import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import createPersistedState from "use-persisted-state";
import { Spin, Checkbox } from "antd";

import { debounce } from "lodash";

import InputSearch from "../../components/searchBar/input";
import Tabs from "../../components/tabs/Tabs";
import Notification from "../ant-notification/notification";
import { FiTrash2 } from "react-icons/fi";

import { Button } from "../../components/buttons/buttons";
import { Modal } from "../../components/modals/antd-modals";

import GroupsWrapper from "./style";

import {
  GetGroupsList,
  GetPostsList,
  SetState,
  RemoveGroupsRecord,
  RemovePostsRecord,
} from "../../redux/slices/leads-slice";

const GroupsPosts = () => {
  const dispatch = useDispatch();
  const openTabState = createPersistedState("openTab");
  const [disabled, setDisabled] = useState(false);
  const [removeRecord, setRemoveRecord] = useState(false);
  const [bulkAction, setBulkAction] = useState(false);
  const [data, setData] = useState({});
  const [openTab, setOpenTab] = openTabState("groups");
  const [groups, setGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [posts, setPosts] = useState([]);
  const [deleteLeads, setDeleteLeads] = useState(false);

  const { err, message, groupsList, postsList, loading, success } = useSelector(
    (state) => state.leads,
  );

  useEffect(() => {
    setGroups(groupsList);
    setPosts(postsList);
  }, [groupsList, postsList]);

  useEffect(() => {
    if (err) {
      Notification({
        type: "error",
        message: message || "Error",
      });
      dispatch(GetGroupsList());
      dispatch(GetPostsList());
    }
    if (success) {
      Notification({
        type: "success",
        message: message || "Success",
      });
      dispatch(GetGroupsList());
      dispatch(GetPostsList());
    }
    dispatch(SetState({ field: "success", value: false }));
    dispatch(SetState({ field: "err", value: false }));
    dispatch(SetState({ field: "message", value: "" }));
    setRemoveRecord(false);
    setDisabled(false);
  }, [err, success]);

  useEffect(() => {
    if (openTab === "groups") {
      dispatch(GetGroupsList());
    } else dispatch(GetPostsList());
  }, [openTab]);

  const isGroupSelected = (data) =>
    selectedGroups.findIndex((item) => item?._id == data?._id) !== -1;
  const isPostSelected = (data) =>
    selectedPosts.findIndex((item) => item?._id == data?._id) !== -1;

  const postsCompletelySelected = () =>
    selectedPosts.length &&
    posts.every((data) => {
      return selectedPosts.find((item) => data?._id == item?._id);
    });

  const groupsCompletelySelected = () =>
    selectedGroups.length &&
    groups.every((data) => {
      return selectedGroups.find((item) => data?._id == item?._id);
    });

  const handleRemoveRecord = () => {
    let dataIds = [];
    let _ids = [];
    setDisabled(true);
    if (openTab === "groups") {
      if (bulkAction) dataIds = selectedGroups.map(({ groupId }) => groupId);
      else dataIds = [data.groupId];
      dispatch(RemoveGroupsRecord({ groupIds: dataIds, deleteLeads }));
      setSelectedGroups([]);
    } else if (openTab === "posts") {
      if (bulkAction) {
        dataIds = selectedPosts.map(({ postId }) => postId);
        _ids = selectedPosts.map(({ _id }) => _id);
      } else {
        dataIds = [data.postId];
        _ids = [data._id];
      }

      console.log({ dataIds, _ids });
      if (!dataIds || !dataIds[0]) {
        console.log("here");
        dataIds = _ids;
      }

      dispatch(RemovePostsRecord({ postIds: dataIds }));
      setSelectedPosts([]);
      setDeleteLeads(false);
    }
  };

  const onRowSelectionChange = ({ checked, tab, record, selectedRows }) => {
    if (tab === "groups") {
      let newGroup = selectedGroups;
      if (selectedRows === "All") {
        if (checked) newGroup = [...groups];
        else newGroup = [];
      } else {
        if (checked) newGroup.push(record);
        else newGroup = newGroup.filter((data) => data._id !== record._id);
        newGroup = [...new Set(newGroup)];
      }
      setSelectedGroups(newGroup);
      setSelectedPosts([]);
    } else if (tab === "posts") {
      let newPost = selectedPosts;
      if (selectedRows === "All") {
        if (checked) newPost = [...posts];
        else newPost = [];
      } else {
        if (checked) newPost.push(record);
        else newPost = newPost.filter((data) => data._id !== record._id);
        newPost = [...new Set(newPost)];
      }
      setSelectedPosts(newPost);
      setSelectedGroups([]);
    }
  };

  const handleSearchChange = debounce((event) => {
    const filterGroups = groupsList.filter((group) =>
      group.name.toLowerCase().includes(event.toLowerCase().trim()),
    );
    const filterPosts = postsList.filter((post) =>
      post.name.toLowerCase().includes(event.toLowerCase().trim()),
    );

    setGroups(filterGroups);
    setPosts(filterPosts);
  }, 500);

  return (
    <GroupsWrapper>
      <div className="d-flex page-header-main align-items-center justify-content-between">
        <h1 className="heading">Groups & Posts</h1>
        <div className="d-flex align-tems-center">
          <div key={1} className="search-box">
            <InputSearch
              onChange={(event) => {
                event.persist();
                handleSearchChange(event.target.value);
              }}
              search
              type="text"
              className="position-relative"
              placeholder="Search by Name"
            />
          </div>
          <div className="d-flex align-items-center group-checkbox">
            <Checkbox
              checked={
                openTab === "groups"
                  ? groupsCompletelySelected()
                  : postsCompletelySelected()
              }
              onChange={(event) => {
                onRowSelectionChange({
                  checked: event.target.checked,
                  tab: openTab,
                  selectedRows: "All",
                  record: openTab === "groups" ? groups : posts,
                });
              }}
              className="checkbox"
              type="checkbox"
            />
            <span className="select-all-text">Select All</span>
          </div>
          <Button
            disabled={selectedGroups.length <= 1 && selectedPosts.length <= 1}
            onClick={(event) => {
              setBulkAction(true);
              setRemoveRecord(true);
            }}
            className={
              selectedGroups.length > 1 || selectedPosts.length > 1
                ? "trash-button"
                : "disable-group-post-wrapper"
            }
          >
            {" "}
            <FiTrash2 className="trash-bukets" /> Delete{" "}
            {openTab === "groups" ? "Groups" : "Posts"}
          </Button>
        </div>
      </div>
      <Tabs
        onRowSelectionChange={onRowSelectionChange}
        setRemoveRecord={setRemoveRecord}
        isGroupSelected={isGroupSelected}
        setSelectedGroups={setSelectedGroups}
        setSelectedPosts={setSelectedPosts}
        setBulkAction={setBulkAction}
        isPostSelected={isPostSelected}
        setOpenTab={setOpenTab}
        openTab={openTab}
        setData={setData}
        groupsList={groups}
        postsList={posts}
      />

      <Modal
        className="modal-content crm-basic-modal action-popup-style"
        footer={null}
        closable={false}
        type="primary"
        centered
        visible={removeRecord}
        width={462}
        onCancel={() => {
          setRemoveRecord(false);
        }}
      >
        <FiTrash2 className="custom-delete-icon-modal" />
        {openTab === "groups" ? (
          <>
            <h3 className="text-padding">
              Are you sure you want to remove{" "}
              {bulkAction ? "these Groups" : "this Group"}?
            </h3>
            <p>
              <Checkbox
                className="justify-content-center"
                checked={deleteLeads}
                onChange={(event) => {
                  setDeleteLeads(event.target.checked);
                }}
              >
                Also delete associated Leads?
              </Checkbox>
            </p>
            <p>
              This will delete the {bulkAction ? "Groups" : "Group"} record{" "}
              {deleteLeads ? "along with the Leads" : ""}.
            </p>
          </>
        ) : (
          <>
            {bulkAction ? (
              <>
                <h3 className="text-padding">
                  Are you sure you want to remove these Posts?
                </h3>
                <p>This will delete the Posts Record along with the Leads</p>
              </>
            ) : (
              <>
                <h3 className="text-padding">
                  Are you sure you want to remove this post?
                </h3>
                <p>This will delete the Post Record along with the Leads</p>
              </>
            )}
          </>
        )}

        <div className="modal-footer d-flex justify-content-center">
          <Button
            size="default"
            className="remove-group-wrapper"
            outlined={true}
            type="white"
            onClick={() => {
              setRemoveRecord(false);
              setDeleteLeads(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            onClick={handleRemoveRecord}
            size="default"
            type="primary"
            className="save-btn"
          >
            {loading ? <Spin size="small" /> : "Delete"}
          </Button>
        </div>
      </Modal>
    </GroupsWrapper>
  );
};
export default GroupsPosts;
