import { Space, Spin } from "antd";
import React from "react";
const Loader = (props) => {
const { spaceSize, spinSize, className } = props;
  return (
    <Space size={spaceSize}>
        <Spin size={spinSize} className={className} />
    </Space>
  );
};
export default Loader;