/*global chrome*/
import Cookies from 'universal-cookie';
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Button, Row, Col } from 'antd';

import { validateEmail } from '../../../../utils/constants';
import Notification from '../../../ant-notification/notification';

import Heading from '../../../../components/heading/heading';
import LeftSide from "./authSideGrid";
import { ReactComponent as Logo } from '../../../../static/img/logo-text.svg';
import { AuthWrapper } from './style';

import {
  SignIn as Login,
  SendAuthTokenToExtension,
  SetState
} from '../../../../redux/slices/auth-slice';

const SignIn = () => {
  const history = useHistory();
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { loading, success, err, token, status } = useSelector(state => state.auth);
  const [form] = Form.useForm();

  useEffect(() => {
    if (err) {
      Notification({
        type: 'error',
        message: err
      });
      dispatch(SetState({ field: "err", value: "" }));
    }
  }, [err])

  useEffect(() => {
    if (success) {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set('loggedIn', true, { path: '/', expires: nextYear });
      cookies.set('token', token, { path: '/', expires: nextYear });
      if (process.env.NODE_ENV === 'production') dispatch(SendAuthTokenToExtension({ token }));
      dispatch(SetState({ field: "success", value: '' }));
      if (status === "Pending") return history.push('/auth/verification' + history.location.search);
      history.push('/dashboard' + history.location.search);
    }
  }, [success]);

  const handleSubmit = (value) => {
    const verifyEmail = validateEmail(value.email);
    if (!verifyEmail) {
      return Notification({
        type: 'warning',
        message: 'Email Is Invalid'
      });
    }
    dispatch(Login(value));
  };

  const handleSignUpUser = () => {
    history.push('/register' + history.location.search);
  }

  const handleForgotPassword = () => {
    history.push('/auth/forgot-password' + history.location.search);
  }

  return (
    <AuthWrapper>
      <Row className='d-flex '>
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className='right-side-content d-flex flex-column justify-content-center align-item-center'>
            <div className="auth-contents">
              <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
                <Heading as="h3">
                  Sign in to <span className="color-secondary logo-text"><Logo /></span>
                </Heading>
                <Form.Item
                  name="email"
                  rules={[{ message: 'Please type your email', required: true }]}
                  label="Username or Email Address"
                >
                  <Input className='enter-name' placeholder="name@example.com" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ message: 'Please type your password!', required: true }]}
                  label="Password">
                  <Input.Password className='enter-name' placeholder="Password" />
                </Form.Item>
                <div className="auth-form-action align-items-baseline login-checkbox-wrapper">
                  <Link to="#" className="color-secondary forgot-pass-link" onClick={handleForgotPassword}> <span className='forgot-text'>Forgot password?</span></Link>
                </div>
                <Form.Item>
                  <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                    {loading ? 'Loading...' : 'Sign In'}
                  </Button>
                </Form.Item>
                <div className="auth-notice d-flex">
                  <p>  Don&rsquo;t have an account? </p> <span onClick={handleSignUpUser} className="color-secondary ml-2"> Sign up</span>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  );
};

export default SignIn;
