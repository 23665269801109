import styled from "styled-components";
const GroupsWrapper = styled.div`
  button.ant-btn.ant-btn-default.sc-bdVaJa.erjwGR.trash-button {
    border: 1px solid #5f63f2;
    color: #5f63f2;
    background-color:#ffffff;
    margin-left: 24px;
    &:hover {
      background-color: #5f63f2;
      color: #fff;
    }
  }
  height: calc(100vh - 114px);
  width: 100%;
  .checkbox-wrapper span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9ba8b7;
  }
  .checkbox {
    /* margin-left: 24px; */
    span {
      /* margin-right: 0px; */
    }
  }
  input.checkbox {
    width: 16px !important;
    min-width: 0px;
    margin-right: 10px;
    margin-left: 24px;
    height: 16px;
  }
  .d-flex.align-items-center.checkbox-wrapper {
    margin-top: -5px;
  }
  .search-box {
    position: relative;
    box-shadow: 0 5px 5px rgba(#9299b8, 0.3);
    margin-left: 43px;
    svg,
    img {
      min-width: 16px;
      color: #5f63f3;
      margin-left: 1px;
    }
    input.ant-input.position-relative {
      width: 252px;
      border-radius: 36px;
    }
  }
.disable-group-post-wrapper {
    background: #fff;
    color: #9BA8B7;
    border: 1px solid #9BA8B7;
    margin-left: 24px;
    cursor: not-allowed;
    &:hover{
      background: #fff;
    color: #9BA8B7;
    border: 1px solid #9BA8B7;
    }
}
.trash-button {
    background: #fff;
    color: #5f63f3;
    margin-left: 24px;
    &:hover{
      background-color: #5f63f3;
      color:#ffffff;
    }
}
  .list-card-wrapper {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px 24px 24px;
    /* margin-top: -9px; */
    label.ant-checkbox-wrapper{
      top:-3px;
    }
    .card-input{
      span{
        color: #4B5664;
      }
    }
    .date-issue{
      color: #4B5664;
    }
  }
  h1.heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
  }
  .card-footer-wrapper{
    margin-top:0;
  }
  .card-footer-wrapper .header-bage {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding: 3px 9px;
    border-radius: 39px;
    background: #2fc898;
    color: #fff;
    /* margin-top: -4px; */
    position: relative;
    min-width: 67px;
    white-space: nowrap;
    max-width: 84px;
  }
  .ant-tabs-tab {
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: -7px;
    margin: -9px 0 0 -3px !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #5f63f2;
    font-weight: 700;
    margin: -1px 0 0 0px;
  }
  .list-card-wrapper .user-info-list {
    padding: 25px 0px 0 2px;
  }
  .checkbox-wrapper span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-right: 1px;
    color: #9ba8b7;
  }
  .checkbox {
    /* margin-left: 16px; */
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
    position: relative;
    padding: 8px 16px;
    background: transparent;
    border: 0;
    display: none;
  }
  span.select-all-text {
    margin-top: -6px;
    color: #9ba8b7;
  }
  button.ant-btn.ant-btn-default.sc-brqgnP.ivPkWK.trash-button {
    color: #5f63f3;
    background: #ffffff;
    margin-left: 14px;
    margin-top: -4px;
  }
  button.ant-btn.ant-btn-default.sc-brqgnP.ivPkWK.trash-button:hover {
    background: #5f63f3;
    color: #fff;
  }
  .page-header-main{
    padding-top:24px;
    padding-bottom: 8px;
    .ant-checkbox-wrapper{
      margin-right:0;
      top:-6px;
    }
  }
  .list-page-cards-wrapper{
    padding-top:8px;
    margin-right:0px ;
  }
`;
export default GroupsWrapper;
