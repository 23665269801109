import React, { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({
  component: Component,
  withLayout: Layout,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const { token, status, role } = useSelector(state => state.auth);
  const path = location.path;
  const { pathname: pathName } = location;

  useEffect(() => {
    if ((!role || role !== 'admin') && pathName?.includes('chatpro-users')) {
      return history.push('/dashboard'); 
    }
    if (!token || token === '' || token === undefined) { 
      return history.push('/auth/sign-in' + history.location.search); 
    }
    if (status === 'Active' && pathName?.includes('auth')) {
      return history.push(`/dashboard`);
    }
    if (status === 'Pending' && !pathName?.includes('subscription-plans')) {
      return history.push(`/auth/verification` + history.location.search);
    }
    if (status === 'Verified' && !pathName?.includes('subscription') ) { 
      return history.push(`/auth/subscription-plans` + history.location.search) 
    }
    else if (status === 'Subscribed' && !pathName?.includes('onboard')) { 
      return history.push(`/auth/onboarding-overview`) 
    }
  }, [token, status, pathName, path]);

  return token && <Route path={rest.path} component={Component} />
};

export default PrivateRoute;
