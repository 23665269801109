import styled from "styled-components";
const TabsWrapper = styled.div`
padding-top:10px ;
  .list-card-wrapper {
    background: #ffffff;
    border-radius: 8px;
    padding: 24px 27px 24px 27px;
    margin:0px ;
    .note-icon {
      color: #5f63f2;
      font-size: 17px;
      margin-right: 8px;
      cursor: pointer;
    }
    .eye-icon {
      color: #5f63f2;
      font-size: 17px;
      margin-right: 12px;
      cursor: pointer;
    }
    .bucket-icon {
      color: #5f63f2;
      font-size: 17px;
      cursor: pointer;
    }
    .user-info-list {
      padding: 20px 0px 0 2px;
      .user-info {
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        text-align: left;
        color: #9ba8b7;
        margin-right:4px ;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color:#4B5664;
      }
    }

    .card-input {
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
      }
      input {
        width: 16px;
        margin-right: 12px;
        height: 16px;
      }
    }
  }
  .ant-tabs-nav-wrap {
    margin-top: 18px;
  }


  .ant-tabs-tab-btn {
    outline: none;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-weight: 700;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #5f63f2;
    font-weight: 700;
  }
  .card-footer-wrapper .header-bage {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 3px 6px;
    left:0px !important;
    border-radius: 39px;
    background: #2FC898;
    color: #fff;
  }
  .card-footer-wrapper {
    margin-top: -4px;
    display:flex ;
  }
  .list-page-cards-wrapper {
    padding: 20px 0px;
    margin-right:0px ;
    overflow: auto;
    height: calc(100vh - 250px);
    margin-left:-12px;
    margin-right:-12px;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    width: 200px;
}
.ant-tabs-tab {
    width: 200px;
    margin: auto;
    text-align: center;
    display: flex;
    justify-content: center;
}
span.box-text-wrapper-tabs {
    width: 170px;
    overflow-wrap: anywhere;
}
`;
export default TabsWrapper;
