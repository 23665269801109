import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { debounce } from "lodash";

import {
  Spin
} from "antd";

import CrossIcon from "../../static/img/x-crose-icon.svg";
import { BiLockOpen } from "react-icons/bi"
import Table from "./table";
import Notification from '../ant-notification/notification';

import { TableWrapper } from "./style";
import { Modal } from "../../components/modals/antd-modals";
import { Button } from "../../components/buttons/buttons";
import InputSearch from "../../components/searchBar/input"
import { Main } from '../styled';

import {
  GetBlockedLeads,
  SetState,
  UnblockLeads
} from '../../redux/slices/blocked-leads-slice';

import "../leads/style"
import "./style";
import { RowWrapper } from '../leads/style';

const BlockedLeads = () => {
  const dispatch = useDispatch();
  const { loading, leads, err, success, message, total } = useSelector(state => state.blockedLeads);

  const [disabled, setDisabled] = useState([]);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [deleteLeads, setDeleteLeads] = useState(false);
  const [unblockLeads, setUnblockLeads] = useState(false);
  const [bulkAction, setBulkAction] = useState(false);
  const [leadsData, setLeadsData] = useState([]);
  const [lead, setLead] = useState({});
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState([["name", "ascending"]]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
    total: 0
  });

  useEffect(() => {
    setPagination({ ...pagination, total: total });
    setLeadsData(leads)
  }, [leads, total]);

  useEffect(() => {
    if (success) {
      Notification({
        type: 'success',
        message
      });
      dispatch(SetState({ field: 'success', value: false }))
      setSelectedLeads([]);
    }
    if (err) {
      Notification({
        type: 'error',
        message: err
      });
      dispatch(SetState({ field: 'err', value: '' }))
      setSelectedLeads([]);
    }

    dispatch(GetBlockedLeads({ filter, sort, skip: 0, limit: pagination.pageSize }));

    setPagination({
      ...pagination,
      current: 1
    })
    setDeleteLeads(false);
    setUnblockLeads(false);
    setBulkAction(false);
    setDisabled(false);
  }, [success, err, filter.keyword])

  useEffect(() => {
    dispatch(GetBlockedLeads({ filter, sort, skip: 0, limit: pagination.pageSize }))
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    const { current: newPage, pageSize: limit, total } = newPagination;
    setPagination({ showSizeChanger: true, current: newPage, pageSize: limit, total });
    dispatch(GetBlockedLeads({ filter, sort, skip: (newPage - 1) * limit, limit }));
  };

  const handleUnblockLeads = async () => {
    let leadIds = [];
    setDisabled(true);
    if (bulkAction) leadIds = selectedLeads.map(data => data.leadId);
    else leadIds.push(lead.leadId)
    await dispatch(UnblockLeads({ leadIds }));
  }

  const handleSearchChange = debounce((event) => {
    setFilter({ keyword: event });
    setPagination({
      showSizeChanger: true,
      current: 1,
      pageSize: pagination.pageSize,
      total: 0
    });
  }, 250);
  const tableProps = {
    loading,
  }

  return (
    <Main>
      <TableWrapper>
        <div className="content-header page-header-main d-flex justify-content-between align-items-center">
          <div className="heading d-flex justify-content-between ">
            <h3>Blocked Leads</h3>

          </div>
          <div className='d-flex  heading'>
            <div key={1} className="search-box">
              <InputSearch
                search
                type="text"
                name="search"
                placeholder="Search by Name"
                onChange={(event) => {
                  event.persist();
                  handleSearchChange(event.target.value);
                }}
              />
            </div>
            <div className="page-header-actions buttons-block-leads">
              <Button outlined className={selectedLeads.length > 0 ? "pipeline-delete-card-btn" : "blockleads-btn-disable-wrapper"}
                size="default"
                type="primary"
                disabled={selectedLeads.length < 1}
                onClick={() => { setBulkAction(true); setUnblockLeads(true) }}
              >
                <BiLockOpen className="actions-icons lock-icon blocklead-icon" />
                Unblock Leads
              </Button>
            </div>
          </div>
        </div>
        <RowWrapper>
                <div className="d-flex justify-content-between filter-wrapper">
                    <div className="leads-counter-wrap">
                        <span>
                            Total: <span className="font-bold">{total}</span>
                        </span>
                    </div>
                </div>
            </RowWrapper>
        <Modal
          className="modal-content-blocklead block-leads-popup"
          type="primary"
          centered
          visible={deleteLeads}
          width={462}
          closable={false}
        >
          <img src={CrossIcon} />
          {selectedLeads.length ?
            <><h3>Are you sure you want to unblock this users?</h3> <p>Leads will be removed</p> </> :
            <><h3>Are you sure you want to unblock this user?</h3> <p>Lead will be removed</p></>}

          <div className="modal-footer d-flex justify-content-center">
            <Button
              size="default"
              outlined
              type="white"
              onClick={() => {
                setDeleteLeads(false);
              }}
            >
              Cancel
            </Button>
            <Button size="default" type="primary" className="ml-2 save-btn">
              {loading ? <Spin size="small" /> : "Remove"}
            </Button>
          </div>
        </Modal>

        <Modal
          title=""
          className="modal-content block-leads-popup"
          footer={null}
          type="primary"
          centered
          visible={unblockLeads}
          width={462}
          closable={false}
        >
          <div>
            <BiLockOpen className='modal-lock-icon' />
            {selectedLeads.length ?
              <><h3>Are you sure you want to unblock these leads?</h3>
                <p>Unblocked leads will be shown now.</p></> :
              <><h3>Are you sure you want to unblock this lead?</h3>
                <p>Unblocked lead will be shown now.</p></>}
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <Button
              size="default"
              outlined
              type="white"
              onClick={() => {
                setUnblockLeads(false);
              }}
            >
              Cancel
            </Button>
            <Button disabled={disabled} onClick={handleUnblockLeads} size="default" type="primary" className="ml-2 save-btn">
              {loading ? <Spin size="small" /> : "Unblock"}
            </Button>
          </div>
        </Modal>
        <Table
          sticky
          leadsData={leadsData}
          selectedLeads={selectedLeads}
          handleTableChange={handleTableChange}
          setSelectedLeads={setSelectedLeads}
          setDeleteLeads={setDeleteLeads}
          setUnblockLeads={setUnblockLeads}
          setLead={setLead}
          pagination={pagination}
          {...tableProps}
        />
      </TableWrapper>
    </Main>
  );
};
export default BlockedLeads;
