import styled from "styled-components";
const InputWrapper = styled.div`
span.anticon.anticon-search.search-icon {
    position: absolute;
    left: 212px;
    top: 10px;
    color:#5f63f3;
}
.search-input {
    width: 252px;
    height: 36px;
    margin-left: 3px;
    border-radius: 24px;
}

`;

export default InputWrapper;