import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const GetStages = createAsyncThunk(
  'stage/getStages',
  async (data, thunkAPI) => {
    try {
      const { pipelineId, skip, limit } = data;
      const response = await axios.get('/stages/get-pipelines-stages', { params: { pipelineId, skip, limit } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemoveAllStageLeads = createAsyncThunk(
  'pipeline/removeLeadRecord',
  async (data, thunkAPI) => {
    try {
      const { pipelineId, leadIds, stageId } = data;
      const response = await axios.delete('/pipelines/delete-leads-record', { params: { pipelineId, leadIds, stageId } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const DeleteStageRecord = createAsyncThunk(
  'stage/deleteStageRecord',
  async (data, thunkAPI) => {
    try {
      const { pipelineId, stageId } = data;
      const response = await axios.delete('/stages/remove-stage-record', { params: { stageId } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddStageRecord = createAsyncThunk(
  'stage/addStageRecord',
  async (data, thunkAPI) => {
    try {
      const { pipelineId, stageName } = data;
      const response = await axios.post('/stages/add-stage-record', { name: stageName }, { params: { pipelineId } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateStageRecord = createAsyncThunk(
  'stage/updateStageRecord',
  async (stageData, thunkAPI) => {
    try {
      const { pipelineId, stageId, data, label } = stageData;
      const response = await axios.put('/stages/update-stage-record', { data, label }, { params: { pipelineId, stageId } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddLeadToStage = createAsyncThunk(
  'stage/addLeadsStage',
  async (data, thunkAPI) => {
    try {
      const { pipelineId, leadId, stageId } = data;
      const response = await axios.post('/stages/add-stage-lead', { pipelineId, stageId, leadId });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemoveStageLead = createAsyncThunk(
  'stage/removeStageLead',
  async (data, thunkAPI) => {
    try {
      const { leadId, stageId } = data;
      const response = await axios.delete('/stages/remove-stage-lead', { params: { leadId, stageId } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetStageLeads = createAsyncThunk(
  'stage/getStageLeads',
  async (data, thunkAPI) => {
    try {
      const { keyword , stageId, skip, limit, placeAtTop  } = data;
      const response = await axios.get('/stages/get-stage-leads', { params: { stageId, keyword, skip, placeAtTop, limit } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const GetAllStagesLeads = createAsyncThunk(
  'stage/getAllStagesLeads',
  async (data, thunkAPI) => {
    try {
      const { stageIds} = data;
      const response = await axios.post('/stages/get-allstages-leads', {   stageIds });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const user = createSlice({
  name: 'stages',
  initialState: {
    success: false,
    reloadStages: false,
    refreshStage:false,
    reloadLeads: false,
    scrollPosition: {},
    stageLeadsLoading: false,
    updateError: false,
    total: 0,
    stageData: null,
    loading: false,
    stages: [],
    stageLeads: [],
    allStagesLeads:[],
    err: ''
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState() {
      return {
        success: false,
        total: 0,
        pipelinesData: [],
        reloadStages: false,
        stageLeadsLoading: false,
        stageData: null,
        stages: [],
        stageLeads: [],
        err: ''
      };
    }
  },
  extraReducers: {
    [GetStages.pending]: (state, action) => ({
      ...state
    }),
    [GetStages.fulfilled]: (state, action) => ({
      ...state,
      stages: action.payload.data,
      reloadLeads: true,
      stageLeads: []
    }),
    [GetStages.rejected]: (state, action) => ({
      ...state,
      success: false,
      err: action.payload.err.error
    }),
    [UpdateStageRecord.pending]: (state, action) => ({
      ...state,
    }),
    [UpdateStageRecord.fulfilled]: (state, action) => ({
      ...state,
      updateError: false,
    }),
    [UpdateStageRecord.rejected]: (state, action) => ({
      ...state,
      loading: false,
      updateError: true,
      err: action.payload?.err?.error
    }),
    [AddStageRecord.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [AddLeadToStage.pending]: (state, action) => ({
      ...state,
      success: false,
      message: '',
      loading: true
    }),
    [AddLeadToStage.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      reloadStages:true,
      loading: false
    }),
    [AddLeadToStage.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err?.error
    }),
    [AddStageRecord.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      stageData: { ...action.payload.data, activeLeads: 0 },
      loading: false,
      stageAddLoading: false
    }),
    [AddStageRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      stageAddLoading: false,
      err: action.payload?.err?.error
    }),
    [GetStageLeads.pending]: (state, action) => ({
      ...state,
    }),
    [GetStageLeads.fulfilled]: (state, action) => ({
      ...state,
      stageLeadsLoading: false,
      stageLeads: [...new Set([...state.stageLeads, ...action.payload.data])]

    }),
    [GetStageLeads.rejected]: (state, action) => ({
      ...state,
      success: false,
      stageLeadsLoading: false,
      err: action.payload.err.error
    }),
    [GetAllStagesLeads.pending]: (state, action) => ({
      ...state,
    }),
    [GetAllStagesLeads.fulfilled]: (state, action) => ({
      ...state,
      allStagesLeads: action.payload.data

    }),
    [GetAllStagesLeads.rejected]: (state, action) => ({
      ...state,
      success: false,
      err: action.payload.err.error
    }),
    [DeleteStageRecord.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [DeleteStageRecord.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      message: action.payload?.message,
      success: true
    }),
    [DeleteStageRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload?.err?.error
    }),
    [RemoveStageLead.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [RemoveStageLead.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [RemoveStageLead.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload?.err?.error
    }),
    [RemoveAllStageLeads.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [RemoveAllStageLeads.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message
    }),
    [RemoveAllStageLeads.rejected]: (state, action) => ({
      ...state,
      loading: false,
      success: false,
      err: action.payload?.err?.error
    })
  }
});

const { reducer, actions } = user;

export const {
  SetState,
  ClearState
} = actions;

export default reducer;
