import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';


const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { token, accountAttached } = useSelector((state) => state.auth);


  return (
    <div>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </div>
  );
}

export default PublicRoute;
