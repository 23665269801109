import React, { useEffect } from 'react';
import { Col, Row } from 'antd';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LeftSide from './authSideGrid';
import { AuthWrapper } from './style';
import Notification from "../../../ant-notification/notification";
import EmailVerification from '../../../../static/img/email-verification.svg';
import { SetState, ForgotPassword } from '../../../../redux/slices/auth-slice';

const verificationEmailSent = () => {

  const dispatch = useDispatch();
  const { err, success, message, resetEmail } = useSelector(state => state.auth);

  useEffect(() => {
    if (success) {
      Notification({
        type: "success",
        message: message || 'Email Has Been Sent',
      })
      dispatch(SetState({ field: 'success', value: '' }))
    }
    if (err) {
      Notification({
        type: "error",
        message: err,
      })
      dispatch(SetState({ field: 'err', value: '' }))
    }
  }, [success, err])

  const handleResendEmail = () => {
    dispatch(ForgotPassword({ email: resetEmail }));
  }

  return (
    <AuthWrapper>
    <Row className="d-flex">
      <Col md={11} className="left-side-img">
        <LeftSide />
      </Col>
      <Col md={13} className="right-side-content">
      <div className='verfiction-email-overlay-wrapper'>
        <h1>Reset Password Email Sent</h1>
        <img src={EmailVerification} alt="email-verification-icon"/>
        <p>If you didn’t receive the email <Link to='#' onClick={handleResendEmail}>Click Here</Link></p>
      </div>
      </Col>
    </Row>
  </AuthWrapper>
  );
}

export default verificationEmailSent;
