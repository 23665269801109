/*global chrome*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const GetChatProUsers = createAsyncThunk(
  'admin/getChatProUsers',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/admin/users', { params: data });

      return response.data;
    } catch (err) {

      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const CreateChatProUser = createAsyncThunk(
  'admin/createChatProUser',
  async (userData, thunkAPI) => {
    try {
      const response = await axios.post('/admin/create-chatpro-user', userData);

      return response.data;
    } catch (err) {

      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateChatProUser = createAsyncThunk(
  'admin/updateChatProUser',
  async (userData, thunkAPI) => {
    try {
      const response = await axios.put(`/admin/update-chatpro-user/${userData._id}`, userData);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);


export const DeleteChatProUser = createAsyncThunk(
  'admin/deleteChatProUser',
  async (userId, thunkAPI) => {
    try {
      const response = await axios.delete(`/admin/delete-chatpro-user/${userId}`);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const ResetPassword = createAsyncThunk(
  'admin/resetPassword',
  async (userData, thunkAPI) => {
    try {
      const response = await axios.put(`/admin/reset-password/${userData._id}`, userData);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

export const ResendVerificationEmail = createAsyncThunk(
  'admin/resendVerificationEmail',
  async (userData, thunkAPI) => {
    try {
      const response = await axios.put(`/admin/resend-verification-email/${userData._id}`, userData);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status,
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error',
      });
    }
  }
);

const admin = createSlice({
  name: 'auth',
  initialState: {
    users: [],
    success: '',
    err: false,
    loading: false,
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState() {
      return {
        users: [],
        success: '',
        err: false,
        loading: false,
      };
    }
  },
  extraReducers: {
    [GetChatProUsers.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetChatProUsers.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      users: action.payload.data,
      total: action.payload.total
    }),
    [GetChatProUsers.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err?.error || 'Unable To Fetch Users Data'
    }),
    [CreateChatProUser.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [CreateChatProUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message || 'User Created Successfully',
    }),
    [CreateChatProUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action?.payload?.err?.message || action?.payload?.message || "Couldn't Create User. Please Try Again"
    }),
    [DeleteChatProUser.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [DeleteChatProUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message || 'User Deleted Successfully',
      users: state.users.filter(user => user._id !== action.meta.arg),
      total: state.users.filter(user => user._id !== action.meta.arg).length
    }),
    [DeleteChatProUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action?.payload?.err?.message || action?.payload?.message || "Couldn't Delete User. Please Try Again"
    }),
    [UpdateChatProUser.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [UpdateChatProUser.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message || 'User Updated Successfully',
      users: state.users.map(user => 
        user._id === action.meta.arg._id ? { ...action.payload.data } : user
      ),
    }),
    [UpdateChatProUser.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action?.payload?.err?.message || action?.payload?.message || "Couldn't Update User. Please Try Again"
    }),
    [ResetPassword.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [ResetPassword.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message || 'Password Reset Successfully',
    }),
    [ResetPassword.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action?.payload?.err?.message || action?.payload?.message || "Couldn't Reset Password. Please Try Again",
    }),
    [ResendVerificationEmail.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [ResendVerificationEmail.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message || 'Verification Email Resent Successfully',
    }),
    [ResendVerificationEmail.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action?.payload?.err?.message || action?.payload?.message || "Couldn't Resend Verification Email. Please Try Again",
    }),
  }
});

const { reducer, actions } = admin;

export const {
  SetState,
  ClearState
} = actions;

export default reducer;
