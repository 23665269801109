import React, { useState, useEffect } from "react";
import { useSelector, useHistory } from 'react-redux';

import { Tabs, Row, Col, Switch } from "antd";

import EditProfile from "./editProfile";
import ResetPassord from "./editPassword";

import User from "../../static/img/user.jpeg";

import { Items } from '../../utils/constants';
import { SettingWrapper } from "./style";

const Setting = () => {

  const [resetPassword, setShowResetPassword] = useState(false);

  const [tabName, setTabName] = useState("edit-profile");
  const {
    auth: { name, facebookProfileData = {} }
  } = useSelector((state) => state);

  const { image } = facebookProfileData;

  const onClickk = (item) => {
    setTabName(item);
  };

  const handleImageError = () => {
    const userImage = document.querySelector('img[id="social-profile-wrap"]');
    if (userImage) userImage.src = 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'
  }

  return (
    <SettingWrapper>
      <div className="setting-header-wrapper d-flex justify-content-between">
        <h1 className="m-0">Profile</h1>
      </div>
      <Row gutter={30}>
        <Col md={6}>
          <div className="setting-menu-wrapper">
            <div className="setting-menu">
              <div className="user-wrapper">
                <img id={'social-profile-wrap'} src={image} onError={handleImageError} className="user-img" />
              </div>
              <h1 className="mb-0">{name}</h1>
            </div>
            <div className="setting-user-divider" />
            <div className="footer-wrapper">
            </div>
          </div>
        </Col>
        <Col md={18}>
          <div className="content-wrapper">
            {tabName === "edit-profile" ? (
              <div>
                {resetPassword ? <ResetPassord setShowResetPassword={setShowResetPassword}/>
                  : <EditProfile setShowResetPassword={setShowResetPassword} />
                  // <Verification />
                }
              </div>
            ) : (
              ""
            )}
          </div>
        </Col>
      </Row>
    </SettingWrapper>
  );
};
export default Setting;
