/*global chrome*/

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isEqual, isEmpty } from 'lodash';

import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Checkbox,
  Tag
} from "antd";

import SelectDropdown from "../../components/select/index";
import Notification from "../ant-notification/notification";

import FeatherIcon from "feather-icons-react";
import Facebook from "../../static/img/facebook.svg";
import Messenger from "../../static/img/messenger.svg";
import NoUser from "../../static/img/no-user.png";
import { ReactComponent as ListIcon } from "../../static/img/list.svg";
import ListModalIcon from "../../static/img/list-modal.svg";
import PipelineModalIcon from "../../static/img/filtersModal.svg";
import { ReactComponent as PipelineIcon } from "../../static/img/filters.svg";
import { ReactComponent as DeleteActions } from "../../static/img/user-remove.svg";
import { ReactComponent as Unfriend } from "../../static/img/remove-user.svg";
import { ReactComponent as BlockUser } from "../../static/img/user-block.svg";
import DeletePopUp from "../../static/img/delete-modal-red.svg";
import bagDelete from "../../static/img/bag-delete-icon.svg";
import AddFriend from "../../static/img/add-friend.svg";
import { ReactComponent as UnFriend } from "../../static/img/unfriend.svg";
import { ReactComponent as UsergroupOutlined } from "../../static/img/usergroup-outlined.svg";
import { FiCheck } from "react-icons/fi";
import { BiPencil } from "react-icons/bi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { FaTimes } from "react-icons/fa";

import CustomDropDownMenu from "../../components/customDropDown/CustomDropDown";

import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import { Modal } from "../../components/modals/antd-modals";
import SearchInput from "../../components/searchBar/input";

import Loader from "../../components/loader/index";
import {
  validateEmail,
  isValidURL,
  ExtensionId,
  relationshipOptions,
  parseData,
} from "../../utils/constants";

import { Main } from "../styled";
import { TableWrapper } from "../styled";
import { WrapperModal, RowWrapper } from "./style";

import {
  StopOutlined,
  MessageOutlined,
  SolutionOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  GetPipelinesRecord,
  GetPipelineStages,
} from "../../redux/slices/pipeline-slice";

import { GetLists , GetTags } from "../../redux/slices/list-slice";

import { SendAuthTokenToExtension } from "../../redux/slices/auth-slice";

import {
  SetState,
  GetLeadsData,
  UpdateLeadsRecord,
  GetGroupsList,
  AddLeadsToLists,
  GetPostsList,
  AddLeadsRelationshipStatus,
  AddLeadsToPipeline,
  DeleteLeadsData,
  BlockLeadsData,
  UpdateLeadsField,
  GetAllLeadsData,
} from "../../redux/slices/leads-slice";

import DashboardCards from "../dashboard/index"

const Dashboard = () => {   
  const searchRef = useRef(null)

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;

  const dispatch = useDispatch();

  const {
    leadsData,
    total,
    err,
    message,
    leadsLoading,
    information,
    fieldEdited,
    groupsList,
    postsList,
    loading,
    success,
  } = useSelector((state) => state.leads);

  const { pipelinesData, stages: stagesData } = useSelector(
    (state) => state.pipelines
  );

  const { lists } = useSelector((state) => state.list);
  const { tags }  = useSelector((state) => state.list);

  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    setEditEmail(false);
    setEditContact(false);
    setEditValue(false);
    setEditLink(false);
    setDisabled(false);
    setAddFriendModal(false);

    if (err) {
      Notification({
        type: "error",
        message: err,
      });
      dispatch(SetState({ field: "err", value: false }));
    }
  }, [err]);

  useEffect(() => {
    setDisabled(false);

    if (information) {
      Notification({
        type: "info",
        message: information,
      });
      dispatch(SetState({ field: "information", value: "" }));
    }
  }, [information]);

  useEffect(() => {
    setEdit(false);
    setEditEmail(false);
    setEditContact(false);
    setEditValue(false);
    setAddLink(false);
    setEditLink(false);
    setLinkValue("");
    setAddLeadsToLists(false);
    setAddToPipelinesModal(false);
    setExtensionLoading(false);
    setListsRecord([]);
    setPipelineStageData({
      pipelineId: null,
      stageId: null,
      overwriteStages: true,
    });
    setDisabled(false);
    if (success) {
      Notification({
        type: "success",
        message: message || 'success',
      });
      const { current: newPage, pageSize: limit } = pagination;
      dispatch(GetLeadsData({ filter, sort, skip: (newPage - 1) * limit, limit }));
      dispatch(SetState({ field: "success", value: false }));
      dispatch(SetState({ field: "message", value: "" }));
      setSelectedLeads([]);
    }
  }, [success]);

  useEffect(() => {
    if (fieldEdited) {
      dispatch(SetState({ field: "fieldEdited", value: false }));
      const { current: newPage, pageSize: limit } = pagination;
      dispatch(
        GetLeadsData({ filter, sort, skip: (newPage - 1) * limit, limit })
      );
    }
    setDisabled(false);
  }, [fieldEdited]);

  const sendListsToExtension = () => {
    const listsData = lists?.map(({ _id, name }) => { return { name, value: _id } })

    if (!chrome?.runtime) return;
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "LISTS_DATA",
        lists: listsData
      });
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      dispatch(SendAuthTokenToExtension({ token }));
      sendListsToExtension();
    }

    dispatch(
      GetLeadsData({ filter, sort, skip: 0, limit: pagination.pageSize })
    );
    dispatch(
      GetPipelinesRecord({
        filter: { keyword: "" },
        sort: sortByCreation,
        skip: 0,
      })
    );
    dispatch(
      GetLists({ filter: { keyword: "" }, sort: sortByCreation, skip: 0 })
    );
    dispatch(
      GetTags({ filter: { keyword: "" }, sort: sortByCreation, skip: 0 })
    );
    dispatch(GetGroupsList());
    dispatch(GetPostsList());
    dispatch(SetState({ field: "err", value: "" }));
    dispatch(SetState({ field: "message", value: "" }));
    setPagination({ current: 1, pageSize: 20 });
  }, []);


  const handleCloseFilterByClick = () => {
    const interval = setInterval(() => {
      const concernedElement = document.querySelector('#search-filter-div');
      if (concernedElement) {
        clearInterval(interval);
        document.addEventListener('mousedown', (e) => {
          const attribute = e.target.getAttribute('class');

          const clickedInside = searchRef.current?.contains(e.target) ||
            attribute && attribute.includes('select') ||
            attribute && attribute.includes('option') ||
            attribute && attribute.includes('ant-empty');

          if (clickedInside) return;
          else setOpenSearchFilter(false);
        });
        document?.removeEventListener('mousedown', () => { });
      }
    }, 50)
  }

  useEffect(() => {
    setPagination({ ...pagination, total });
    setLeads(parseData(JSON.stringify(leadsData)));
    if (allLeadsSelected) {
      setSelectedLeads(leadsData)
    }
  }, [leadsData]);

  useEffect(() => {
    if (lists.length) setListsArr(lists);
  }, [lists]);

  const [edit, setEdit] = useState(false);
  const [block, setBlock] = useState(false);
  const [leads, setLeads] = useState([]);
  const [remove, setRemove] = useState(false);
  const [addFriendModal, setAddFriendModal] = useState(false);
  const [showTags, setShowTags] = useState(null);
  const [showList, setShowList] = useState(false);
  const [showGroups, setShowGroups] = useState(null);
  const [editEmail, setEditEmail] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [editValue, setEditValue] = useState(false);
  const [editLink, setEditLink] = useState(false);
  const [addLink, setAddLink] = useState(false);
  const [linkValue, setLinkValue] = useState("");
  const [deleteLeadModal, setDeleteModal] = useState(false);
  const [openSearchFilter, setOpenSearchFilter] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [listsArr, setListsArr] = useState([]);
  const [allLeadsSelected, setAllLeadsSelected] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [filterSelected, setFilterSelected] = useState(false);
  const [filteredLeads, setFilteredLeads] = useState([]);

  const [filter, setFilter] = useState({
    keyword: "",
    postId: null,
    groupId: null,
    tags: [],
    lists: [],
    noFriends: false,
    messenger: false,
    friendsOnly: false,
    requestSent: false,
  });

  const [appliedFilter, setAppliedFilter] = useState({
    keyword: "",
    postId: null,
    groupId: null,
    tags: [],
    lists: [],
    noFriends: false,
    messenger: false,
    friendsOnly: false,
    requestSent: false,
  });

  const [sort, setSort] = useState([["name", "ascending"]]);
  const [sortByCreation, setSortByCreation] = useState([
    ["createdAt", "ascending"],
  ]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [singleUserAction, setSingleUserAction] = useState(false);
  const [addToPipelinesModal, setAddToPipelinesModal] = useState(false);
  const [pipelineStageData, setPipelineStageData] = useState({
    pipelineId: null,
    stageId: null,
    overwriteStages: true,
  });
  const [lead, setLead] = useState({});
  const [disabled, setDisabled] = useState({});
  const [leadUpdated, setLeadUpdated] = useState({});
  const [friendshipStatus, setFriendshipStatus] = useState(false);
  const [extensionLoading, setExtensionLoading] = useState(false);
  const [addLeadsToLists, setAddLeadsToLists] = useState(false);
  const [listsRecord, setListsRecord] = useState([]);

  const isSelected = (lead) =>
    selectedLeads.findIndex((item) => item.leadId == lead.leadId) !== -1;
  const isCompletelySelected = () =>
    leadsData.every((data) => {
      return selectedLeads?.find((item) => data.leadId == item.leadId);
    });

  const onRowSelectionChange = ({ checked, selectedRows, leadData }) => {
    let newLeads = JSON.parse(JSON.stringify(selectedLeads)) || [];
    if (selectedRows === "All") {
      if (checked) newLeads.push(...leadData)
      else {
        newLeads = newLeads.filter((value, index) => {
          return !leadData.find((data) => data.leadId === value.leadId);
        });
      }
      setSelectedLeads(newLeads)
    } else if (checked) {
      newLeads.push(leadData);
      setSelectedLeads(newLeads)
    }
    else {
      newLeads = newLeads.filter((data) => data.leadId !== leadData.leadId);
      newLeads = [...new Set(newLeads)];
      if (!checked) setAllLeadsSelected(false);
      setSelectedLeads(newLeads)
    }
    if (newLeads.length === 2) {
      document.addEventListener('keyup', function (event) {
        if (event.key === 'Meta' || event.key === "Control") {
          //   // Find the start and end indices of the selected rows
          const indices = newLeads.map((selectedLead) =>
            leads.findIndex((row) => row.leadId === selectedLead.leadId)
          );
          //   // Ensure indices are valid and not equal
          if (indices[0] !== -1 && indices[1] !== -1 && indices[0] !== indices[1]) {
            // Sort indices to get the correct range
            indices.sort((a, b) => a - b);
            // Select all rows between the start and end indices
            const rowsToSelect = leads.slice(indices[0], indices[1] + 1);
            setSelectedLeads(rowsToSelect);
          }
        }
      });
    }
  };

  const handleCancelFilters = () => {
    setOpenSearchFilter(false);
    setFilter(appliedFilter)
    setFriendshipStatus(false);
  };

  useEffect(() => {
    if (checkObjectIsEmpty(appliedFilter)) setSelectedLeads([]); setAllLeadsSelected(false);
  }, [appliedFilter])


  useEffect(() => {
    if (pipelineStageData.pipelineId) {
      if (stagesData.length > 0) {
        setPipelineStageData((prevData) => ({
          ...prevData,
          stageId: stagesData[0]._id, // Set the stageId to the first element
        }));
      } else {
        setPipelineStageData((prevData) => ({
          ...prevData,
          stageId: null, // Set the stageId to the first element
        }));
      }
    }
  }, [pipelineStageData.pipelineId, stagesData]);

  const columns = [
    {
      title: (
        <div>
          <Checkbox
            checked={leadsData.length ? isCompletelySelected() : false}
            onChange={(event) => {
              onRowSelectionChange({
                checked: event.target.checked,
                selectedRows: "All",
                leadData: leadsData?.length ? leadsData : [],
              });
            }}
          />
        </div>
      ),
      dataIndex: "checkbox",
      width: 30,
    },
    {
      title: (
        <div className="d-flex align-items-center">
          <span>Profile</span>
          {leadsData.length > 0 && isCompletelySelected() && <div key={'select-all-leads'} className="ml-2">
            <ul className="d-flex filter-list">
              <li className={filterSelected ? 'bages leads-selected-wrap' : 'bages leads-unselected-wrap'}>
                <a
                  onClick={() => { handleFilterSelect(!filterSelected) }}
                  key={'select-all-index'}
                  className={filterSelected ? 'select-leads-btn selected' : 'select-leads-btn'}
                  target="_blank"
                >
                  {filterSelected ? `Unselect ${filteredLeads.length === 0 ?pagination.total:filteredLeads.length} Leads` : `Select ${filteredLeads.length === 0 ?pagination.total:filteredLeads.length} Leads`}
                </a>
              </li>
            </ul>
          </div>}
        </div>
      ),
      dataIndex: "profile",
      width: 300,
      render: (data) => <div>{data}</div>,
    },
    {
      title: "Profile Link",
      dataIndex: "profile_link",
      width: 100,
      render: (data) => <div>{data}</div>,
    },
    {
      title: "Groups",
      dataIndex: "groups",
      width: 300,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: 200,
    },
    {
      title: "Tag",
      dataIndex: "tag",
      width: 209,
    },
    {
      title: "List",
      dataIndex: "list_select",
      width: 201,
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      width: 100,
    },
    {
      title: "Actions",
      dataIndex: "action",
      fixed: "right",
      width: 180,
    },
  ];

  const handleTableChange = (newPagination, filters, sorter) => {
    const { current: newPage, pageSize: limit, total } = newPagination;
    setPagination({ current: newPage, pageSize: limit, total });
    dispatch(
      GetLeadsData({ filter, sort, skip: (newPage - 1) * limit, limit })
    );
  };

  const handleGetPipelineStages = (event) => {
    dispatch(GetPipelineStages({ pipelineId: event }));
  };

  const handleUpdateLeadsField = ({ label, value, leadId }) => {
    let newValue = value;
    if (label === 'email' && value.length && !validateEmail(value)) {
      setLead({ ...lead, email: '' });
      return dispatch(
        SetState({ field: "err", value: "Email Is Invalid" })
      );
    }
    if (label === "leadsValue") newValue = { value, createdAt: new Date() };
    else if (label === "links") {
      const links = lead.links || [];
      const alreadyExists = links.find((link) => link === linkValue);
      if (alreadyExists)
        return dispatch(
          SetState({ field: "err", value: "Link Is Already Added" })
        );
      if (!isValidURL(linkValue))
        return dispatch(
          SetState({ field: "err", value: "Please Enter A Valid URL" })
        );
      else newValue = [...links, linkValue];
      setAddLink(false);
    }
    setLead({ ...lead, [label]: newValue });
    dispatch(UpdateLeadsField({ leadId, label, value: newValue }));
  };

  const changeLeadsLink = ({ value, index }) => {
    const alreadyExists = lead?.links.findIndex((link) => link === value);
    if (alreadyExists !== -1 && alreadyExists !== index) return dispatch(SetState({ field: "err", value: "Link Is Already Added" }));
    if (value && !isValidURL(value))
      return dispatch(
        SetState({ field: "err", value: "Please Enter A Valid URL" })
      );
    let links = lead?.links;

    if (!value) links = links.filter((link, ind) => ind !== index);
    else links[index] = value;
    setLead({ ...lead, links });
    dispatch(
      UpdateLeadsField({ leadId: lead.leadId, label: "links", value: links })
    );
  };

  const handleSearchLeadsByFilter = (filterValue) => {
    setSelectedLeads([]);
    setFilterSelected(false);
    const { pageSize: limit } = pagination;
    
    dispatch(GetLeadsData({ filter: filterValue, sort, skip: 0, limit }))
      .unwrap()
      .then((result) => {
        setPagination((prev) => ({
          ...prev,
          total: result.totalLeads,
        }));
  
        return dispatch(GetLeadsData({ filter: filterValue, sort, skip: 0, limit: 0 })).unwrap();
      })
      .then((fullResult) => {
        setFilteredLeads(fullResult.leadsRecord);
      })
      .catch((err) => {
        console.error('Error fetching leads:', err);
      });
  
    // Reset pagination for the new search filter input
    setPagination({
      current: 1,      // Reset to the first page
      pageSize: limit, // Keep the page size unchanged for the first call
      total: 0,        // Reset total to 0 until leads data is fetched
    });
  };  

  const handleFiltersChange = ({ label, event }) => {
    let filterValue = {};
    if (label === undefined) {
      filterValue = {
        ...filter,
        friendsOnly: false,
        requestSent: false,
        noFriends: false,
        messenger: false,
      };
    } else if (
      label === "noFriends" ||
      label === "messenger" ||
      label === "requestSent" ||
      label === "friendsOnly"
    ) {
      filterValue = {
        ...filter,
        friendsOnly: false,
        requestSent: false,
        noFriends: false,
        messenger: false,
        [label]: event,
      };
    } else filterValue = { ...filter, [label]: event };
    setFilter(filterValue);
  };

  const handleString = (str) => {
    if (str) {
      if (str.length <= 20) return str;
      const maxLength = 20;
      const title = str.substr(0, maxLength);
      return `${title}.....`;
    } else return "";
  };

  const showTagsData = ({ leadId, tagsData }) => {
    return (
      <div className="tags-stucture d-flex">
        <>
          {[0, 1].map((index) => {
            return tagsData[index] ? (
              <div
                key={`key-${index}`}
                className="d-flex tags-wrapper-stucture"
              >
                <span className="tags-wrapper tags-content status Success">
                {tags.find((tag) => tag._id === tagsData[index])?.name}
                </span>
              </div>
            ) : (
              ""
            );
          })}
          {tagsData.length > 2 && (
            <div className="d-flex tags-btn-wrapper-stucture">

              <span
                className="btn btn-success"
                onMouseEnter={() => setShowTags(leadId)}
                onMouseLeave={() => setShowTags(null)}
              >
                {tagsData.length <= 10 ? tagsData.length : "10+"}
              </span>
              {showTags === leadId && tags.length > 2 && (
                <div className="tags-box">
                  <div className="d-flex flex-wrap overlay-tags">
                    {tagsData?.map((tag, index) => {
                      return (
                        <div key={index} className="d-flex tags-wrapper-stucture">
                          <span className="tags-wrapper tags-content status Success">
                          {tags.find((tag) => tag._id === tagsData[index])?.name  }
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      </div>
    );
  };

  const showListData = ({ leadId, listsData }) => {
    return (
      <div className="tags-stucture d-flex">
        <>
          {[0, 1].map((index) => {
            return listsData[index] ? (
              <div
                key={`key-${index}`}
                className="d-flex tags-wrapper-stucture"
              >
                <span className="tags-wrapper tags-content status Success">
                  {lists.find((list) => list._id === listsData[index])?.name}
                </span>
              </div>
            ) : (
              ""
            );
          })}
          {listsData?.length > 2 &&
            <div className="d-flex tags-btn-wrapper-stucture">
              <span
                className="btn btn-success"
                onMouseEnter={() => setShowList(leadId)}
                onMouseLeave={() => setShowList(null)}
              >
                {listsData.length <= 10 ? listsData.length : "10+"}
              </span>
              {showList === leadId && listsData.length > 2 && (
                <div className="tags-box">
                  <div className="d-flex flex-wrap overlay-tags">
                    {listsData?.map((list, index) => {
                      return (
                        <div key={index} className="d-flex tags-wrapper-stucture">
                          <span className="tags-wrapper tags-content status Success">
                            {lists.find((data) => data._id === list)?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>}
        </>
      </div>
    );
  };

  const showGroupsData = ({ leadId, groupsData }) => {
    try {
    return (
      <div className="tags-stucture d-flex">
        <>
          {[0, 1].map((index) => {
            return groupsData[index] ? (
              <div
                key={`key-${index}`}
                className="d-flex tags-wrapper-stucture"
              >
                <span className="tags-wrapper tags-content status Success">
                  {groupsList.find((group) => group.groupId === groupsData[index])?.name}
                </span>
              </div>
            ) : (
              ""
            );
          })}
          {groupsData?.length > 2 &&
            <div className="d-flex tags-btn-wrapper-stucture">
              <span
                className="btn btn-success"
                onMouseEnter={() => setShowGroups(leadId)}
                onMouseLeave={() => setShowGroups(null)}
              >
                {groupsData.length <= 10 ? groupsData.length : "10+"}
              </span>
              {showGroups === leadId && groupsData.length > 2 && (
                <div className="tags-box">
                  <div className="d-flex flex-wrap overlay-tags">
                    {groupsData?.map((group, index) => {
                      return (
                        <div key={index} className="d-flex tags-wrapper-stucture">
                          <span className="tags-wrapper tags-content status Success">
                          {groupsList.find((data) => data.groupId === group)?.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>}
        </>
      </div>
    );
          
  } catch (error) {
      console.log(error)
  }
  };


  
  const handleDeleteLeadsData = async () => {
    let leads = [];
    if (singleUserAction) leads.push(lead?._id);
    else leads = selectedLeads.map((lead) => lead._id);
    setDisabled(true);
    await dispatch(DeleteLeadsData({ leadIds: allLeadsSelected ? [] : leads, allLeadsSelected, filter: appliedFilter }));
    setDeleteModal(false);
  };

  const handleAddLeadsPipeline = async () => {
    let leadIds = selectedLeads.map((lead) => lead._id);
    if (!pipelineStageData.stageId || !pipelineStageData.pipelineId) {
      return dispatch(
        SetState({
          field: "err",
          value: "Please Select All The Required Fields",
        })
      );
    }
    await dispatch(AddLeadsToPipeline({ leadIds: allLeadsSelected ? [] : leadIds, ...pipelineStageData, allLeadsSelected, filter: appliedFilter }));
    const { current: newPage, pageSize: limit } = pagination;
    await dispatch(
      GetLeadsData({ filter, sort, skip: (newPage - 1) * limit, limit })
    );
  };

  const sendFriendRequest = async () => {
    if (!chrome.runtime) {
      dispatch(SetState({ field: "err", value: "Extension Is Not Installed" }));
      setAddFriendModal(false);
      return;
    }
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response.isUserValid === false) return dispatch(SetState({ field: "err", value: "This Facebook Account Is Not Attached" }));
        if (response && response.success) {
          let leadIds = [];

          setExtensionLoading(true);

          if (singleUserAction) leadIds.push(lead?.leadId);
          else
            leadIds = selectedLeads
              .filter((lead) => {
                if (!lead?.relationship?.friends)
                  return lead?.leadId;
              })
              ?.map(({ leadId }) => leadId);
          if (!leadIds?.length) {
            setExtensionLoading(false);
            return dispatch(SetState({ field: "err", value: "No Leads Found" }));
          }
          await chrome.runtime?.sendMessage(
            ExtensionId,
            { messageType: "Send_Friend_Request", profileIds: leadIds },
            async (response) => {
              await dispatch(
                AddLeadsRelationshipStatus({ leadIds, label: 'requestSent', value: true })
              );
              const { current: newPage, pageSize: limit } = pagination;
              await dispatch(
                GetLeadsData({
                  filter,
                  sort,
                  skip: (newPage - 1) * limit,
                  limit,
                })
              );
              setSelectedLeads([]);
              setAddFriendModal(false);
              setExtensionLoading(false);
              if (response) {
                dispatch(SetState({ field: "err", value: response.message }));
              }
            }
          );
        } else {
          setAddFriendModal(false);
          dispatch(
            SetState({ field: "err", value: "Extension Is Not Installed" })
          );
        }
      }
    );
  };

  const removeFacebookFriends = async () => {
    if (!chrome.runtime) {
      dispatch(SetState({ field: "err", value: "Extension Is Not Installed" }));
      setRemove(false);
      return;
    }
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response.isUserValid === false) return dispatch(SetState({ field: "err", value: "This Facebook Account Is Not Attached" }));
        if (response && response.success) {
          let leadIds = [];
          setExtensionLoading(true);
          if (singleUserAction) leadIds.push(lead?.leadId);
          else
            leadIds = selectedLeads
              .filter((lead) => {
                if (lead.relationship?.friends) {
                  return lead.leadId;
                }
              })
              ?.map(({ leadId }) => leadId);
          if (leadIds.length <= 0) {
            setExtensionLoading(false);
            setRemove(false);
            dispatch(SetState({ field: "err", value: "No Friends Found" }));
            return;
          }
          await chrome.runtime?.sendMessage(
            ExtensionId,
            { messageType: "Remove_Friend", profileIds: leadIds },
            async (response) => {
              setSelectedLeads([]);
              setRemove(false);
              setExtensionLoading(false);
              dispatch(
                AddLeadsRelationshipStatus({ leadIds, label: 'friends', value: false })
              );
              dispatch(SetState({ field: "success", value: true }));
            }
          );
        } else {
          setRemove(false);
          dispatch(
            SetState({ field: "err", value: "Extension Is Not Installed" })
          );
        }
      }
    );
  };
  const changeLeadsRecord = ({ label, event }) => {
    if (label === "contact" && event.target.value > 999999999999) return;
    if (label === "email" && event.length > 60)
      SetState({ field: "err", value: "Email Length Exceeded The Limit" });
    let value =
      label === "tags" || label === "lists" ? event : event?.target?.value;
    if (label === "link") {
      const alreadyExists = lead?.links.find((link) => link === linkValue);
      if (alreadyExists)
        return dispatch(
          SetState({ field: "err", value: "Link Is Already Added" })
        );
      if (!isValidURL(linkValue))
        return dispatch(
          SetState({ field: "err", value: "Please Enter A Valid URL" })
        );
      else {
        const links = [...lead.links, linkValue];
        dispatch(
          UpdateLeadsRecord({
            ...lead,
            ...pipelineStageData,
            links: [...lead.links, linkValue],
          })
        );
        setLead({ ...lead, links });
        const { current: newPage, pageSize: limit } = pagination;
        return dispatch(
          GetLeadsData({ filter, sort, skip: (newPage - 1) * limit, limit })
        );
      }
    }

    if (label === "leadsValue")
      return setLead({ ...lead, [label]: { value, createdAt: new Date() } });
    setLead({ ...lead, [label]: value });
  };

  const handleBlockLeadModal = async () => {
    let leadIds = [];
    if (singleUserAction) leadIds.push(lead?._id);
    else leadIds = selectedLeads.map((lead) => lead._id);
    setDisabled(true);
    await dispatch(BlockLeadsData({ leadIds: allLeadsSelected ? [] : leadIds, filter: appliedFilter, allLeadsSelected }));
    setBlock(false);
  };

  const handleAddLeadsToLists = async ({
    singleUserAction,
    value,
    leadData,
    index,
  }) => {
    let leadIds = [];
    let listIds = [];

    if (singleUserAction) {
      let { leadId } = leadData;
      leads[index].lists = value;
      listIds = value;
      leadIds.push(leadId);
    } else {
      listIds = listsRecord;
      leadIds = selectedLeads.map((lead) => lead.leadId);
    }
    if (!listIds.length) return dispatch(SetState({ field: "err", value: "No List Is Selected" }));
    setDisabled(true);
    await dispatch(AddLeadsToLists({ listIds, leadIds: allLeadsSelected ? [] : leadIds, filter: appliedFilter, allLeadsSelected }));

  };

  const handleUpdateLeadsRecord = async () => {
    if (lead.email && !validateEmail(lead.email))
      return dispatch(SetState({ field: "err", value: "Email Is Invalid" }));
    await dispatch(
      UpdateLeadsRecord({
        ...lead,
        ...pipelineStageData,
        notes: lead.notes?.trim(),
      })
    );
    await dispatch(
      GetLists({ filter: { keyword: "" }, sort: sortByCreation, skip: 0 })
    );
    await dispatch(
      GetTags({ filter: { keyword: "" }, sort: sortByCreation, skip: 0 })
    );
  };

  const removeLeadsLink = async (value) => {
    const links = lead?.links?.filter((link) => link !== value);
    setLead({ ...lead, links });
    dispatch(UpdateLeadsField({ leadId: lead.leadId, label: "links", links }));
  };

  const checkObjectIsEmpty = (Obj) => {
    let isEmpty = true;
    Object.entries(Obj).map((data) => {
      const key = data[0];
      const value = data[1];
      if (key === "keyword" && value !== "") isEmpty = false;
      else if ((key === "postId" || key === "groupId") && value !== null)
        isEmpty = false;
      else if ((key === "lists" || key === "tags") && value?.length)
        isEmpty = false;
      else if (value === true) isEmpty = false;
    });
    return isEmpty;
  };

  const pushFiltersInArray = ({ key, value, name }) => {
    if (key === "postId" || key === "groupId") {
      return (
        <>
          <span className="d-flex align-items-center">
            <a target="_blank" href={`https://www.facebook.com/${value}`}>
              {name}
            </a>
            <img
              onClick={() => {
                handleRemoveFilter({ key });
              }}
              className="leads-overlay-bag-wrapper"
              src={bagDelete}
            />
          </span>
        </>
      );
    }
    if (key === "lists" || key === "tags") {
      return (
        <>
          <span className="d-flex align-items-center">
            {name}
            <img
              onClick={() => {
                handleRemoveFilter({ key, value });
              }}
              className="leads-overlay-bag-wrapper"
              src={bagDelete}
            />
          </span>
        </>
      );
    }
    return (
      <>
        <span className="d-flex align-items-center">
          {name}
          <img
            onClick={() => {
              handleRemoveFilter({ key });
            }}
            className="leads-overlay-bag-wrapper"
            src={bagDelete}
          />
        </span>
      </>
    );
  };

  const showSelectedFilters = () => {
    let filterArray = [];

    Object.entries(appliedFilter).map((data, index) => {
      const key = data[0];
      const value = data[1];
      if (key === "keyword" && value.length) {
        filterArray.push(
          <div key={index} className="d-flex">
            <ul className="d-flex filter-list">
              <li className="bages">
                <a key={index} className="text-dark" target="_blank">
                  {pushFiltersInArray({
                    key,
                    value,
                    name: appliedFilter[key]
                  })}
                </a>
              </li>
            </ul>
          </div>
        );
      }

      if (value) {
        if (key === "postId") {
          filterArray.push(
            <div key={index} className="d-flex">
              <ul className="d-flex filter-list">
                <li className="bages">
                  <a key={index} className="text-dark" target="_blank">
                    {pushFiltersInArray({
                      key,
                      value,
                      name: postsList.find(
                        (data) => data.postId === appliedFilter.postId
                      )?.name,
                    })}
                  </a>
                </li>
              </ul>
            </div>
          );
        }
        if (key === "groupId") {
          filterArray.push(
            <div key={index} className="d-flex">
              <ul className="d-flex filter-list">
                <li className="bages">
                  <a key={index} className="text-dark" target="_blank">
                    {pushFiltersInArray({
                      key,
                      value,
                      name: groupsList.find(
                        (data) => data.groupId === appliedFilter.groupId
                      )?.name,
                    })}
                  </a>
                </li>
              </ul>
            </div>
          );
        }
        if (key === "lists" && value.length) {
          appliedFilter.lists.map((data, index) => {
            filterArray.push(
              <div key={`lists-${index}`} className="d-flex">
                <ul className="d-flex filter-list">
                  <li className="bages">
                    <a className="text-dark" target="_blank">
                      {
                        pushFiltersInArray({
                          key,
                          name: lists.find((list) => list._id === data)?.name,
                          value: data
                        })
                      }
                    </a>
                  </li>
                </ul>
              </div>
            );
          });
        }
        if (key === "tags" && value.length) {
          appliedFilter.tags.map((data, index) => {
            filterArray.push(
              <div key={`tags-${index}`} className="d-flex">
                <ul className="d-flex filter-list">
                  <li className="bages">
                    <a key={index} className="text-dark" target="_blank">
                      {
                        pushFiltersInArray({
                          key,
                          name: data,
                          value: data
                        })
                      }
                    </a>
                  </li>
                </ul>
              </div>
            );
          });
        }
        if (key === "friendsOnly") {
          filterArray.push(
            <div key={index} className="d-flex">
              <ul className="d-flex filter-list">
                <li className="bages">
                  <a key={index} className="text-dark" target="_blank">
                    {pushFiltersInArray({ key, name: "Facebook Friends" })}
                  </a>
                </li>
              </ul>
            </div>
          );
        }
        if (key === "requestSent") {
          filterArray.push(
            <div key={index} className="d-flex">
              <ul className="d-flex filter-list">
                <li className="bages">
                  <a key={index} className="text-dark" target="_blank">
                    {pushFiltersInArray({ key, name: "Sent Requests" })}
                  </a>
                </li>
              </ul>
            </div>
          );
        }
        if (key === "noFriends") {
          filterArray.push(
            <div key={index} className="d-flex">
              <ul className="d-flex filter-list">
                <li className="bages">
                  <a key={index} className="text-dark" target="_blank">
                    {pushFiltersInArray({ key, name: "Others" })}
                  </a>
                </li>
              </ul>
            </div>
          );
        }
        if (key === 'messenger') {
          filterArray.push(<div key={index} className="d-flex">
            <ul className="d-flex filter-list">
              <li className="bages"><a key={index} className="text-dark" target="_blank">
                {pushFiltersInArray({ key, name: "Messenger" })}
              </a></li>
            </ul>
          </div>)
        }
      }
    });

    return filterArray;
  };

  const isValidHexadecimalID = (id) => {
    const hexRegex = /^[0-9a-fA-F]{24}$/;
    return hexRegex.test(id);
  };

  const showLeadsLists = (listIds) => {
    const listsData = [];
    listIds?.map((listId, index) => {
      const foundList = lists.find((data) => data._id === listId);

      if (foundList) {
        listsData.push(foundList._id);
      }
      else {
        const notAnID = isValidHexadecimalID(listId)
        if (!notAnID) {
          listsData.push(listId);
        }

      }
    });
    
    return listsData;
  }
  const showLeadsTags = (tagIds) => {
    const tagData = [];
    tagIds?.map((tagId, index) => {
      const foundList = tags.find((data) => data._id === tagId);

      if (foundList) {
        tagData.push(foundList._id);
      }
      else {
        const notAnID = isValidHexadecimalID(tagId)
        if (!notAnID) {
          tagData.push(tagId);
        }
      }
    });
    
    return tagData;
  }

  const imageErrors = (image) => {
    if (document.querySelector(`img[src="${image}"]`))
      document.querySelector(`img[src="${image}"]`).src = NoUser;
  };

  const setRelationshipStatus = (relation) => {
    let status = {};
    if (relation && !isEmpty(relation)) status = relation;
    const { friends, groupMember, postMember, messenger } = status;

    return (
      <div className="d-flex profile-url relationship-data gap-3">
        {friends && (
          <Tooltip placement="top" title={"Facebook Friend"}>
            <UserAddOutlined />
          </Tooltip>
        )}
        {groupMember && (
          <Tooltip placement="top" title={"Group Member"}>
            <UsergroupOutlined />
          </Tooltip>
        )}
        {postMember && (
          <Tooltip placement="top" title={"Post Member"}>
            <SolutionOutlined />
          </Tooltip>
        )}
        {messenger && (
          <Tooltip placement="top" title={"Messenger"}>
            <MessageOutlined />
          </Tooltip>
        )}
        {(!relation || isEmpty(relation)) && (
          <Tooltip placement="top" title={"No Relation"}>
            <UserOutlined />
          </Tooltip>
        )}
      </div>
    );
  };

  const tableProps = {
    loading,
  };

  const handleApplyFilters = (filterValue) => {
    if (isEqual(filter, appliedFilter)) return dispatch(
      SetState({ field: "err", value: "Please Select Any Filter" })
    );
    setAppliedFilter(filterValue);
    handleSearchLeadsByFilter(filterValue);
    setOpenSearchFilter(false);
    if (checkObjectIsEmpty(appliedFilter)) setAllLeadsSelected(false)
  }

  const handleRemoveFilter = ({ key, value = null }) => {
    const newFilter = {
      keyword: "",
      lists: [],
      tags: [],
      messenger: false,
      noFriends: false,
      requestSent: false,
      friendsOnly: false,
      postId: null,
      groupId: null,
    };
    let filterValue = {};
    let appliedFilterValue = {};

    if (key === 'lists' || key === 'tags') {
      filterValue = { ...filter, [key]: filter[key]?.filter(data => data !== value) || [] };
      appliedFilterValue = { ...filter, [key]: appliedFilter[key]?.filter(data => data !== value) || [] };
    } else {
      filterValue = { ...filter, [key]: newFilter[key] };
      appliedFilterValue = { ...appliedFilter, [key]: newFilter[key] };
    }

    if (
      key === 'messenger'
      || key === 'noFriends'
      || key === 'friendsOnly'
      || key === 'requestSent'
    ) setFriendshipStatus(false);

    setFilter(filterValue);
    setAppliedFilter(appliedFilterValue);

    handleSearchLeadsByFilter(filterValue);
  };

  const searchLeadsByInput = () => {
    setOpenSearchFilter(false);
    if (!filter.keyword && !appliedFilter.keyword) return;
    const filterValue = { ...appliedFilter, keyword: filter.keyword };
    setAppliedFilter(filterValue);
    handleSearchLeadsByFilter(filterValue);
    dispatch(GetGroupsList());
  };

  const handleSelectAllLeads = (value) => {
    setAllLeadsSelected(value);

    if (value) {
      dispatch(GetAllLeadsData())
        .unwrap()
        .then((allLeadsData) => {
          setSelectedLeads(allLeadsData?.leadsRecord || []);
        })
        .catch((err) => {
          console.error('Error fetching all leads:', err);
        });
    } else {
      setSelectedLeads([]);
    }
  };

  const handleFilterSelect = (value) => {
    setFilterSelected(value);
    
    if(value){
      if(filteredLeads.length === 0)
      {
        dispatch(GetAllLeadsData())
        .unwrap()
        .then((allLeadsData) => {
          setSelectedLeads(allLeadsData?.leadsRecord || []);
        })
        .catch((err) => {
          console.error('Error fetching all leads:', err);
        });
        return;
      }
      setSelectedLeads(filteredLeads);
    } else {
      setSelectedLeads([]);
    }
  };

  const data = [];
  leads.forEach((lead, index) => {
    data.push({
      key: index,
      checkbox: (
        <div className="d-flex user-profile align-items-center gap-3">
          <Checkbox
            checked={isSelected(lead)}
            onChange={(event) => {
              onRowSelectionChange({
                checked: event.target.checked,
                selectedRows: "Single",
                leadData: lead,
              });
            }}
          />
        </div>
      ),
      profile: (
        <div className="d-flex user-profile gap-3 align-items-center">
          <img
            className="user-img"
            src={lead.image}
            onError={() => {
              imageErrors(lead.image);
            }}
            alt={""}
          />
          <div className="heading-user-content-wrapper">
            <span>{lead.name}</span>
            <div className="d-flex lead-info-user">
              <Tooltip placement="top" title={lead.contact}>
                <label className="leads-user-number">
                  {lead.contact ? `${lead.contact}` : ""}
                </label>
              </Tooltip>
              <Tooltip placement="top" title={lead.email}>
                <label>{handleString(lead.email)}</label>
              </Tooltip>
            </div>
          </div>
        </div>
      ),
      profile_link: (
        <div className="user-profile-link">
          <a href={`https://www.facebook.com/${lead.leadId}?leadId=${lead.leadId}`} target="_blank">
            <img width="20" height="20" src={Facebook} alt="no-link" />
          </a>
          <a
            className="messanger-icon-wrap"
            href={`https://www.facebook.com/messages/t/${lead.leadId}?leadId=${lead.leadId}`}
            target="_blank"
          >
            <img
              className="messanger-icon"
              width="20"
              height="20"
              src={Messenger}
              alt=""
            />
          </a>
        </div>
      ),
      tag: lead.tags?.length
        ? showTagsData({ leadId: lead.leadId, tagsData: lead.tags })
        : "",
      list_select: lead.lists?.length
        ? showListData({ leadId: lead.leadId, listsData: lead.lists })
        : "",
      notes: (
        <div onMouseOver={() => setShowNote(lead.leadId)} className="d-flex align-items-center gap-3 note-tooltip position-relative">
          {showNote === lead.leadId ? <span onMouseLeave={() => setShowNote(null)} className="note-overlay-wrapper-leads">
            {lead.notes}
          </span> : <span >
            {handleString(lead.notes)}
          </span>}

        </div>
      ),
      relationship: (
        <div className="d-flex align-items-center gap-3">
          {setRelationshipStatus(lead.relationship)}
        </div>
      ),
      groups: lead.groups?.length
      ? showGroupsData({ leadId: lead.leadId, groupsData: lead.groups })
      : "",
      action: (
        <div className="table-actions">
          <Button
            className="btn-icon"
            type="info"
            to="#"
            shape="circle"
            onClick={() => {
              setSingleUserAction(true);
              setLead(lead);
              setLeadUpdated(lead);
              setEdit(true);
            }}
          >
            <Tooltip placement="top" title={"Edit Lead"}>
              <FeatherIcon icon="edit" size={16} />
            </Tooltip>
          </Button>
          {lead.relationship?.friends && (
            <Button
              className="btn-icon"
              type="danger"
              to="#"
              shape="circle"
              onClick={() => {
                setSingleUserAction(true);
                setLead(lead);
                setRemove(true);
              }}
            >
              <Tooltip placement="top" title={"Unfriend Lead"}>
                <UnFriend />
              </Tooltip>
            </Button>
          )}
          <Button
            className="btn-icon"
            type="danger"
            to="#"
            shape="circle"
            onClick={() => {
              setSingleUserAction(true);
              setLead(lead);
              setAddFriendModal(true);
            }}
          >
            <Tooltip placement="top" title={"Send Friend Request"}>
              <img src={AddFriend} />
            </Tooltip>
          </Button>
          <Button
            className="btn-icon"
            type="info"
            to="#"
            shape="circle"
            onClick={() => {
              setSingleUserAction(true);
              setLead(lead);
              setBlock(true);
            }}
          >
            <Tooltip placement="top" title={"Block Lead"}>
              <StopOutlined icon="trash-2" size={16} />
            </Tooltip>
          </Button>
          <Button
            className="btn-icon"
            type="danger"
            to="#"
            shape="circle"
            onClick={() => {
              setSingleUserAction(true);
              setLead(lead);
              setDeleteModal(true);
            }}
          >
            <Tooltip placement="top" title={"Delete Lead"}>
              <FeatherIcon icon="trash-2" size={16} />
            </Tooltip>
          </Button>
        </div>
      ),
    });
  });

  return (
    <>
      <DashboardCards />
      <PageHeader
        ghost
        title={`Leads: ${total}`}
        className={"page-header-main"}
        buttons={[
          <div key="6" className="page-header-actions">
            <div className="d-flex flex-column">
              <div key={1} className="search-box">
                <SearchInput
                  search
                  className="leads-search-bar"
                  type="text"
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      searchLeadsByInput();
                    }
                  }}
                  value={filter.keyword}
                  onChange={(event) => {
                    event.persist();
                    setFilter({ ...filter, keyword: event.target.value })
                  }}
                  onClick={() => {
                    handleCloseFilterByClick();
                    setOpenSearchFilter(true);
                    dispatch(GetGroupsList());
                  }}
                  name="search"
                  placeholder="Search by Name"
                />
              </div>
            </div>
            {openSearchFilter && (
              <div id={'search-filter-div'} ref={searchRef} className="d-flex justify-content-between filter-wrapper">
                <div className="d-flex">
                  {(
                    <div>
                      <div className="filter-dropdown-wrapper">
                        <div
                          className={
                            openSearchFilter
                              ? "overlay-advance-search-filter"
                              : "overlay-advance-search-filter d-none"
                          }
                        >
                          <div className="overlay-inner-wrapper">
                            <Row gutter={[16, 16]}>
                              <Col md={12}>
                                <CustomDropDownMenu
                                  id={'custom-drop-down-menu'}
                                  value={filter.postId ? filter.postId : null}
                                  name={"posts"}
                                  options={postsList}
                                  onChange={(event) => {
                                    handleFiltersChange({
                                      label: "postId",
                                      event,
                                    });
                                  }}
                                  placeholder={"Posts"}
                                />
                              </Col>
                              <Col md={12}>
                                <CustomDropDownMenu
                                  className="leads-custom-dropdown"
                                  id={'custom-drop-down-menu'}
                                  value={filter.groupId ? filter.groupId : null}
                                  name={"groups"}
                                  allowClear
                                  options={groupsList}
                                  onChange={(event) => {
                                    handleFiltersChange({
                                      label: "groupId",
                                      event,
                                    });
                                  }}
                                  placeholder={"Groups"}
                                />
                              </Col>
                              <Col md={12}>
                                <div>
                                  <SelectDropdown
                                    className="relationship-dropdown-wrapper"
                                    value={
                                      friendshipStatus ? friendshipStatus : null
                                    }
                                    name={"relationship"}
                                    onChange={(event) => {
                                      setFriendshipStatus(event);
                                      handleFiltersChange({
                                        label: event,
                                        event: true,
                                      });
                                    }}
                                    options={relationshipOptions}
                                    placeholder={"Relationship"}
                                  />
                                </div>
                              </Col>
                              <Col md={12}>
                                <div>
                                  <Select
                                    allowClear
                                    mode="tags"
                                    value={showLeadsLists(filter.lists)}
                                    onChange={(event) => {
                                      handleFiltersChange({
                                        label: "lists",
                                        event,
                                      });
                                    }}
                                    className="select-drop-down add-tags-wrapper"
                                    placeholder="Lists"
                                  >
                                    {lists.map((data, index) => {
                                      return (
                                        <Option key={index} value={data._id}>
                                          {data.name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </div>
                              </Col>
                              <Col md={12}>
                                <div>
                                  <Select
                                    mode="tags"
                                    allowClear
                                    value={filter.tags}
                                    placeholder="Tags"
                                    className="select-drop-down add-tags-wrapper"
                                    onChange={(event) => {
                                      handleFiltersChange({
                                        label: "tags",
                                        event,
                                      });
                                    }}
                                  >
                                    {lead.tags?.map((tag, index) => (
                                      <Option key={tag}>{tag}</Option>
                                    ))}
                                  </Select>
                                </div>
                              </Col>
                            </Row>
                          </div>
                          <div className="overlay-divider" />
                          <div className="fotter-wrapper d-flex">
                            <Button
                              className="cancel-overlay-btn ant-btn-white"
                              onClick={handleCancelFilters}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="save-overlay-btn"
                              onClick={() => {
                                handleApplyFilters(filter);
                              }}
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <Row>
              <Col>
                <div className="d-flex align-items-center flex-wrap social-buttons">
                  <div className="d-flex aligin-item-center checked-btn">
                    <Button
                      size="default"
                      type="primary"
                      outlined
                      className={(!allLeadsSelected && selectedLeads.length < 1) || appliedFilter.friendsOnly ? "condition-disable-btn-wrapper" : "condition-request-btn-wrapper"}
                      onClick={() => {
                        setSingleUserAction(false);
                        setAddFriendModal(true);
                      }}
                      disabled={(!allLeadsSelected && selectedLeads.length < 1) || appliedFilter.friendsOnly}
                    >
                      Send Friend Request
                    </Button>
                    <div className="bulk-action-wrappper">
                      <Tooltip placement="top" title={"UnFriend"} >
                        <Unfriend
                          className={selectedLeads.length >= 1 || allLeadsSelected ? "bulk-actions" : "disabled-icon"}
                          onClick={() => {
                            setSingleUserAction(false);
                            setRemove(true);
                          }}
                          disabled={!allLeadsSelected && !selectedLeads.length}
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={"Delete Users"}>
                        <DeleteActions
                          className={selectedLeads.length >= 1 || allLeadsSelected ? "bulk-actions" : "disabled-icon"}
                          onClick={() => {
                            setSingleUserAction(false);
                            setDeleteModal(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={"Block Users"}>
                        <BlockUser
                          className={selectedLeads.length >= 1 || allLeadsSelected ? "bulk-actions" : "disabled-icon"}
                          onClick={() => {
                            setSingleUserAction(false);
                            setBlock(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={"Add to Pipeline"}>
                        <PipelineIcon
                          className={selectedLeads.length >= 1 || allLeadsSelected ? "bulk-actions" : "disabled-icon"}
                          onClick={() => {
                            setAddToPipelinesModal(true);
                          }}
                        />
                      </Tooltip>
                      <Tooltip placement="top" title={"Add to Lists"}>
                        <ListIcon
                          className={selectedLeads.length >= 1 || allLeadsSelected ? "bulk-actions" : "disabled-icon"}
                          onClick={() => {
                            setAddLeadsToLists(true);
                          }}
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>,
        ]}
      />
      {/* <RowWrapper>
        <div className="d-flex justify-content-between filter-wrapper">
          <div className="leads-counter-wrap">
            <span>
              Leads: <span className="font-bold">{total}</span>
            </span>
          </div> */}
          {/* <div className="d-flex flex-wrap filter-bages">
            {!checkObjectIsEmpty(appliedFilter) && showSelectedFilters()}
            <div key={'select-all-leads'}>
              <ul className="d-flex filter-list">
                <li className={allLeadsSelected ? 'bages leads-selected-wrap' : 'bages leads-unselected-wrap'}>
                  <a onClick={() => { handleSelectAllLeads(!allLeadsSelected) }} key={'select-all-index'} className={allLeadsSelected ? 'text-dark leads-selected-span' : 'text-dark'} target="_blank">
                    {allLeadsSelected || (filteredLeads.length === 0 && filterSelected) ? 'Unselect All Leads' : 'Select All Leads'}
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
        {/* </div>
      </RowWrapper> */}
            
      <Main>
        <Row gutter={25}>
          <Col lg={24} xs={24}>
            <Cards headless>
              {<TableWrapper>
                <Table
                  // sticky
                  size="small"
                  scroll={{ x: 1200 }}
                  dataSource={data}
                  pagination={pagination}
                  columns={columns}
                  onChange={handleTableChange}
                  {...tableProps}
                  className="leads-custom-table"
                  loading={{
                    indicator: <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads" /></div>,
                    spinning: leadsLoading
                  }}
                />
              </TableWrapper>}
              <WrapperModal>
                <Modal
                  className="addEvent-modal edit-lead-modal"
                  footer={null}
                  type="primary"
                  title="Edit Lead"
                  visible={edit}
                  closable={true}
                  width={722}
                  onCancel={() => {
                    setEdit(false)
                  }}
                >
                  <div className="d-flex justify-content-between align-items-start edit-lead-modal-overflow-issue">
                    <div className="d-flex user-picture">
                      <img
                        src={lead.image}
                        height={80}
                        width={80}
                        onError={() => {
                          imageErrors(lead.image);
                        }}
                      />
                      <div className="user-info d-flex flex-column justify-content-end pl-3">
                        <h3 className="mb-0">{lead.name}</h3>
                        <span className="d-flex align-items-baseline condition-wrapper-modal">
                          {editEmail ? (
                            <Input
                              className="condition-input-wrapper"
                              onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                  setEditEmail(false);
                                  handleUpdateLeadsField({
                                    leadId: lead.leadId,
                                    label: "email",
                                    value: event.target.value,
                                  });
                                }
                              }}
                              onChange={(event) => {
                                changeLeadsRecord({
                                  label: "email",
                                  event,
                                });
                              }}
                              onBlur={(event) => {
                                setEditEmail(false);
                                handleUpdateLeadsField({
                                  leadId: lead.leadId,
                                  label: "email",
                                  value: event.target.value,
                                });
                              }}
                              type="text"
                              placeholder="Add/Edit Email"
                              value={lead.email}
                            />
                          ) : (
                            <>
                              {" "}
                              {!lead.email && (
                                <span
                                  onClick={() => setEditEmail(!editEmail)}
                                  className="plus-icon-modal cursor-pointer"
                                >
                                  +
                                </span>
                              )}
                              <span
                                onClick={() => setEditEmail(!editEmail)}
                                className="email"
                              >
                                {lead.email || "Add Email"}
                              </span>
                            </>
                          )}
                          {lead.email && (
                            <BiPencil
                              onClick={() => setEditEmail(!editEmail)}
                              className="pencil-icon"
                            />
                          )}
                        </span>
                        <span className="d-flex align-items-baseline condition-wrapper-modal">
                          {editContact ? (
                            <Input
                              onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                  setEditContact(false);
                                  handleUpdateLeadsField({
                                    leadId: lead.leadId,
                                    label: "contact",
                                    value: event.target.value,
                                  });
                                }
                              }}
                              onChange={(event) => {
                                changeLeadsRecord({
                                  label: "contact",
                                  event,
                                });
                              }}
                              onBlur={(event) => {
                                setEditContact(false);
                                handleUpdateLeadsField({
                                  leadId: lead.leadId,
                                  label: "contact",
                                  value: event.target.value,
                                });
                              }}
                              className="phone-condition-input-wrapper"
                              type="number"
                              placeholder="Add/Edit Contact"
                              value={lead.contact}
                            />
                          ) : (
                            <>
                              {!lead.contact && (
                                <span
                                  onClick={() => setEditContact(true)}
                                  className="plus-icon-modal cursor-pointer"
                                >
                                  +
                                </span>
                              )}{" "}
                              <span
                                onClick={() => setEditContact(true)}
                                className="email-phone"
                              >
                                {lead.contact || "Add Contact"}
                              </span>
                            </>
                          )}
                          {lead.contact && (
                            <BiPencil
                              onClick={() => setEditContact(true)}
                              className="pencil-icon"
                            />
                          )}
                        </span>
                        <span className="d-flex align-items-center link-wrapper-wrap-link">
                          {lead?.links?.map((link, index) => {
                            return (
                              <>
                                {editLink === index ? (
                                  <Input
                                    key={index}
                                    onKeyUp={(event) => {
                                      if (event.key === "Enter") {
                                        changeLeadsLink({
                                          value: event.target.value,
                                          index,
                                        });
                                        setEditLink(false);
                                      }
                                    }}
                                    onBlur={(event) => {
                                      changeLeadsLink({
                                        value: event.target.value,
                                        index,
                                      });
                                      setEditLink(false);
                                    }}
                                    className="social-condition-input-wrapper"
                                    type="text"
                                    placeholder="Edit Link"
                                    defaultValue={lead.links[index]}
                                  />
                                ) : (
                                  <>
                                    <a
                                      className="email-wrapper link-overflow d-flex"
                                      target="_blank"
                                      href={`${link}/${lead.leadId}`}
                                    >
                                      <span
                                        key={index}
                                        className="email-wrapper "
                                      >
                                        {link}
                                      </span>
                                    </a>{" "}
                                    <span
                                      style={{ marginLeft: "3px" }}
                                      className="icons-wrapper-space"
                                    >
                                      <BiPencil
                                        onClick={() => setEditLink(index)}
                                        className="pencil-icon"
                                      />
                                    </span>
                                    <FeatherIcon
                                      className="trash-icon-wrapper"
                                      onClick={() => {
                                        removeLeadsLink(link);
                                      }}
                                      icon="trash-2"
                                      size={12}
                                    />
                                  </>
                                )}
                              </>
                            );
                          })}
                          {!lead?.links?.length && (
                            <>
                              <span
                                onClick={() => setAddLink(true)}
                                className="plus-icon-modal add-email-plus-icon"
                              >
                                +
                              </span>
                              <span
                                onClick={() => setAddLink(true)}
                                className="add-link-wrapper"
                              >
                                Add Link
                              </span>
                            </>
                          )}
                          {lead?.links?.length ? (
                            <AiOutlinePlusCircle
                              onClick={() => setAddLink(true)}
                              className="add-link-wrapper plus-icon-wrap"
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column cost-and-socail-wrapper">
                      <div
                        className={
                          editValue
                            ? "cost-wrapper"
                            : "cost-condition-text-controle"
                        }
                      >
                        <span
                          className={
                            lead.leadsValue?.value
                              ? "value-text-wrap"
                              : "new-condition-wrapper"
                          }
                        >
                          Value:
                          {editValue ? (
                            <Input
                              onKeyUp={(event) => {
                                if (event.key === "Enter") {
                                  setEditValue(false);
                                  handleUpdateLeadsField({
                                    leadId: lead.leadId,
                                    label: "leadsValue",
                                    value: event.target.value,
                                  });
                                }
                              }}
                              onBlur={(event) => {
                                setEditValue(false);
                                handleUpdateLeadsField({
                                  leadId: lead.leadId,
                                  label: "leadsValue",
                                  value: event.target.value,
                                });
                              }}
                              onChange={(event) => {
                                changeLeadsRecord({
                                  label: "leadsValue",
                                  event,
                                });
                              }}
                              className="cost-condition-input-wrapper"
                              type="number"
                              placeholder="Add/Edit Value"
                              value={lead.leadsValue?.value}
                            />
                          ) : (
                            <>
                              {!lead.leadsValue?.value && (
                                <span
                                  onClick={() => setEditValue(true)}
                                  className="plus-icon-modal add-email-plus-icon cursor-pointer"
                                >
                                  +
                                </span>
                              )}
                              <Tooltip title={lead.leadsValue?.value}>
                                <span
                                  onClick={() => setEditValue(true)}
                                  className="cost-text-wrap"
                                >
                                  {lead.leadsValue?.value || "Add Value"}
                                </span>
                              </Tooltip>
                            </>
                          )}
                          {lead.leadsValue?.value && (
                            <BiPencil
                              onClick={() => setEditValue(true)}
                              className="pencil-icon"
                            />
                          )}
                        </span>
                      </div>
                      <div className="facebook-icon-wrap d-flex justify-content-end">
                        <a
                          href={`https://www.facebook.com/${lead.leadId}?leadId=${lead.leadId}`}
                          target="_blank"
                          rel="noreferrer"
                          className="fb-icon-wrapper"
                        >
                          <img src={Facebook} alt="no-media" />
                        </a>
                        <a
                          href={`https://www.facebook.com/messages/t/${lead.leadId}?leadId=${lead.leadId}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img src={Messenger} alt="no-media" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="add-link-wrapper-edit-modal">
                    <span className="add-new-link-wrapper">
                      {addLink ? (
                        <>
                          <span className="contion-add-link">Add Link</span>
                          <div className="d-flex align-items-center">
                            <Input
                              className="Add-link-input-wrapper"
                              onChange={(event) => {
                                setLinkValue(event.target.value);
                              }}
                              type="text"
                              value={linkValue}
                              placeholder="mysite"
                            />
                            <FaTimes
                              className="crose-icon-custom"
                              onClick={() => {
                                setAddLink(false);
                                setLinkValue("");
                              }}
                            />
                            <FiCheck
                              disabled={!linkValue}
                              onClick={() => {
                                if (!linkValue) return;
                                handleUpdateLeadsField({
                                  leadId: lead.leadId,
                                  label: "links",
                                });
                                setLinkValue("");
                              }}
                              className="check-custom-icon"
                            />
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>

                  <Divider />
                  <div className="leads-modal-wrapper">
                    <Form
                      form={form}
                      layout="vertical"
                      className="edit-lead-modal-form"
                    >
                      <div className="edit-lead-modal-select-wrapper">
                        <Row gutter={24}>
                          <Col md={24}>
                            <Form.Item label="Add Lists">
                              <div className="tags-select list-select-wrapper">
                                <Select
                                  allowClear
                                  showSearch
                                  mode="tags"
                                  filterOption={(input, option) =>
                                    (option?.children ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                  }
                                  value={showLeadsLists(lead.lists)}
                                  onChange={(event) => {
                                    changeLeadsRecord({
                                      label: "lists",
                                      event,
                                    });
                                  }}
                                  className="select-drop-down add-tags-wrapper"
                                  placeholder="Lists"
                                >
                                  {lists.map((data, index) => {
                                    return (
                                      <Option key={index} value={data._id}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Form.Item>
                          </Col>
                          <Col md={24}>
                            <Form.Item label="Add Tag">
                              <div className="tags-select list-select-wrapper">
                                <Select
                                  showSearch
                                  allowClear
                                  mode="tags"
                                  value={showLeadsTags(lead.tags)}
                                  placeholder="Tags"
                                  className="select-drop-down add-tags-wrapper"
                                  filterOption={(input, option) =>
                                    (option?.children ?? '')?.toLowerCase()?.includes(input?.toLowerCase())
                                  }
                                  onChange={(event) => {
                                    changeLeadsRecord({ label: "tags", event });
                                  }}
                                >
                                  {tags?.map((data, index) => {
                                    return (
                                      <Option key={index} value={data._id}>
                                        {data.name}
                                      </Option>
                                    );
                                  })}
                                </Select>
                              </div>
                            </Form.Item>
                          </Col>
                          <Col md={24}>
                            <span className="add-note-text mb-3">Add Note</span>
                            <TextArea
                              onChange={(event) => {
                                changeLeadsRecord({ label: "notes", event });
                              }}
                              value={lead.notes}
                              rows={4}
                              placeholder="Add Notes"
                            />
                          </Col>
                          <Col md={12} className="edit-modal-select-wrapper">
                            <Form.Item label="Add to Pipeline">
                              <Select
                                allowClear={pipelineStageData.pipelineId ? true : false}
                                className="selector-pipeline select-drop-down-edit-modal"
                                value={pipelineStageData.pipelineId}
                                onChange={(event) => {
                                  setPipelineStageData({
                                    ...pipelineStageData,
                                    pipelineId: event,
                                  });
                                  handleGetPipelineStages(event);
                                }}
                                placeholder="Select Pipeline"
                              >
                                {pipelinesData.map((data, index) => {
                                  return (
                                    <Option key={index} value={data._id}>
                                      {data.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={12} className="edit-modal-select-wrapper-1">
                            <Form.Item
                              className="stage-pipeline-modal-select"
                              label="Select Stage"
                            >
                              <Select
                                allowClear={pipelineStageData.stageId ? true : false}
                                className="selector-pipeline select-drop-down-edit-modal"
                                value={pipelineStageData.stageId}
                                onChange={(event) => {
                                  setPipelineStageData({
                                    ...pipelineStageData,
                                    stageId: event,
                                  });
                                }}
                                defaultValue="select"
                                placeholder="Select Stage"
                              >
                                {stagesData.map((data, index) => {
                                  return (
                                    <Option key={index} value={data._id}>
                                      {data.name}
                                    </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </div>
                  {lead?.pipelines && (
                    <div className="edit-lead-wrapper">
                      <h4 className="edit-lead-title">Pipelines</h4>
                      <div className="edit-lead-container">
                        {lead?.pipelines?.map((pipeline) => (
                          <div key={pipeline.pipelineId._id} className="edit-lead-item">
                            <h4 className="edit-lead-name">{pipeline.pipelineId.name}</h4>
                            <div className="edit-lead-stage">
                              <span>{pipeline.stageId.name}</span>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                
                  <div className="modal-footer d-flex justify-content-end">
                    <Button
                      onClick={() => {
                        setLead({});
                        setPipelineStageData({
                          pipelineId: null,
                          stageId: null,
                          overwriteStages: true,
                        });
                        setEdit(false);
                        setEditEmail(false);
                        setEditContact(false);
                        setEditValue(false);
                        setAddLink(false);
                        setEditLink(false);
                        setLinkValue("");
                      }}
                      size="default"
                      outlined
                      type="white"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        setDisabled(true);
                        handleUpdateLeadsRecord();
                      }}
                      size="default"
                      type="primary"
                      disabled={
                        disabled ||
                        (isEqual(lead, leadUpdated) &&
                          (!pipelineStageData.pipelineId || !pipelineStageData.stageId))
                      }
                      className="ml-2 save-btn-modal"
                    >
                      {!loading ? "Save" : <Spin size="small" />}
                    </Button>
                  </div>
                </Modal>
              </WrapperModal>
              <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                visible={block}
                width={440}
                closable={false}
              >
                <div className="d-flex justify-content-center align-items-center flex-column unfriend-heading-modal">
                  {singleUserAction ? (
                    <img
                      src={lead.image}
                      onError={() => {
                        imageErrors(lead.image);
                      }}
                      height={80}
                      width={80}
                      alt="block-user"
                      className="add-space"
                    />
                  ) : (
                    <span className="icon-block-user" />
                  )}
                  <h2 className="mb-3 text-center color-dark ">
                    {singleUserAction
                      ? "Are you sure you want to block this user?"
                      : "Are you sure you want to block these users?"}
                  </h2>
                  {singleUserAction ? (
                    <p className="text-center fs-14 color-gray">
                      Blocking this user will remove him from the system only
                      and will prevent him from being re-added to the system. It
                      will NOT block the user on Facebook itself.
                    </p>
                  ) : (
                    <p className="text-center fs-14 color-gray">
                      Blocking these users will remove them from the system only
                      and will prevent them from being re-added to the system.
                      It will NOT block the user on Facebook itself.
                    </p>
                  )}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined
                    type="white"
                    onClick={() => {
                      setBlock(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleBlockLeadModal}
                    size="default"
                    type="primary"
                    disabled={loading}
                    className="block-user"
                  >
                    {loading ? <Spin size="small" /> : "Block"}
                  </Button>
                </div>
              </Modal>

              <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                visible={deleteLeadModal}
                width={440}
                closable={false}
              >
                <div className="d-flex justify-content-center align-items-center flex-column unfriend-heading-modal">
                  {singleUserAction ? (
                    <img
                      src={lead.image}
                      onError={() => {
                        imageErrors(lead.image);
                      }}
                      height={80}
                      width={80}
                      alt="block-user"
                      className="add-space"
                    />
                  ) : (
                    <img className="pipeline-bulk-action" src={DeletePopUp} />
                  )}
                  <h2 className="mb-3 text-center color-dark ">
                    {singleUserAction
                      ? "Are you sure you want to delete this user?"
                      : "Are you sure you want to delete these users?"}
                  </h2>
                  {singleUserAction ? (
                    <p className="text-center fs-14 color-gray">
                      Deleting this user will only delete him from this
                      application. It won't be permanentely deleted and you can
                      sync him again.
                    </p>
                  ) : (
                    <p className="text-center fs-14 color-gray">
                      Deleting users will only delete them from this
                      application. They won't be permanentely deleted and you
                      can sync them again.
                    </p>
                  )}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined
                    type="white"
                    onClick={() => {
                      setDeleteModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDeleteLeadsData}
                    size="default"
                    type="primary"
                    disabled={disabled}
                    className="ml-2 delete-btn-modal"
                  >
                    {loading ? <Spin size="small" /> : "Delete"}
                  </Button>
                </div>
              </Modal>

              <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                visible={addToPipelinesModal}
                width={440}
                closable={false}
              >
                <div className="d-flex justify-content-center align-items-center flex-column pipeline-modal">
                  <img src={PipelineModalIcon} className="pipeline-bulk-action" />
                  <h2 className="mb-3 text-center color-dark pipeline-modal-heading">
                    <span className="">
                      {"Please select pipeline you want to assign these users"}
                    </span>
                  </h2>
                  <div className="pipeline-modal-label">
                    <label>Add Pipeline</label>
                    <br />
                    <Select
                      className="selector-pipeline add-pipeline m-0"
                      value={pipelineStageData.pipelineId}
                      onChange={(event) => {
                        setPipelineStageData({
                          ...pipelineStageData,
                          pipelineId: event,
                        });
                        handleGetPipelineStages(event);
                      }}
                      placeholder="Select Pipeline"
                    >
                      {pipelinesData.map((data, index) => {
                        return (
                          <Option key={index} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="pipeline-modal-label">
                    <br />
                    <label className="add-stage-label">Add Stage</label>
                    <br />
                    <Select
                      className="selector-pipeline add-pipeline m-0"
                      value={pipelineStageData.stageId}
                      onChange={(event) => {
                        setPipelineStageData({
                          ...pipelineStageData,
                          stageId: event,
                        });
                      }}
                      placeholder="Select Stage"
                    >
                      {stagesData.map((data, index) => {
                        return (
                          <Option key={index} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="pipeline-modal-label">
                    <br />
                    <Checkbox
                      checked={pipelineStageData.overwriteStages}
                      onChange={(event) => {
                        setPipelineStageData({
                          ...pipelineStageData,
                          overwriteStages: event.target.checked,
                        });
                      }}
                    >
                      <label>
                        Add leads to new stages, if previously added.
                      </label>
                    </Checkbox>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined
                    type="white"
                    onClick={() => {
                      setPipelineStageData({
                        pipelineId: null,
                        stageId: null,
                        overwriteStages: true,
                      });
                      setAddToPipelinesModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setDisabled(true);
                      handleAddLeadsPipeline();
                    }}
                    size="default"
                    type="primary"
                    disabled={disabled || !pipelineStageData.stageId}
                    className="ml-2 save-btn"
                  >
                    {loading ? <Spin size="small" /> : "Save To Pipeline"}
                  </Button>
                </div>
              </Modal>
              <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                visible={addLeadsToLists}
                width={440}
                closable={false}
              >
                <div className="d-flex justify-content-center align-items-center flex-column pipeline-modal pipeline-list-modal">
                  <img className="pipeline-bulk-action" src={ListModalIcon} />
                  <h2 className="mb-3 text-center color-dark ">
                    {
                      "Please select the lists you want to assign to these users"
                    }
                  </h2>
                  <div className="pipeline-modal-label">
                    <label>Add Lists</label>
                    <Select
                      showSearch
                      className="selector-pipeline add-pipeline add-leads-to-pipeline"
                      mode="multiple"
                      value={listsRecord}
                      filterOption={(input, option) =>
                        (option?.children ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      onChange={(event) => {
                        setListsRecord(event);
                      }}
                      placeholder="Lists"
                    >
                      {listsArr.map((data, index) => {
                        return (
                          <Option key={index} value={data._id}>
                            {data.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined
                    type="white"
                    onClick={() => {
                      setListsRecord([]);
                      setAddLeadsToLists(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      setDisabled(true);
                      handleAddLeadsToLists({ singleUserAction: false });
                    }}
                    disabled={disabled || !listsRecord.length}
                    size="default"
                    type="primary"
                    className="save-btn"
                  >
                    {loading ? <Spin size="small" /> : "Save"}
                  </Button>
                </div>
              </Modal>

              <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                visible={remove}
                width={440}
                closable={false}
              >
                <div className="d-flex justify-content-center align-items-center flex-column  unfriend-heading-modal">
                  {singleUserAction ? (
                    <img
                      src={lead.image}
                      onError={() => {
                        imageErrors(lead.image);
                      }}
                      height={80}
                      width={80}
                      alt="block-user"
                      className="add-space"
                    />
                  ) : (
                    <span className="icon-unfriend-wrapper" />
                  )}
                  {singleUserAction ? (
                    <h2 className="mb-3 text-center color-dark">
                      Are you sure you want to unfriend this user?
                    </h2>
                  ) : (
                    <h2 className="mb-3 text-center color-dark">
                      Are you sure you want to unfriend these users?
                    </h2>
                  )}
                  {singleUserAction ? (
                    <p className="text-center fs-14 color-gray">
                      This will unfriend this user on Facebook only. It will not
                      remove him from ChatPro.
                    </p>
                  ) : (
                    <p className="text-center fs-14 color-gray">
                      This will unfriend these users on Facebook only. It will
                      not remove them from ChatPro.
                    </p>
                  )}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined
                    type="white"
                    onClick={() => {
                      setRemove(false), setExtensionLoading(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={removeFacebookFriends}
                    size="default"
                    type="primary"
                    disabled={extensionLoading}
                    className="unfriend-btn-modal"
                  >
                    {extensionLoading ? <Spin size="small" /> : "Submit"}
                  </Button>
                </div>
              </Modal>
              <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                visible={addFriendModal}
                width={440}
                closable={false}
              >
                <div className="d-flex justify-content-center align-items-center flex-column send-friend-request-text">
                  <img
                    src={singleUserAction ? lead.image : AddFriend}
                    onError={() => {
                      imageErrors(lead.image);
                    }}
                    height={80}
                    width={80}
                    alt="block-user"
                    className={singleUserAction ? "add-space" : ""}
                  />
                  {singleUserAction ? (
                    <>
                      <h2 className="mb-3 text-center color-dark">
                        Are you sure you want to friend this lead?
                      </h2>
                      <p className="text-center fs-14 color-gray">
                        This will send request to lead on facebook.
                      </p>
                    </>
                  ) : (
                    <>
                      <h2 className="mb-3 text-center color-dark">
                        Are you sure you want to friend these leads?
                      </h2>
                      <p className="text-center fs-14 color-gray">
                        This will send requests to leads on facebook.
                      </p>
                    </>
                  )}
                </div>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined
                    type="white"
                    onClick={() => {
                      setAddFriendModal(false), setExtensionLoading(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={sendFriendRequest}
                    size="default"
                    type="primary"
                    disabled={extensionLoading}
                    className="request-btn-modal"
                  >
                    {extensionLoading ? (
                      <Spin size="small" />
                    ) : (
                      <>
                        {singleUserAction
                          ? "Send Request"
                          : "Start Sending Requests"}
                      </>
                    )}
                  </Button>
                </div>
              </Modal>
            </Cards>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Dashboard;
