import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import SignUp from '../container/profile/authentication/overview/signUp';
import SubscriptionPlans from '../container/profile/authentication/overview/subscriptionPlans';


import { setAuthToken } from '../config/axios/axios-configuration';

const NonAuthRoute = () => {
  const { token, status } = useSelector(state => state.auth);
  setAuthToken(token);
  const location = useLocation();
  const history = useHistory();
  const { pathname: pathName } = location;

  useEffect(() => {
    if (status === 'Active' && pathName.includes('auth')) {
      return history.push(`/dashboard`);
    }
  },[token])

  return (
    <Switch>
      <Route path='/non-auth/register' component={SignUp} />
      <Route path='/non-auth/free-plan' component={SubscriptionPlans} />
    </Switch>
  );
}

export default NonAuthRoute;
