/*global chrome*/

import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { Row, Col, Button } from "antd"

import LeftSide from "./authSideGrid"

import Video from "../../../../static/video/chat.mp4"
import {ReactComponent as Logo} from '../../../../static/img/logo-text.svg';

import { OnBoardingWrapper } from './style';

import {
  ExtensionId,
  parseData
} from "../../../../utils/constants";

const OnboardingOverview = () => {

  const history = useHistory();

  const handleNextOnboardingStep = () => {
    history.push(`/auth/onboarding-connect`)
  }

  const [extensionInstalled, setExtensionInstalled] = useState(true);

  const checkExtensionInstalled = () => {
    if (!chrome.runtime) return setExtensionInstalled(false);

    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response && response.success) setExtensionInstalled(true)
        else setExtensionInstalled(false)
      }
    );
  };

  useEffect(() => {
    checkExtensionInstalled();
  }, [])

  return (
    <OnBoardingWrapper>
      <Row>
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className='main-onboading-wrapper'>
            <div className='Onboarding-header'>
              <span className='overview-text-wrapper'>Overview Of <span className="logo-chat"> <Logo/></span> Extension</span>
              <p className='text-paragraph'>A social media marketing manager which allows you to add connect and manage your leads by saving their record, managing them, categorizing them by adding them on different lists, different pipelines and their stages.</p>
            </div>
            <div className='video-section-wrapper'>
              <video className='video-wrapper' controls>
                <source src={Video} />
              </video>
            </div>
            <div className='features-wrapper'>
              <h2 className='m-0'>List of Features</h2>
              <ul>
                <li>Auto react feature allows you to automatically react on facebook posts.</li>
                <li>Cancel request feature allows you to cancel bulk send requests.</li>
                <li>Sending bulk requests to leads from Chat Pro CRM.</li>
                <li>Sync facebook friends allows you to sync all facebook friends to ChatPro CRM.</li>
                <li>Sync facebook post reactors allows you to sync any facebook post's reactors to ChatPro CRM.</li>
                <li>Sync facebook post commentators allows you to sync any facebook post's commentators to ChatPro CRM.</li>
                <li>Sync facebook group members allows you to sync any facebook group's member to Chat Pro CRM.</li>
              </ul>
            </div>
            <div className='d-flex justify-content-end footer-wrapper'>
              <Button
                onClick={handleNextOnboardingStep}
                className='next-link-btn'
                >Next</Button>
              {!extensionInstalled ? <Button
                className='install-extention'>
                <a
                  target='_blank'
                  href="https://chrome.google.com/webstore/detail/chat-pro/ecgidnnlichmoalgoabboefegghdipmc"> Install Extension </a>
              </Button> : ''}
            </div>
          </div>
        </Col>
      </Row>

    </OnBoardingWrapper>
  );
}
export default OnboardingOverview;
