import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin } from 'antd';

import User from "../../static/img/user.jpeg";
import Notification from "../ant-notification/notification";
import Loader from "../../components/loader/index";

import { ReactComponent as BsFacebook } from "../../static/img/facebook-user-icon.svg";
import { ReactComponent as NoData } from "../../static/img/no-data-image.svg";
import { LinkFacebookWrapper } from './style';
import { ExtensionId, parseData } from '../../utils/constants';
import { SetState as SetAuthState, AttachFacebookProfile, SendFacebookProfileData } from "../../redux/slices/auth-slice";

const LinkWithFacebook = (props) => {

  const {
    auth: {
      token,
      success,
      message,
      err,
      loading: authLoading
    } } = useSelector(state => state);

  const {
    setSwitchAccount,
    profileId
  } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loggedInFacebook, setLoggedInFacebook] = useState(true);
  const [user, setUser] = useState({})

  useEffect(() => {
    if (success) {
      Notification({
        type: "success",
        message: message,
      });
      dispatch(SetAuthState({ field: "success", value: false }));
    }
    if (err) {
      Notification({
        type: "error",
        message: err,
      });
      dispatch(SetAuthState({ field: "err", value: false }));
    }
  }, [success, err])

  useEffect(() => {
    if (user.name && user.image && user.profileId) {
      setLoading(false);
    }
  }, [user])

  const handleAttachAccount = () => {
    if (!chrome.runtime) return dispatch(SetAuthState({ field: "err", value: 'Extension Is Not Installed' }));

    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response && response.success) {
          chrome.runtime?.sendMessage(
            ExtensionId,
            {
              messageType: "CHECK_LOGGED_IN",
            },
            async ({ loggedIn }) => {
              if (loggedIn) {
                const { image, name } = user;
                dispatch(SetAuthState({ field: "facebookProfileData", value: user }));
                dispatch(AttachFacebookProfile({ facebookProfileData: user, token }))
                dispatch(SendFacebookProfileData({ name, image }));
              } else return dispatch(SetAuthState({ field: "err", value: 'Please Login To Facebook' }));
            });
        } else return dispatch(SetAuthState({ field: "err", value: 'Extension Is Not Installed' }));
      });
  }

  const handleImageError = (image) => {
    if (document.querySelector(`img[src="${image}"]`))
      document.querySelector(`img[src="${image}"]`).src = NoUser;
  };

  const checkFacebookProfileData = () => {
    if (!chrome.runtime) return dispatch(SetAuthState({ field: "err", value: 'Extension Is Not Installed' }));

    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "CHECK_LOGGED_IN",
      },
      async ({ loggedIn }) => {
        setLoggedInFacebook(loggedIn);
        setLoading(true);
        if (loggedIn) {
          chrome.runtime?.sendMessage(
            ExtensionId,
            {
              messageType: "FETCH-FB-USER-DATA",
            },
            async (payload) => {
              if (!payload) {
                setLoading(false);
                return dispatch(SetAuthState({ field: "err", value: "Couldn't Fetch Users Data, Extension Is Not Installed" }));
              }
              const { fbUserData, profileId } = payload;

              const userData = parseData(fbUserData);
              const leadData = userData?.data?.node?.comet_hovercard_renderer?.user;
              const name = leadData?.name;
              const image = leadData?.profile_picture?.uri;

              if (name && profileId) {
                setUser({ name, image, profileId })
              } else {
                setLoading(false);
                dispatch(SetAuthState({ field: "err", value: "Couldn't Fetch Users Data, Please Try Again" }));
              }
            }
          );
        } else {
          setLoading(false);
          return dispatch(SetAuthState({ field: "err", value: 'Please Login To Facebook' }));
        }
      }
    );
  };

  useEffect(() => {
    checkFacebookProfileData();
  }, [])

  return (
    <LinkFacebookWrapper>
      {loading ? <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads" /></div> :
        <div> <div className='d-flex'>
          <BsFacebook />
          {loggedInFacebook ? <h1 className='d-flex'>Link your Facebook account to<p><span>Chat</span>Pro</p> </h1>
            : <h1 className='d-flex'>Please Sign In to your Facebook Account</h1>}
        </div>
          {loggedInFacebook && <><img src={user.image || "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"}
            onError={() => {
              handleImageError(lead.image);
            }} alt={User} className='fb-user-image' />
            <h2>{user.name}</h2>
            <h3>Are you sure about switching to this account?</h3>
            <ul>
              <li>All leads will be synced from that facebook account that you are including.</li>
              <li>No other facebook account will be entertained, if you want to sync leads from any other account, you will have to onboard it first.</li>
              <li>We will be including background jobs which will be syncing leads in the background, you will have the option to disable the background jobs in that case.</li>
            </ul>
            <div className='d-flex justify-content-end chat-pro-account-buttons' >
              <Button className='ant-btn-white' onClick={() => { setSwitchAccount(false) }} outlined={'true'}>Cancel</Button>
              <Button onClick={handleAttachAccount} disabled={authLoading} outlined={'true'}>{
                authLoading ? <Spin size="small" /> : 'Confirm'}</Button>
            </div></>}
          {!loggedInFacebook && <div className='no-data-illustration-wrapper d-flex justify-content-center align-items-center flex-column'>
            <NoData />
            <h6>No Account Signed In</h6>
          </div>}
        </div>}
    </LinkFacebookWrapper>
  );
}

export default LinkWithFacebook;