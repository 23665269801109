import styled from 'styled-components';

const SearchBar = styled.div`
span.anticon.anticon-search.search-icon {
    position: absolute;
    left: 212px;
    top: 10px;
    color:#5f63f3;
    cursor: pointer;
}
svg{
    cursor: pointer;
    color:#5f63f3;

}
input{
    border-radius:25px;
}
.search-input {
    width: 252px;
    height: 34px;
    margin-left: 3px;
    border-radius: 100%;
}

`;

export default SearchBar;
