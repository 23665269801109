/*global chrome*/

import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "antd"

import GraphOne from "../../static/img/graph.svg"
import GraphTwo from "../../static/img/2graph.svg"
import GraphThree from "../../static/img/3graph.svg"
import GraphFive from "../../static/img/5graph.svg"
import GraphSix from "../../static/img/6graph.svg"
import GraphSeven from "../../static/img/7graph.svg"
import GraphEight from "../../static/img/8graph.svg"
import Notification from "../ant-notification/notification";

import { Button } from "../../components/buttons/buttons";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { MdErrorOutline } from "react-icons/md";
import { BiX } from "react-icons/bi";

import { GoSync } from "react-icons/go";

import {
  ExtensionId,
} from "../../utils/constants";

import {
  GetDashboardRecord,
  SetState,
} from '../../redux/slices/dashboard-slice';

import { UpdateUserRecord, SetState as SetAuthState } from '../../redux/slices/auth-slice';

import { DashboardWrapper } from './style';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const getCardValue = ({
  id,
  value,
  counterValue,
  label,
  rating,
  graph
}) => {
  return {
    id,
    value,
    counterValue,
    label,
    rating,
    date: "Since last week",
    image: <img src={graph} />,
  }
}

const DashboardCards = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const history = useHistory();
  const dashboardData = useSelector((state) => state.dashboard);
  const { token, friendsSynced = false } = useSelector((state) => state.auth);

  const [cardsValue, setCardsValue] = useState([]);
  const [extensionInstalled, setExtensionInstalled] = useState(true);
  const [showExtensionLabel, setShowExtensionLabel] = useState(true);
  const [extensionLoading, setExtensionLoading] = useState(false);
  const { information, success, err } = dashboardData;

  const checkExtensionInstalled = () => {
    if (!chrome.runtime) return setExtensionInstalled(false);

    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response && response.success) setExtensionInstalled(true)
        else setExtensionInstalled(false)
      }
    );
  };

  useEffect(() => {
    const loggedInCookie = cookies.get('loggedIn');
    const tokenCookie = cookies.get('token');
    if (!loggedInCookie) {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set('loggedIn', true, { path: '/', expires: nextYear });
    }
    if (!tokenCookie) {
      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set('token', token, { path: '/', expires: nextYear });
    }
    dispatch(GetDashboardRecord());
    checkExtensionInstalled();
  }, [])

  useEffect(() => {
    const { success, err } = dashboardData;
    if (success) {
      Notification({
        type: "success",
        message: typeof success === "object" ? 'Success' : success,
      });
      dispatch(SetState({ field: "success", value: false }));
    }
    if (information) {
      Notification({
        type: "success",
        message: typeof information === "object" ? 'Success' : information,
      });
      dispatch(SetState({ field: "information", value: false }));
    }
    if (err) {
      Notification({
        type: "error",
        message: typeof err === "object" ? 'Error! Please Try Again' : err,
      });
      dispatch(SetState({ field: "err", value: false }));
    }
  }, [success, err, information])

  const getRatio = (value = 0) => {
    return value && !isNaN(value) ? value?.toFixed(2) : 0.00
  }

  useEffect(() => {
    const {
      leadsSynced = {},
      leadsInPipelines = {},
      valueOfDeals = {},
      leadsAddedInPipelines = {},
      columns = [],
    } = dashboardData || {};

    const cards = [];
    for (let i = 0; i < 4; i += 1) {
      switch (i) {
        case 0:
          const {
            value: totalSynced = 0,
            rating: syncedRatio = 0,
            blocked: blockedLeadsCount = 0,
          } = leadsSynced || {};
          cards?.push(getCardValue({
            id: i,
            label: "Leads Added",
            value: totalSynced,
            rating: getRatio(syncedRatio),
            counterValue: blockedLeadsCount,
            graph: GraphOne
          }));
          continue;
        case 1:
          const {
            value: totalLeadsMoved = 0,
            rating: leadsRating = 0
          } = leadsAddedInPipelines || {};
          cards.push(getCardValue({
            id: i,
            label: "Leads Added To Pipeline",
            value: totalLeadsMoved,
            rating: getRatio(leadsRating),
            graph: GraphTwo
          }));
          continue;
        case 2:
          const { value: totalLeads = 0, rating: leadsInPipelinesRatio = 0 } = leadsInPipelines || {};
          cards.push(getCardValue({
            id: i,
            label: "Leads Currently in Pipelines",
            value: totalLeads,
            rating: getRatio(leadsInPipelinesRatio),
            graph: GraphThree
          }));
          continue;
        case 3:
          const { value: dealsValue = 0, rating: dealsRating = 0 } = valueOfDeals || {};
          cards.push(getCardValue({
            id: i,
            label: "Value Of Deals",
            value: dealsValue,
            rating: getRatio(dealsRating),
            graph: GraphFive
          }));
          continue;
      }
    }

    const customCards = columns?.map((column, index) => {
      const {
        value = 0,
        rating: customColumnRating = 0.00,
        name: label
      } = column || {};
      const items = [GraphSix, GraphSeven, GraphEight];

      return getCardValue({
        id: 5 + index,
        label,
        value,
        rating: getRatio(customColumnRating),
        graph: items[Math.floor(Math.random() * items.length)]
      })
    }) || [];
    setCardsValue([...cards, ...customCards])

  }, [dashboardData])

  const options = [
    {
      value: 'leads',
      label: 'Leads',
    },
    {
      value: 'activeLeads',
      label: 'Active Leads',
    }
  ];

  const syncFriendsGraphql = async ({ type }) => {
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "CHECK_LOGGED_IN",
      },
      async ({ loggedIn }) => {
        if (loggedIn) {
          chrome.runtime?.sendMessage(
            ExtensionId,
            { messageType: "FETCH-GRAPHQL-FRIENDS", token, syncType: type },
            async (data) => {
              console.log(data, "hihi");
              setExtensionLoading(false);
              if (data?.isUserValid === false) return dispatch(SetState({ field: 'err', value: 'This Facebook Account Is Not Attached' }));
              dispatch(
                SetAuthState({
                  field: "friendsSynced",
                  value: true,
                })
              );
              await dispatch(UpdateUserRecord({ friendsSynced: true }));
              setTimeout(() => { history.push('/background-jobs') }, 5000)
            }
          );
        } else {
          setExtensionLoading(false);
          return dispatch(SetState({ field: 'err', value: 'Please Login To Facebook' }));
        }
      });
  };

  const handleTopLeads = async () => {
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "CHECK_LOGGED_IN",
      },
      async ({ loggedIn }) => {
        if (loggedIn) {
          await chrome.runtime?.sendMessage(
            ExtensionId,
            { messageType: "FETCH-TOP-LEADS", token },
            async (data) => {
              if (data?.isUserValid === false) return dispatch(SetState({ field: 'err', value: 'This Facebook Account Is Not Attached' }))
              dispatch(
                SetState({
                  field: "success",
                  value: "Syncing Recent Activity In The Opened Window",
                })
              );
            }
          );
        }
        else {
          return dispatch(SetState({ field: 'err', value: 'Please Login To Facebook' }));
        }
      });
  }

  return (
    <DashboardWrapper>
      <div id="sync-friends-div" className='header-wrapper d-flex justify-content-between align-items-center'>
        <h1 className='m-0'>Dashboard</h1>
        <div className='dashbaord-header-button-wrapper'>
          <Button
            outlined
            disabled={extensionLoading || !extensionInstalled}
            className="sync-facebook-friend"
            onClick={() => {
              setExtensionLoading(true);
              syncFriendsGraphql({ type: 'syncFriends' })
            }}
          >{<div className='d-flex'><GoSync />{extensionLoading ? 'Syncing Started...' : 'Sync Facebook Friends'}</div>}
          </Button>
          <Button
            outlined
            onClick={handleTopLeads}
            disabled={!extensionInstalled}
            className="sync-facebook-friend"
          >{<div className='d-flex'><GoSync /> Sync Recent Messages </div>}</Button>
        </div>
      </div>
      <div className=''>
        {!extensionInstalled && showExtensionLabel && <div className='error-notification-wrapper d-flex justify-content-between align-items-center'>
          <div className='error-inner'><MdErrorOutline /><span>The Chat Pro Chrome Extension is required for Chat Pro to work. Please open the extension or <a
            className='ancer-tags-wrapper'
            target='_blank'
            href='https://chrome.google.com/webstore/detail/chat-pro/ecgidnnlichmoalgoabboefegghdipmc'>
            click here </a>
            to install it.
          </span>
          </div>
          <BiX onClick={() => { setShowExtensionLabel(false) }} className='crose-icon-wraper' />
        </div>}
        <div className='graph-wrapper'>
          <div className="card-grid-wrapper">
            <Row className='m-0' gutter={[30, 25]}>
              {cardsValue.map(({ value, counterValue, label, rating, date, image, id }) => {
                return (
                  <Col key={id} span={6}>
                    <div className='column-wrapper'>
                      <div className="cards-wrapper d-flex justify-content-between">
                        <div className='content-area'>
                          <p className='m-0 message'>{label}</p>
                          <div className='d-flex justify-content-between'>
                            <div>
                              <h1 className='m-0'>{value}</h1>
                              <div className='d-flex rating-date-wrapper'>
                                <p className='m-0 rating-wrapper'><>
                                  {rating >= 100 ? <BsArrowUpShort />
                                    : <BsArrowDownShort />}<span>{rating}</span></></p>
                                <p className='m-0 date-wrapper'>{date}</p>
                              </div>
                              {/* {
                                counterValue !== undefined && (
                                  <Link to="/blocked-leads" className="" style={{fontSize: "12px", color: "#323232"}}>
                                    (Blocked users {counterValue})
                                  </Link>
                                )
                              } */}
                            </div>
                            <div className='icons-wrapper d-flex justify-content-end'>
                              {image}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default DashboardCards;
