import React, { useEffect } from "react";

import { Row, Col, Tooltip } from "antd"
import { useDispatch, useSelector } from 'react-redux';

import PlusIcon from "../../static/img/add-card-plus.svg";
import Notification from "../ant-notification/notification";
import User from "../../static/img/user.jpeg";

import { FiAlertCircle } from "react-icons/fi";
import { ReactComponent as BsFacebook } from "../../static/img/facebook-user-icon.svg";
import { ReactComponent as AcountSwitch } from "../../static/img/switch.svg";
import { EditProfileWrapper } from "./style";
import { ExtensionId } from '../../utils/constants';

import { SetState } from '../../redux/slices/auth-slice';

const EditProfile = (props) => {
  const dispatch = useDispatch();
  const { setSwitchAccount, name, image } = props;
  const { auth: { err } } = useSelector(state => state);

  useEffect(() => {
    if (err) {
      Notification({
        type: "error",
        message: err,
      });
      dispatch(SetState({ field: "err", value: false }));
    }
  },[err])

  const checkFacebookLoggedIn = () => {
    if (!chrome.runtime) return dispatch(SetState({ field: "err", value: 'Extension Is Not Installed' }));

    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "IS_EXTENSION_INSTALLED",
      },
      async (response) => {
        if (response && response.success) setSwitchAccount(true);
        else return dispatch(SetState({ field: "err", value: 'Extension Is Not Installed' }));
      }
    );
  };

  const handleImageError = () => {
    const userImage = document.querySelector('img[id="social-media-user"]');
    if (userImage) userImage.src = 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'
  }

  return (
    <EditProfileWrapper>
      <div className="social-media-header-wrapper">
        <h1 className="mb-0">Social Media Platform</h1>
        <div className="divider" />
      </div>
      <div className="social-profile-content-wrapper">
        <div className="content d-flex justify-content-between">
          <p className="mb-0">
            View social media platform that your ChatPro account is connected
            with.
          </p>
        </div>
      <Row gutter={16}>
        <Col md={8}>
        <div className="socail-media-card postion-relative">
        <img id="social-media-user" src={image || "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"} onError={handleImageError} alt={User} className="facebook-user-image"/>
       <Tooltip placement="top" title="Switch Account"><AcountSwitch onClick={checkFacebookLoggedIn} className="switch-icon"/></Tooltip>
       <BsFacebook className="facebook-icon"/>
        <h1 className="mb-0 text-center">{name}</h1>
        </div>
        </Col>
      </Row>
    
        <footer className="d-flex">
          <FiAlertCircle />
          <span className="note-text-wrapper">Note:</span>
          <p className="mb-0">
            Currently you can connect a single Facebook account with your
            ChatPro account. If you are interested in connecting multiple
            accounts, please reach out to <a href="#">support@chatpro.io</a>
          </p>
        </footer>
      </div>
    </EditProfileWrapper>
  );
};
export default EditProfile;
