import styled from "styled-components";

const TableWrapper = styled.div`
.ant-table-content::-webkit-scrollbar {
  display: none;
}
  height: calc(100vh - 100px);
  .ant-spin-container{
    margin-top:0px !important;
    margin-bottom:0px !important;
  }
  .page-header-main{
    padding: 24px 0;
  }

  button.ant-btn.ant-btn-primary.sc-brqgnP.bNoLGt {
    background: transparent;
    border: 1px solid #5f63f3;
    color: #5f63f3;
    position: relative;
    right: 2px;
}
button.ant-btn.ant-btn-primary.sc-brqgnP.bNoLGt:hover {
    background: #5f63f3;
    color: #fff;
}
.user-profile-link.d-flex {
    margin-left: -19px;
}
.user-profile-link.d-flex .link-socail {
    margin: 0px 18px;
    justify-content: center;
}
button.ant-btn.ant-btn-primary.sc-brqgnP.bNoLGt:hover .blocklead-icon {
    color: #fff;
}
.block-leads-lock-icon{
  margin-left:16px;
}
  .heading {
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    /* margin-left: -3px; */
    /* margin-top: 7px; */
    h3{
        font-size: 24px;
        margin-bottom:0px;
        /* margin-left: 3px; */
        font-weight:700 ;
    }
    .search-box {
    position: relative;
    box-shadow: 0 5px 5pxrgba(#9299b8,0.3);

          svg,
          img {
            min-width: 16px;
            color: #5f63f3;
            margin-left: 1px;
          }
        }
        input {
          border: 0 none;
          height: 36px;
          width: 252px;
          font-size: 12px;
          padding: 0 19px 0 12px;
          min-width: 152px;
          border-radius: 36px;
          &::placeholder {
            color: #adb4d2;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .ant-table-thead > tr > th {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    border-top: navajowhite;
    background: #ffff;
    border-bottom: 1px solid #000;
    position: relative;
    z-index:999999 ;
}


  span.ant-dropdown-trigger {
    display: none;
  }
  .ant-spin-container {
    background: white;
    padding-bottom: 10px;
    margin-top: 15px;
    border-radius: 8px;
  }

  .ant-table {
    height: calc(100vh - 258px);
    overflow: auto;
    border-radius: 6px;
    table {
      width: 100%;
      text-align: left;
      border-radius: 10px;
      border-collapse: separate;
      border-spacing: 0;
      line-height: 16.52px;
      margin-top: 0px;
        tr {
          background: #f8f9fb;
          border-radius: 10px;
          position: relative;
          th {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            text-align:left;
          }
          th:nth-child(3) {
            text-align: right;
          }
          th:nth-child(4) {
            text-align: right;
          }
        }
      }
      
.ant-empty.ant-empty-normal {
    height: calc(100vh - 379px);
    display: flex;
    justify-content: center;
    flex-direction: column;

}
      .ant-table-tbody {
        tr {
          td {
            border-bottom: none;
            font-weight: 500;
            line-height: 17px;
            text-align:left;
            background-color:#fff;
          }
          td:nth-child(3) {
            text-align: right;
          }
          td:nth-child(4) {
            text-align: left;
          }
        }
      }
    }
  }
  .ant-pagination {
    margin-right: 22px !important;
  }
  .ant-table-header {
padding-top: 10px;
  }
  .ant-modal .ant-modal-content {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 391px;
    text-align: center;
    flex-direction: column;
}
.ant-layout.ant-layout-has-sider {
  overflow: scroll;
}
.unblock-user{
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 391px;
    text-align: center;
    flex-direction: column;
}
.page-header-actions{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top:0px ;
    margin-left:16px ;
 button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    height: 32px;
    padding: 2px 10px;
    box-shadow: 0 3px 5px #9299b805;
}
}
.actions-icons{
  color:#5F63F2;
  margin-right:auto;
  cursor: pointer;
}
button:hover .actions-icons{
  color:#fff;
}
.d-flex.align-items-center.checkbox-wrapper .ant-checkbox-wrapper {
    position: relative;
    left: 37px;
    top: -3px;
}
.action-icons{
  width:14px;
  cursor: pointer;
}

.d-flex.user-profile{
  img{
      width:32px;
  }
}
.user-profile{
  .d-flex{
      align-items:center;
      grid-column-gap:6px;    
      label{
         font-size:10px;
         color:${({ theme }) => theme["primary-color"]};
         font-weight:${({ theme }) => theme["font-weight-normal"]};
      }
      .dot{
          width:4px;
          height:4px;
          background: #C1BCBC;
          border-radius:50px;

      }
  }
}
.d-flex.profile-url{

img {
  width: 22px;
}

}
.d-flex.align-items-center.relation-ship.gap-3 .profile-url {
    text-align: left;
    margin: 0px;
    padding-left: 27px;
}
.blockleads-btn-disable-wrapper {
    color: #9BA8B7 !important;
    border: 1px solid #9BA8B7 !important;
    background: #fff !important;
    cursor:not-allowed !important;
    &:hover{
      color: #9BA8B7 !important;
    border: 1px solid #9BA8B7 !important;
    background: #fff !important;
    cursor:not-allowed !important;
    }
    svg{
      color:#9BA8B7 !important;
    }
}
.buttons-block-leads{
  .pipeline-delete-card-btn{
    svg{
    }
  }
}

`;

export { TableWrapper };
