/*global chrome*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

import { ExtensionId } from "../../utils/constants";

const axios = axiosBaseUrl();

export const AddFriendsData = createAsyncThunk(
  'leads/addFriends',
  async (friends, thunkAPI) => {
    try {
      const response = await axios.post('/friends/add-friends-record', { leads: friends });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetLeadsData = createAsyncThunk(
  'leads/getLeadsData',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/leads/leads-record', { params: data });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetAllLeadsData = createAsyncThunk(
  'leads/getAllLeadsData',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/leads/all-leads');
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetGroupsList = createAsyncThunk(
  'groups/getGroupsList',
  async (leads, thunkAPI) => {
    try {
      const response = await axios.get('/groups/all-groups');
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetPostsList = createAsyncThunk(
  'posts/getPostsList',
  async (posts, thunkAPI) => {
    try {
      const response = await axios.get('/posts/all-posts');
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemovePostsRecord = createAsyncThunk(
  'posts/removePostsRecord',
  async (posts, thunkAPI) => {
    try {
      const { postIds } = posts;
      const response = await axios.delete('/posts/posts-record', { params: { postIds } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemoveGroupsRecord = createAsyncThunk(
  'posts/removeGroupsRecord',
  async (groups, thunkAPI) => {
    try {
      const { groupIds, deleteLeads } = groups;
      const response = await axios.delete('/groups/groups-record', { params: { groupIds, deleteLeads } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const DeleteLeadsData = createAsyncThunk(
  'leads/deleteLeadsData',
  async (data, thunkAPI) => {
    try {
      const { leadIds, allLeadsSelected, filter } = data;
      const response = await axios.post('/leads/delete-leads-record',{ leadIds, allLeadsSelected, filter });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const BlockLeadsData = createAsyncThunk(
  'leads/blockLeadsData',
  async (data, thunkAPI) => {
    try {
      const { leadIds, allLeadsSelected, filter } = data;
      const response = await axios.put('/leads/block-leads-record', { leadIds, allLeadsSelected, filter });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateLeadsRecord = createAsyncThunk(
  'leads/updateLeadsRecord',
  async (data, thunkAPI) => {
    try {
      const response = await axios.put('/leads/update-lead-record', { data }, { params: { leadId: data._id } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateLeadsField = createAsyncThunk(
  'leads/updateLeadsField',
  async (data, thunkAPI) => {
    try {
      const { label, value } = data;
      const response = await axios.put('/leads/update-leads-field', { label, value }, { params: { leadId: data.leadId } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddLeadsRelationshipStatus = createAsyncThunk(
  'leads/addRelationshipStatus',
  async (data, thunkAPI) => {
    try {
      const { leadIds, label, value } = data;
      const response = await axios.put('/leads/update-relationship-status', { label, value }, { params: { leadIds } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddLeadsToPipeline = createAsyncThunk(
  'leads/addLeadToPipeline',
  async (data, thunkAPI) => {
    try {
      const {
        leadIds,
        pipelineId,
        stageId,
        overwriteStages,
        allLeadsSelected,
        filter
      } = data;
      const response = await axios.put('/leads/add-pipelines-leads', {
        leadIds,
        pipelineId,
        allLeadsSelected,
        filter,
        stageId,
        overwriteStages
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SyncFriendsRecord = createAsyncThunk(
  'chrome/syncFriendsRecord',
  async (data, thunkAPI) => {
    try {
      await chrome.runtime?.sendMessage(ExtensionId, { messageType: "FETCH_FRIENDS_DATA", index },)
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddLeadsToLists = createAsyncThunk(
  'lists/addLeadsToLists',
  async (data, thunkAPI) => {
    try {
      const { leadIds, listIds, filter, allLeadsSelected } = data;
      const response = await axios.post('/lists/add-leads-to-lists', { 
        leadIds,
        listIds,
        allLeadsSelected,
        filter
      });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const user = createSlice({
  name: 'leads',
  initialState: {
    success: false,
    total: 0,
    message: '',
    information: '',
    leadsLoading: false,
    groupsLoading: false,
    postsLoading: false,
    pipelines: [],
    leadsData: [],
    groupsList: [],
    postsList: [],
    extensionLoading: false,
    fieldEdited: false,
    loading: false,
    err: '',
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState() {
      return {
        success: false,
        total: 0,
        leadsData: [],
        message: '',
        information: '',
        groupsList: [],
        postsList: [],
        loading: false,
        fieldEdited: false,
        err: '',
      };
    }
  },
  extraReducers: {
    [GetLeadsData.pending]: (state, action) => ({
      ...state,
      leadsLoading: true
    }),
    [GetLeadsData.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      leadsLoading: false,
      leadsData: action.payload.leadsRecord,
      total: action.payload.total
    }),
    [GetLeadsData.rejected]: (state, action) => ({
      ...state,
      loading: false,
      leadsLoading: false,
      err: action.payload.err?.error
    }),
    [GetAllLeadsData.pending]: (state, action) => ({
      ...state,
      leadsLoading: true,
      err: null,
    }),
    [GetAllLeadsData.fulfilled]: (state, action) => ({
      ...state,
      leadsLoading: false,
      leadsData: action.payload.leadsRecord,
      total: action.payload.total,
      err: null,
    }),
    [GetAllLeadsData.rejected]: (state, action) => ({
      ...state,
      leadsLoading: false,
      err: action.payload?.err,
    }),
    [GetGroupsList.pending]: (state, action) => ({
      ...state,
      groupsLoading: true
    }),
    [GetGroupsList.fulfilled]: (state, action) => ({
      ...state,
      groupsList: action.payload.data,
      groupsLoading: false
    }),
    [GetGroupsList.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error,
      groupsLoading: false
    }),
    [GetPostsList.pending]: (state, action) => ({
      ...state,
      postsLoading: true
    }),
    [GetPostsList.fulfilled]: (state, action) => ({
      ...state,
      postsList: action.payload.data,
      postsLoading: false
    }),
    [GetPostsList.rejected]: (state, action) => ({
      ...state,
      success: false,
      err: action.payload.err?.error,
      postsLoading: false
    }),
    [RemovePostsRecord.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [RemovePostsRecord.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
    }),
    [RemovePostsRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err?.error
    }),
    [RemoveGroupsRecord.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [RemoveGroupsRecord.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      success: true,
      message: action.payload.message,
    }),
    [RemoveGroupsRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err?.error
    }),
    [UpdateLeadsRecord.pending]: (state, action) => ({
      ...state,
      success: false,
      message: '',
      loading: true
    }),
    [UpdateLeadsRecord.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [UpdateLeadsRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      message: action.payload.err?.message,
      err: action.payload.err?.error
    }),
    [AddLeadsToPipeline.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [AddLeadsToPipeline.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload?.message,
      loading: false
    }),
    [AddLeadsToPipeline.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload?.err?.error
    }),
    [AddLeadsToLists.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [AddLeadsToLists.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [AddLeadsToLists.rejected]: (state, action) => ({
      ...state,
      success: false,
      err: action.payload.err?.error,
      loading: false
    }),
    [DeleteLeadsData.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [DeleteLeadsData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [DeleteLeadsData.rejected]: (state, action) => ({
      ...state,
      success: false,
      err: action.payload.err?.error,
      loading: false
    }),
    [BlockLeadsData.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [BlockLeadsData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [BlockLeadsData.rejected]: (state, action) => ({
      ...state,
      success: false,
      err: action.payload.err?.error,
      loading: false
    }),

    [BlockLeadsData.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [BlockLeadsData.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      message: action.payload.message,
      loading: false
    }),
    [BlockLeadsData.rejected]: (state, action) => ({
      ...state,
      success: false,
      err: action.payload.err?.error,
      loading: false
    }),
    [UpdateLeadsField.pending]: (state, action) => ({
      ...state,
      loading: false
    }),
    [UpdateLeadsField.fulfilled]: (state, action) => ({
      ...state,
      fieldEdited: true,
      loading: false,
    }),
    [UpdateLeadsField.rejected]: (state, action) => ({
      ...state,
      loading: false,
      fieldEdited: false,
      err: action.payload?.err?.error
    })
  }
});

const { reducer, actions } = user;

export const {
  SetState,
  ClearState
} = actions;

export default reducer;
