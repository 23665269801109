import React, { useState, useEffect } from "react";

import { Spin } from 'antd';

import { useSelector, useDispatch } from 'react-redux';

import { Button } from "../../components/buttons/buttons";
import Input from "../../components/input/input";
import { EditProfileWrapper } from "./style";

import { UpdateUserRecord, GetUser, SetState } from '../../redux/slices/auth-slice';

const EditProfile = (props) => {

  const { setShowResetPassword } = props;

  const {
    auth: { name: userName, email: userEmail, success, userId, loading }
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    if (userName) setName(userName);
    if (userEmail)  setEmail(userEmail);
  }, [userName, userEmail])

  useEffect(() => {
    if (success) {
      dispatch(GetUser({ userId }));
      dispatch(SetState({ field: "success", value: "" }));
    }
  }, [success])

  const handleUpdateUser = () => {
    dispatch(UpdateUserRecord({ email, name }));
  }

  const handleClearState = () => {
    setName('');
    setEmail('');
  }

  return (
    <EditProfileWrapper>
      <div className="header-wrapper">
        <h1 className="mb-0">Edit Profile</h1>
        <div className="divider" />
      </div>
      <div className="edit-profile-content-wrapper">
        <div className="content d-flex justify-content-between">
          <p className="mb-0">Update your username and manage your account.</p>
          <Button onClick={() => { setShowResetPassword(true) }} className="" outlined>
          Update Password
          </Button>
        </div>
        <div className="form">
          <div className="form-item">
            <label>Username</label>
            <Input onChange={(event) => { setName(event.target.value) }} value={name} type="text" placeholder="Select Username Here" />
          </div>
          <div className="form-item form-item-wrapper">
            <label>Email</label>
            <Input disabled={true} onChange={(event) => { setEmail(event.target.value) }} value={email} type="text" placeholder="contact@example.com" />
          </div>
          <div className="fotter-btn-wrapper user-info-footer-wrapper">
            <Button disabled={loading} onClick={handleClearState} className="cancel-btn">Cancel</Button>
            <Button disabled={loading} onClick={handleUpdateUser} className="update-btn" outlined>
              {loading ? <Spin size="small"/> :'Update Profile'}
            </Button> 
          </div>
        </div>
      </div>
    </EditProfileWrapper>
  );
};
export default EditProfile;
