import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const GetSubscriptionPlans = createAsyncThunk(
  'payment/subscriptionPlans',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/stripe/get-subscription-plans');

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const GetCustomerCards = createAsyncThunk(
  'payment/CustomerCards',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/stripe/customer-cards');
      console.log(response);
      return response.data.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const UpdateCustomerCards = createAsyncThunk(
  'payment/updateCustomer',
  async (data, thunkAPI) => {
    try {
      const {card_id, details}=data;
      const response = await axios.post('/stripe/update-customer-card',{card_id, details});
      console.log({response})
      return response.data.status;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const AddCustomerCards = createAsyncThunk(
  'payment/AddCustomerCards',
  async (data, thunkAPI) => {
    try {
      const {token,details}= data;
      console.log("token here ",{token})
      const response = await axios.post('/stripe/add-customer-card',{token,details});
      console.log({response})
      return response.data.status;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);
export const deleteCustomerCards = createAsyncThunk(
  'payment/deleteCustomerCard',
  async (data, thunkAPI) => {
    try {
      const {card_id}= data;
      console.log({card_id})
      const response = await axios.post('/stripe/delete-customer-card',{card_id});
      console.log({response})
      return response.data.status;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);


const auth = createSlice({
  name: 'payment',
  initialState: {
    plans: [],
    cards:[],
    selectedPlan: {},
    loading: false,
    success: false,
    err: false,
    message: ''
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState() {
      return {
        plans: [],
        loading: false,
        success: false,
        err: false,
        message: ''
      };
    }
  },
  extraReducers: {
    [GetSubscriptionPlans.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetSubscriptionPlans.fulfilled]: (state, action) => ({
      loading: false,
      plans: action.payload.data
    }),
    [GetSubscriptionPlans.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err
    }),
    [GetCustomerCards.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetCustomerCards.fulfilled]: (state, action) => ({
      loading: false,
      cards: action.payload
    }),
    [GetCustomerCards.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err
    }),
    [UpdateCustomerCards.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [UpdateCustomerCards.fulfilled]: (state, action) => ({
      loading: false,
      success: action.payload
    }),
    [UpdateCustomerCards.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err
    }),
    [AddCustomerCards.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [AddCustomerCards.fulfilled]: (state, action) => ({
      loading: false,
      success: action.payload
    }),
    [AddCustomerCards.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err
    }),
    [deleteCustomerCards.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteCustomerCards.fulfilled]: (state, action) => ({
      loading: false,
      success: action.payload
    }),
    [deleteCustomerCards.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err
    })
    // [CreateSubscription.pending]: (state, action) => ({
    //   ...state,
    //   loading: true
    // }),
    // [CreateSubscription.fulfilled]: (state, action) => ({
    //   ...state,
    //   loading: false,
    //   success: true,
    //   message: 'Subscription Has Been Created Succesfully'
    // }),
    // [CreateSubscription.rejected]: (state, action) => ({
    //   ...state,
    //   success: false,
    //   loading: false,
    //   err: action.payload.err
    // }),
    // [CancelSubscription.pending]: (state, action) => ({
    //   ...state,
    //   loading: true
    // }),
    // [CancelSubscription.fulfilled]: (state, action) => ({
    //   ...state,
    //   loading: false,
    //   success: true,
    //   message: 'Subscription Has Been Deleted'
    // }),
    // [CancelSubscription.rejected]: (state, action) => ({
    //   ...state,
    //   success: false,
    //   loading: false,
    //   err: action.payload.err
    // })
  }
});

const { reducer, actions } = auth;

export const {
  SetState,
  ClearState,
  logout
} = actions;

export default reducer;
