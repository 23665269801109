import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, useLocation } from "react-router-dom"

import { Row, Col } from "antd"

import { BsCheckLg } from "react-icons/bs"
import { FaStripe } from "react-icons/fa"

import LeftSide from "./authSideGrid"
import { Button } from "../../../../components/buttons/buttons"

import { AuthWrapper } from "./style";

import { GetSubscriptionPlans } from "../../../../redux/slices/payment-slice";
import { SetState, UpdateUserRecord, logout } from "../../../../redux/slices/auth-slice";

const PublicSubscriptionPlans = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { plans } = useSelector((state) => state.payment);
  const { token: authToken } = useSelector((state) => state.auth);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  useEffect(() => {
    dispatch(GetSubscriptionPlans());
  }, []);

  useEffect(() => {
    if (plans.length) {
      const params = new URLSearchParams(history.location.search);
      const betaUser = params.get('ChatPro_Beta_User');
      if (!betaUser) {
        const standardPlan = plans.filter(plan => (plan.planPrice === 147 || plan.planPrice === 97));
        setSubscriptionPlans(standardPlan);
      } else {
        const betaPlans = plans.filter(plan => plan.planPrice !== 0);
        setSubscriptionPlans(betaPlans);
      }
    }
  }, [plans]);

  const handleSelectPaymentPlan = (plan) => {
    dispatch(SetState({ field: 'selectedPlan', value: plan }));

    if (authToken) {
      history.push(`/auth/add-subscription`);
    } else {
      history.push(`/auth/sign-in` + history.location.search);
    }
  }

  return (
    <AuthWrapper>
      <Row className="subscription-paln-row-wrapper">
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className="subscription-plan-wrapper">
            <div className="subscription-plan-header">
              <div className="subscription-plan-item-wrapper">
              </div>
            </div>
            <div className="subscription-plan-header">
              <h1 className="m-0">Subscription Plans</h1>
              <p className="m-0">Choose any plan to continue using ChatPro.</p>
            </div>
            <div className="subscription-plan-tile-wrapper">
              <Row gutter={24}>
                {console.log("subscriptionPlans", subscriptionPlans)}
                {subscriptionPlans.map((plan, index) => {
                  return (
                    <Col key={index} md={8} >
                      <div className="subscription-plan-item-wrapper">
                        <div className="subscription-item-header">
                          <h3 className="m-0 header-bage">{plan.planName}</h3>
                          <p className="m-0 d-flex"><span className="doller-sign">$</span><span className="m-0 price-text">{plan.planPrice}</span><span className="month-text">{plan.planBillingPeriod === "monthly" ? '/month' : '/year'}</span></p>
                        </div>
                        <ul className="plans-list-wrapper">
                          <li className="d-flex align-items-center"><BsCheckLg />  Unlimited Leads</li>
                          <li className="d-flex align-items-center"><BsCheckLg />  Unlimited Pipelines</li>
                          <li className="d-flex align-items-center"><BsCheckLg />  Unlimited Updates</li>
                          <li className="d-flex align-items-center"><BsCheckLg className="text-overlow-wrapper" />Pull leads from groups posts, friends, and messages. </li>
                          <li className="d-flex align-items-center"><BsCheckLg />  Organize by tags & lists</li>
                          <li className="d-flex align-items-center"><BsCheckLg />  All updates with no increases</li>
                        </ul>
                        <Button className="subscription-plan-btn" onClick={() => { handleSelectPaymentPlan(plan) }} outlined>Payment from <FaStripe className="strip-logo" /></Button>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  );
};

export default PublicSubscriptionPlans;
