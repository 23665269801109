/*global chrome*/

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';

import { Row, Col, Button } from "antd"

import LeftSide from "./authSideGrid";

import Facebook from "../../../../static/img/facebook.svg";
import Notification from "../../../ant-notification/notification";
import { ExtensionId, parseData } from "../../../../utils/constants";

import { OnBoardingWrapper } from './style';

import {
  SetState
} from "../../../../redux/slices/auth-slice";

const OnboardingFlowStepTwo = () => {

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    auth: { err }
  } = useSelector((state) => state);

  useEffect(() => {
    if (err) {
      Notification({
        type: "error",
        message: err,
      });
      dispatch(SetState({ field: "err", value: false }));
    }
  }, [err]);

  const handleNextOnboardingStep = () => {
    if (!chrome.runtime) {
      dispatch(SetState({ field: "err", value: "Extension Is Not Installed Or Opened" }));
      return;
    }

    chrome.runtime.sendMessage(
      ExtensionId,
      {
        messageType: "FETCH-FB-USER-DATA",
      }, async (payload) => {
        if (!payload) return dispatch(SetState({ field: "err", value: "Couldn't Fetch Users Data, Extension Is Not Installed Or Opened" }));

        const { fbUserData, profileId } = payload;

        const userData = parseData(fbUserData);
        const leadData = userData?.data?.node?.comet_hovercard_renderer?.user;
        const name = leadData?.name;
        const image = leadData?.profile_picture?.uri;

        if (name && image && profileId) {
          history.push(`/auth/onboard-platforms`);
          dispatch(SetState({ field: "success", value: false }))
          dispatch(SetState({ field: "attachProfile", value: { facebookId: parseData(profileId), name, image } }))
        } else dispatch(SetState({ field: "err", value: "Couldn't Fetch Users Data, Please Make Sure You Are Logged Into Facebook And Extension Is Installed" }));
      })
  }

  return (
    <OnBoardingWrapper>
      <Row>
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className='second-step-wrapper'>
            <div className='second-step'>
              <h2 className='m-0'>Select a social media platform to connect</h2>
              <p className='m-0'>Select social media platform that you want to connect to ChatPro. (You can add another one later).</p>
            </div>
            <div className='socail-contection-wrapper justify-content-between align-items-center d-flex'>
              <div className='d-flex align-items-center'>
                <img src={Facebook} /><span>Facebook</span>
              </div>
              <Button onClick={handleNextOnboardingStep} outlined={'true'} className='conect-btn'>Connect</Button>
            </div>
          </div>
        </Col>
      </Row>

    </OnBoardingWrapper>
  );
}
export default OnboardingFlowStepTwo;
