import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Form, Input, Button, Row, Col } from 'antd';

import Heading from '../../../../components/heading/heading';

import Notification from '../../../ant-notification/notification'
import LeftSide from "./authSideGrid"

import { AuthWrapper } from './style';

import { ResetPassword, SetState } from '../../../../redux/slices/auth-slice';

const AuthResetPassword = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { loading, success, err } = useSelector(state => state.auth);
    const newParam = new URLSearchParams(history.location.search);
    const token = newParam.get('token');
    const [form] = Form.useForm();

    useEffect(() => {
        if (err) {
            Notification({
                type: 'error',
                message: typeof err === 'object' ? err.error || 'Error' : err
            });
        }
        dispatch(SetState({ field: 'err', value: '' }));
    }, [err])

    useEffect(() => {
        if (success) {
            dispatch(SetState({ field: 'success', value: false }));
            history.push('/auth/sign-in');
        }
    }, [success])

    useEffect(() => {
        if (!token) history.push('/auth/reset-password-email')
    },[token])

    const handleSignInRoute = () => {
        history.push('/auth/sign-in');
      }

    const handleSubmit = (data) => {
        const { password, confirmPassword } = data;
        if (!password || password !== confirmPassword) {
            return Notification({
                type: 'error',
                message: 'Passwords Are Not Same'
            });
        }
        dispatch(ResetPassword({ token, password }));
    };

    return (
        <AuthWrapper>
            <Row className='d-flex'>
                <Col md={10} className='left-side-img'>
                    <LeftSide />
                </Col>
                <Col md={14}>
                <div className='right-side-content d-flex flex-column justify-content-center align-item-center'>
                    <div className="auth-contents reset-password">
                        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
                            <Heading as="h3">
                                Set New Password
                            </Heading>
                            <p>Please enter a new, strong password to be used for your ChatPro account.</p>
                            <Form.Item
                                name="password"
                                rules={[{ message: 'Please Input Your Password!', required: true }]}
                                label="Password"
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                rules={[{ message: 'Please Confirm Your Password!', required: true }]}
                                label="Confirm Password"
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                            <Form.Item>
                                <Button className="btn-signin btn-resetpassword" htmlType="submit" type="primary" size="large">
                                    {loading ? 'Loading...' : 'Reset Password'}
                                </Button>
                            </Form.Item>
                            <div className='d-flex justify-content-center'>
                                <span className='return-link'>Return to<Link to="#" onClick={handleSignInRoute} className="link-sign-in"> Sign in</Link></span>
                            </div>
                        </Form>

                    </div>
                </div>
                </Col>
            </Row>
        </AuthWrapper>
    );
};

export default AuthResetPassword;
