import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FolderButtonWrapper } from "./style";

const FolderButton = ({ onClick, handleDeleteFolder, className, text, active }) => {
  const handleIconClick = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating to the parent div
    handleDeleteFolder(); // Call the delete handler
  };
  return (
    <div
      onClick={onClick}
    >
      <FolderButtonWrapper
        className={`${active ? 'active-status-button' : ''}`}
      >

        <p className={className} >{text}</p>
        <AiOutlineDelete
          onClick={handleIconClick}
        />

      </FolderButtonWrapper>
    </div>
  );
};

export default FolderButton;
