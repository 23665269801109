import styled from "styled-components";

const TemplateStyleWrapper = styled.div`
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    .search_wrapper {
      position: relative;
      div {
        width: 252px;
        svg {
          top: 11px;
        }
      }
    }
    h1 {
      margin: 0;
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .folder-loading{
    .ant-spin.ant-spin-lg.ant-spin-spinning {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
}
  }
  .active-status-button{
    border:1px solid #5F63F2;
  }
  .content-templates-overaly {
    margin-top: 24px;
    .tabs-wrapper {
      height: auto;
      width: 100%;
      background: #fff;
      border-radius: 4px;
      padding-bottom: 24px;
      .tabs-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eceef5;
        h2 {
          margin-bottom: 0;
          color: #272b41;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          padding-left: 24px;
          padding-bottom: 16px;
          padding-top: 16px;
        }
        svg {
          font-size: 20px;
          color: #5f63f2;
          cursor: pointer;
          margin-right: 24px;
        }
      }
    }
    .buttons-wrapper {
      padding: 24px;
      display: flex;
      flex-direction: column;
      grid-row-gap: 16px;
      height:542px;
      overflow:auto ;
    }
  }
  .template-content-wrapper {
    background: #fff;
    border-radius: 4px;
    padding-bottom: 24px;
    .template-content-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #eceef5;
      padding-bottom: 16px;

      h3 {
        color: #272b41;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        padding-left: 24px;
        padding-top: 16px;
        margin-bottom: 0px;
      }
      button {
        display: flex;
        align-items: center;
        color: #5f63f2;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        grid-gap: 4px;
        padding: 11px 16px;
        margin-top: 16px;
        margin-right: 24px;
      }
    }
    .data-Template-wrapper {
      padding: 24px;
      height: 523px;
      overflow:auto ;
    }
  }
`;

const CardStyleWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #eceef5;
  background: #fff;
  padding: 16px;
  .card-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .card-header p {
    color: #17181a;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 114.286% */
    margin-bottom: 16px;
  }

  .action-icons {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .card-header p {
      color: #17181a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      margin-bottom: 16px;
    }
  }
  .action-icons {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    font-size: 16px;
    svg {
      font-size: 16px;
      color: #5f63f2;
      cursor: pointer;
    }
  }
  .card-discruption {
    color: #797c80;
    margin-bottom: 0px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    a {
      color: #5f63f2;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 23px;
      text-decoration-line: underline;
    }
  }
`;
const FolderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #eceef5;
  background: #fff;
  p {
    color: #17181a;
    margin: 0px;
    user-select: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    padding: 16px;
    width: 90%;
    cursor: pointer;
  }
  svg {
    color: #5f63f2;
    cursor: pointer;
    font-size: 16px;
    width: 10%;
  }
`;

const MessageModalWrapper = styled.div`
  .recipients {
    display: flex;
  }
  .add-reception-overlay-wrapper{
    position:relative ;
    .add-reception-item {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    position: absolute;
    top: -38px;
    right: 0px;
    width: 76%;
    justify-content:end ;
    .ant-select-show-arrow {
    width: 30%;
    span.ant-select-selection-item {
    color: #5F63F2;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
}
}
}
  }
  .message-alert-wrapper .ant-form-item-control-input-content label.ant-checkbox-wrapper.checkbox {
    position: relative;
    left: 0px;
    margin: 0px;
}
  .message-alert-wrapper .ant-form-item-control-input-content {
    display: flex;
    align-items: end;
    grid-gap: 8px;
}
.first-name-btn{
    display: flex;
    align-items: center;
    cursor: pointer;
    p{
      color: #5F63F2;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 12px;
      margin-left: 6px;
      bottom: 111px;
      user-select: none;
    }
}
  .range-wrapper {
    display: flex;
    justify-content: space-between;
    p {
      position: relative;
      margin-bottom: 0px;
      margin-top: 6px;
      font-weight: 500;
      font-size: 13px;
      line-height: 13px;
      display: flex;
      align-items: center;

      color: #868eae;
      &:before {
        content: "";
        position: absolute;
        left: 11px;
        top: -10px;
        width: 1px;
        height: 7px;
        background-color: rgba(134, 142, 174, 0.5);
      }
      &:nth-child(1) {
        &:before {
          left: 0px;
        }
      }
      &:nth-child(10) {
        &:before {
          left: 26px;
        }
      }
    }
  }
  .ant-slider {
    margin: 0px;
  }
  .ant-slider-mark .ant-slider-mark-text {
    width: 100%;
  }
  .slider-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #272b41;
    margin-bottom: 8px;
    span {
      color: #5f63f2;
    }
  }
  .upload-btn {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #272b41;
    margin-bottom: 4px;
    margin-top: 24px;
  }
  .ant-slider-rail {
    background-color: rgba(134, 142, 174, 0.3);
  }

  .upload-overlay-wrapper {
    cursor: pointer;
    width: 581px;
    border: 1px dashed #e3e6ef;
    height: 108px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    position: relative;
    z-index: 99;
    margin-bottom: 16px;
    &:hover button {
      background-color: red;
      color: #fff !important;
    }
    .new-condition-icon {
      font-size: 50px !important;
      color: #5f63f2 !important;
      position: absolute;
      top: 10px;
    }

    .new-condition {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-top: 6px;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      color: #adb4d2;
      position: absolute;
      z-index: -3;
      top: 62px;
    }
    p {
      margin: 5px 0;
      color: #adb4d2;
      font-weight: 400;
      font-size: 14px;
    }
    button {
      background: #ddd;
      color: #000;
      border: none;
      position: absolute;
      top: 76px;
      padding: 0 7px;
      font-size: 10px;
      line-height: 0px;
      height: 22px !important;
    }
  }
  input.ant-input.upload-button {
    height: 50px !important;
    margin-bottom: 16px;
    opacity: 0;
  }
  .ant-upload-list {
    margin-top: 10px !important;
    width: 100%;
    max-height: 100px;
    height: 100%;
    overflow: auto;
    margin-bottom: 24px;
  }
  textarea.ant-input {
    border: 1px solid #d9d9d9;
    min-height: 108px;
    margin-bottom: 16px;
    font-size: 14px;
    padding: 12px 16px;
    margin-bottom: 0px;
  }
  .ant-select-selector {
    height: 36px !important;
  }
  .messages-overlay-progress {
    position: relative;
    .progress-label {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      margin: 0px;
      & svg {
        color: #2fc898;
        font-size: 16px;
        &.info-icon {
          color: #5f63f2;
          font-size: 16px;
          cursor: pointer;
        }
      }
      p {
        margin-bottom: 0px;
        color: #272b41;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        display: flex;
        grid-gap: 3px;
        align-items: center;
        &.total-count {
          color: #2fc898;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
    .progress-footer {
      p {
        font-size: 12px;
        &.total-count {
          color: #2fc898;
          font-size: 12px;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
    .first-progress-b {
      .ant-progress-bg {
        background: #2fc898;
      }
    }
    .next-progress {
      margin-top: 24px;
      svg {
        color: #ff9900;
      }
      p {
        &.total-count {
          color: #ff9900;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;
        }
      }
    }
    p.progress-label.progress-footer.footer-next-process {
      p {
        &.total-count {
          color: #ff9900;
        }
      }
    }
    .first-progress-c {
      .ant-progress-bg {
        background: #ff9900;
      }
    }
    .current-status {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      margin-top: 24px;
      svg {
        color: #0f7acc;
        font-size: 20px;
      }
      p {
        margin-bottom: 0px;
        color: #272b41;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
      .user-name-info {
        color: #0f7acc;
        font-size: 14px;
        font-weight: 700;
        line-height: 16px;
      }
    }
    .sending-box-wrapper {
      padding: 18px;
      border: 1px dashed grey;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 16px;
      p {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
      }
    }
    p.success-wrapper {
      display: flex;
      align-items: center;
      grid-gap: 6px;
      margin-top: 16px;
      color: #272b41;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      flex-direction: row;
      align-items: center;
      svg {
        color: #2fc898;
        font-size: 16px;
      }
      .time {
        color: #5f63f2;
      }
    }
    .success-img{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .cancel-btn-wrapper {
    background-color: #5f63f2;
    color: #fff;
    margin-left: auto;
    display: flex;
    margin-top: 16px;
    &:hover {
      border: 1px solid #5f63f2;
      background-color: transparent;
      color: #5f63f2;
    }
  }
  .success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: 0px;
    flex-direction: column;

    h6 {
      color: #272b41;
      text-align: center;
      font-size: 24px;
      font-family: Inter;
      font-weight: 700;
      line-height: 30px;
      margin-top: 24px;
      margin-bottom: 0px;
    }
    p {
      color: #5a5f7d;
      text-align: center;
      font-size: 14px;
      font-family: Inter;
      line-height: 23px;
      margin-top: 12px;
    }
    .cancel-btn-wrapper {
      background-color: #5f63f2;
      color: #fff;
      display: flex;

      margin: auto;
      &:hover {
        border: 1px solid #5f63f2;
        background-color: transparent;
        color: #5f63f2;
      }
    }
    .erorr-overlay-wrapper p {
      color: #5a5f7d;
      text-align: center;
      font-size: 14px;
      line-height: 23px;
      margin: 0px;
    }
    .erorr-overlay-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 24px;
      h6 {
        color: #272b41;
        text-align: center;
        font-size: 24px;
        font-family: Inter;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 0px;
      }
      p {
        color: #5a5f7d;
        text-align: center;
        font-size: 14px;
        line-height: 23px;
        margin: 0px;
      }
      .text-erorr {
        color: #ff4d4f;
      }
    }
  }
  .divider-wrapper {
    height: 1px;
    width: 108.4%;
    background-color: #ddd;
    margin-bottom: 16px;
  }
  .success-img {
    img{
    width: 50px;
  }
  }
`;

export { TemplateStyleWrapper, CardStyleWrapper, FolderButtonWrapper, MessageModalWrapper };
