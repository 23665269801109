import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Form, Input, Button, Row, Col } from "antd";

import { AuthWrapper } from "./style";

import Notification from "../../../ant-notification/notification";
import { validateEmail } from "../../../../utils/constants";

import { Checkbox } from "../../../../components/checkbox/checkbox";
import Heading from "../../../../components/heading/heading";
import LeftSide from "./authSideGrid"

import { ReactComponent as Logo } from '../../../../static/img/logo-text.svg';
import { SignUp, SetState, SendAuthTokenToExtension } from "../../../../redux/slices/auth-slice";
import { ExtensionId } from "../../../../utils/constants";

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, success, err, token, message } = useSelector(state => state.auth);
  const [form] = Form.useForm();
  const [state, setState] = useState({
    checked: null,
  });

  useEffect(() => {
    if (err) {
      Notification({
        type: "error",
        message: err,
      });
      dispatch(SetState({ field: "err", value: "" }));
    }
  }, [history, err]);

  useEffect(() => {
    if (success) {
      dispatch(SetState({ field: "success", value: "" }));
      if (process.env.NODE_ENV === 'production') dispatch(SendAuthTokenToExtension({ token }));
      history.push("/auth/verification" + history.location.search);
    }
  }, [history, success]);

  const checkAllFieldsAdded = () => {
    const { name, userName, email, password } = form.getFieldsValue();
    if (!name || !userName || !email || !password || !state.checked) return false;
    else return true;
  }

  const handleSubmit = (value) => {
    const verfiyEmail = validateEmail(value.email);
    if (!verfiyEmail) {
      return Notification({
        type: "warning",
        message: "Email Is Invalid",
      });
    }

    if (!checkAllFieldsAdded()) return Notification({
      type: "warning",
      message: "Please Accept Our Terms And Service",
    });
    
    const params = new URLSearchParams(history.location.search);
    const refId = params.get('via');
    value['refId'] = refId || null;
    const betaUser = params.get('ChatPro_Beta_User');
    value.betaUser = betaUser === "true" ? true : false
    dispatch(SignUp(value));
  };

  const handleSignInUser = () => {
    history.push("/auth/sign-in" + history.location.search);
  };

  const onCheckboxChange = (checked) => {
    setState({ ...state, checked });
  };

  return (
    <AuthWrapper>
      <Row className='d-flex'>
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className='right-side-content d-flex flex-column justify-content-center align-item-center'>
            <div className="auth-contents">
              <Form
                name="login"
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                className="sign-up-form"
              >
                <Heading as="h3">
                  Register for <span className="color-secondary logo-text"><Logo /></span>
                </Heading>
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[{ message: "Please type your name", required: true }]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
                <Form.Item
                  name="userName"
                  rules={[{ message: "Please type your username", required: true }]}
                  label="Username"
                >
                  <Input placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ message: "Please type your email", required: true }]}
                  label="Email Address"
                >
                  <Input placeholder="name@example.com" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[{ message: "Please type your password!", required: true }]}
                  label="Password"
                >
                  <Input.Password placeholder="Password" />
                </Form.Item>
                <div className="auth-form-action sign-up-checkbox-wrapper">
                  <Checkbox checked={state.checked} onChange={onCheckboxChange} />
                  <span className="ant-rate-text fs-12">
                    Creating an account means you’re okay with our {" "}
                    <span className="color-secondary">Terms of Service</span>
                  </span>
                </div>
                <Form.Item>
                  <Button
                    disabled={loading}
                    className="btn-signin"
                    htmlType="submit"
                    type="primary"
                    size="large"
                  >
                    {loading ? "Loading..." : "Sign Up"}<a href="./emailVerification" />
                  </Button>
                </Form.Item>
                <div className="auth-notice d-flex">
                  <p className="auth-notice ">Already have an account?</p>{" "}
                  <span onClick={handleSignInUser} className="color-secondary ml-2">
                    Sign In
                  </span>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  );
};

export default SignIn;
