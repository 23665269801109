import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link, useHistory, useLocation } from "react-router-dom"

import { Row, Col } from "antd"

import { BsCheckLg } from "react-icons/bs"
import { FaStripe } from "react-icons/fa"

import LeftSide from "./authSideGrid"
import { Button } from "../../../../components/buttons/buttons"

import { AuthWrapper } from "./style";

import { GetSubscriptionPlans } from "../../../../redux/slices/payment-slice";
import { SetState, UpdateUserRecord, logout } from "../../../../redux/slices/auth-slice";

const SubscriptionPlans = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { plans } = useSelector((state) => state.payment);
  const { token: authToken, success, message, status } = useSelector((state) => state.auth);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [userVerified, setUserVerified] = useState(false);

  const newParam = new URLSearchParams(history.location.search);
  let urlToken = newParam.get('token') || location.search.split('=')?.length && location.search.split('=')[1];
  if (urlToken?.includes('ChatPro_Beta_User')) urlToken = urlToken.split('/')?.length && urlToken.split('/')[0];

  useEffect(() => {
    dispatch(GetSubscriptionPlans());
  }, []);


  useEffect(() => {
    dispatch(SetState({ field: 'success', value: false }));
    dispatch(SetState({ field: 'message', value: '' }));
  }, [success, message])

  useEffect(() => {
    const { pathname } = location;
    console.log('status from sub plans', status, newParam.get('from_admin'), urlToken);
    if (pathname.includes('free-plan')) {
      dispatch(UpdateUserRecord({ status: 'Verified' })).then(() => {
        dispatch(SetState({ field: 'status', value: 'Verified' }));
        setUserVerified(true);
      });
    } else {
      if (!newParam.get('token') && status === 'Pending') history.push('/auth/verification');
      if (status === "Verified") setUserVerified(true);
      if (status === 'Pending' && authToken === urlToken) {
        dispatch(UpdateUserRecord({ status: 'Verified' })).then(() => {
          dispatch(SetState({ field: 'status', value: 'Verified' }));
          setUserVerified(true);
        });
      } else if (status === 'Pending' && newParam.get('from_admin')) {

        dispatch(UpdateUserRecord({ status: 'Verified' })).then(() => {
          dispatch(SetState({ field: 'status', value: 'Verified' }));
          dispatch(SetState({ field: 'token', value: urlToken }));
          setUserVerified(true);
        });
      }
    }
  }, [authToken, urlToken, status])

  useEffect(() => {
    if (plans.length) {
      const params = new URLSearchParams(history.location.search);
      const betaUser = params.get('ChatPro_Beta_User');
      const { pathname } = location;
      if (pathname.includes('free-plan')) {
        const freePlan = plans.filter(plan => plan.planPrice === 0);
        setSubscriptionPlans(freePlan);
      }
      else if (!betaUser) {
        const standardPlan = plans.filter(plan => (plan.planPrice === 147 || plan.planPrice === 97));
        setSubscriptionPlans(standardPlan);
      } else {
        const betaPlans = plans.filter(plan => plan.planPrice !== 0);
        setSubscriptionPlans(betaPlans);
      }
    }
  }, [plans]);

  const handleSelectPaymentPlan = (plan) => {
    dispatch(SetState({ field: 'selectedPlan', value: plan }));
    history.push(`/auth/add-subscription`);
  }

  const handleLogoutUser = () => {
    dispatch(logout());
    history.push(`/auth/sign-in`);
  }

  return (
    <AuthWrapper>
      <Row className="subscription-paln-row-wrapper">
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className="subscription-plan-wrapper">
            <div className="subscription-plan-header">
              <div className="subscription-plan-item-wrapper">
                <Button className="subscription-plan-logout-btn" outlined onClick={handleLogoutUser}>Log Out</Button>
              </div>
            </div>
            <div className="subscription-plan-header">
              <h1 className="m-0">Subscription Plans</h1>
              <p className="m-0">Choose any plan to continue using ChatPro.</p>
            </div>
            <div className="subscription-plan-tile-wrapper">
              <Row gutter={24}>
                {subscriptionPlans.map((plan, index) => {
                  return (
                    <Col key={index} md={8} >
                      <div className="subscription-plan-item-wrapper">
                        <div className="subscription-item-header">
                          <h3 className="m-0 header-bage">{plan.planName}</h3>
                          <p className="m-0 d-flex"><span className="doller-sign">$</span><span className="m-0 price-text">{plan.planPrice}</span><span className="month-text">{plan.planBillingPeriod === "monthly" ? '/month' : '/year'}</span></p>
                        </div>
                        <ul className="plans-list-wrapper">
                          <li className="d-flex align-items-center"><BsCheckLg />  Unlimited Leads</li>
                          <li className="d-flex align-items-center"><BsCheckLg />  Unlimited Pipelines</li>
                          <li className="d-flex align-items-center"><BsCheckLg />  Unlimited Updates</li>
                          <li className="d-flex align-items-center"><BsCheckLg className="text-overlow-wrapper" />Pull leads from groups posts, friends, and messages. </li>
                          <li className="d-flex align-items-center"><BsCheckLg />  Organize by tags & lists</li>
                          <li className="d-flex align-items-center"><BsCheckLg />  All updates with no increases</li>
                        </ul>
                        <Button disabled={!userVerified} onClick={() => { handleSelectPaymentPlan(plan) }} className="subscription-plan-btn" outlined>Payment from <FaStripe className="strip-logo" /></Button>
                      </div>
                    </Col>

                  )
                })}
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  );
};

export default SubscriptionPlans;
