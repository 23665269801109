import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const GetJobsRecord = createAsyncThunk(
  'jobs/getBackgroundJobs',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/jobs/jobs-record', { params: data });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const RemoveJobsRecord = createAsyncThunk(
  'jobs/removeBackgroundJobs',
  async (data, thunkAPI) => {
    try {
      const { jobIds } = data;
      const response = await axios.delete('/jobs/jobs-record', { params: { jobIds } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const PauseBackgroundJobs = createAsyncThunk(
  'jobs/pauseBackgroundJobs',
  async (data, thunkAPI) => {
    try {
      const { jobIds } = data;
      const response = await axios.put('/jobs/pause-jobs', { jobIds });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const auth = createSlice({
  name: 'backgroundJobs',
  initialState: {
    jobs: [],
    loading: false,
    success: false,
    err: false,
    message: ''
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState() {
      return {
        jobs: [],
        loading: false,
        success: false,
        err: false,
        message: ''
      };
    }
  },
  extraReducers: {
    [GetJobsRecord.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetJobsRecord.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      jobs: action.payload.data
    }),
    [GetJobsRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: true,
      message: action.payload.err
    }),
    [RemoveJobsRecord.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [RemoveJobsRecord.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [RemoveJobsRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: true,
      message: action.payload.err
    }),
    [PauseBackgroundJobs.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [PauseBackgroundJobs.fulfilled]: (state, action) => ({
      ...state,
      success: true,
      loading: false,
      message: action.payload.message
    }),
    [PauseBackgroundJobs.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: true,
      message: action.payload.err
    })
  }
});

const { reducer, actions } = auth;

export const {
  SetState,
  ClearState,
  logout
} = actions;

export default reducer;
