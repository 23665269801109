import React from 'react';
import { Input } from "antd"
import InputWrapper from './style';

const  InputSimple = (props) => {
    const { value, onBlur, placeholder, defaultValue, className, type, onChange, onKeyUp, disabled } = props;

  return (
    <InputWrapper>
      <Input onBlur={onBlur} value={value} disabled={disabled} defaultValue={defaultValue} onKeyUp={onKeyUp} onChange={onChange} placeholder={placeholder} className={className} type={type} />
    </InputWrapper>
  );
}
export default InputSimple;
