import React from "react";

import { AuthWrapper } from "./style";
import AuthImage from "../../../../static/img/auth.svg";
import {ReactComponent as Logo} from '../../../../static/img/chatpro-logo.svg';

const AuthSideGrid = () => {
  return (
    <AuthWrapper>
      <div>
        <div className="main-wrapper">
          <div className="content-area">
            <span className="d-flex Logo">
              {/* Chat <h4> Pro</h4> */}
              <h4> <Logo/></h4>
            </span>
            <h2>The Easiest Way to Manage Your Leads and Deals from Messenger</h2>
          </div>
          <img src={AuthImage} />
        </div>
      </div>
    </AuthWrapper>
  );
};
export default AuthSideGrid;
