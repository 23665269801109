import React from "react";

import { Select } from "antd";
import { HeaderSelect } from "../../container/styled";

const SelectDropDownRelation = (props) => {
  const { Option } = Select;

  const { value, placeholder, children, name, onChange, options, className } = props;

  return (
    <HeaderSelect>
      <Select
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        allowClear={value ? true: false}
        className={className}
      >
        {name === 'relationship' && options?.map((option, index) => {
          return ( <Option key={index} value={option.key}> {option.value} </Option> );
        })}
        {name === 'groups' && options?.map((option, index) => {
          return ( <Option key={index} value={option.groupId}> {option.name} </Option> );
        })}
        {name === 'posts' && options?.map((option, index) => {
          return ( <Option key={index} value={option.postId}> {option.name} </Option> );
        })}
      </Select>
    </HeaderSelect>
  );
};

export default SelectDropDownRelation;