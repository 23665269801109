import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import Store from './redux/store/index';
import AppRoute from './routes/app-route';
import NonAuthRoute from './routes/non-auth-route';
import AuthRoute from './routes/auth-route';
import './static/css/style.css';
import config from './config/config';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import PublicRoute from './routes/public-route';
import PublicSubscriptionPlans from './container/profile/authentication/overview/publicSubscriptionPlans';
import SignUp from './container/profile/authentication/overview/signUp';
import SubscriptionPlans from './container/profile/authentication/overview/subscriptionPlans';
let persistor = persistStore(Store);

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
    };
  });

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    return () => (unmounted = true);
  }, [setPath]);

  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router>
          <Switch>
            <Route path='/register' component={SignUp} />
            <Route path='/free-plan' component={SubscriptionPlans} />
            <PublicRoute path='/subscription-plans' component={PublicSubscriptionPlans} />
            <AuthRoute path='/auth' />
            <NonAuthRoute path='/non-auth' />
            <AppRoute path='/' />
          </Switch>
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={Store}>
      <PersistGate loading={null} persistor={persistor}>
      <ProviderConfig />
      </PersistGate>
    </Provider>
  );
}

export default hot(App);
