import Styled from "styled-components";
import { PageHeader } from "antd";

const PageHeaderStyle = Styled(PageHeader)`

.ant-select.custom-drop-down.ant-select-single.ant-select-show-arrow {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    .ant-select-arrow {
    top: 55%;
    right: 13px;
    width: 8px;
    height: 4px;
    margin-top: -6px;
    color: rgb(100 104 132);
    font-size: 12px !important;
}
}
  .ant-page-header-heading-title{
    color: ${({ theme }) => theme["dark-color"]}
    font-size:24px !important;
    font-weight: 700;
    margin: 32px 0 0 0px;
  }
  .page-header-actions button.ant-btn-white svg {
    width: 12px;
    height: 12px;
    ${({ theme }) => (theme.rtl ? "margin-left" : "margin-right")}: 2px;
    color: #5f63f2;
  }
  i +span, svg +span, img +span {
      ${({ theme }) => (!theme.rtl ? "margin-left" : "margin-right")}: 6px;
  }
  .ant-page-header-heading-sub-title{
    margin-left: 9px;
    border-radius: 50px;
  }
  .search-box{
    position: relative;
    box-shadow: 0 5px 5px rgba(#9299B8,.3);
        svg,
        img{
            margin-top: 2px;
            min-width: 16px;
            color: #5f63f2;
            margin-left: 1px;
        }
    }
    input{
    border: 0 none;
    height: 36px;
    width: 100%;
    font-size: 12px;
    padding: 0 29px 0 12px;
    border-radius: 27px;
        &::placeholder{
            color: #ADB4D2;
        }
        &:focus{
            outline: none;
        }
    }

.ant-input{
    font-size : 12px;
    border: 0 none;
    height: 40px;
    /* padding: ${({ theme }) =>
        theme.rtl ? "0 45px 0 20px" : "0 20px 0 45px"}; */
    border-radius: 36px;
    &::placeholder{
        color: #ADB4D2;
    }
    &:focus{
        outline: none;
    }
}
.search-input{
    width: 252px;
    height: 36px;
    margin-left: 3px
}
.search-select{
    width: 120px;
    font-size: 12px;
    font-weight: 500;
    margin-top: 1px
}
.display-inline{
    display: inline;
}
/* .search-box */
&.page-header-main{
    margin-top:0;
    padding-top:24px!important;
    padding-bottom:16px!important;
    .ant-page-header-heading-title{
        margin-top:0;
    }
    .page-header-actions{
        margin-top:0;
    }
}
`;

export { PageHeaderStyle };
