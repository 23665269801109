import React, { useEffect, useState, useRef } from "react";
import Cookies from 'universal-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Row, Col, Spin, Input, Divider, Carousel } from "antd";

import Notification from "../ant-notification/notification";

import { Button } from "../../components/buttons/buttons";
import { Modal } from "../../components/modals/antd-modals";
import { EditProfileWrapper } from "./style";
import {
  BsCheckLg,
  // BsFill1CircleFill,
  // BsFill2CircleFill,
  // BsFill3CircleFill,
  // BsFill4CircleFill,
  // BsFill5CircleFill,
  // BsFill6CircleFill
} from "react-icons/bs";
import NoUser from "../../static/img/no-user.png";

import { CancelSubscription, SetState } from '../../redux/slices/auth-slice';
import { logout } from '../../redux/slices/auth-slice'
import { ClearState as ClearLeadsState } from '../../redux/slices/leads-slice';
import { ClearState as ClearPipelineState } from '../../redux/slices/pipeline-slice';
import { ClearState as ClearKanbanState } from '../../redux/slices/kanban-slice';

const billingPeriod = {
  yearly: 'year',
  monthly: 'month',
}

const { TextArea } = Input;

const EditProfile = () => {

  const dispatch = useDispatch();
  const carouselRef = useRef();
  const cookies = new Cookies();
  const history = useHistory();
  const [cancelSubscription, setCancelSubscription] = useState(false);
  const [addFeedback, setAddFeedback] = useState(false);
  const [feedbackValue, setAddFeedbackValue] = useState('');
  const { selectedPlan = {} } = useSelector((state) => state.auth);
  const { loading, success, message, err, facebookProfileData = {} } = useSelector((state) => state.auth);

  const { image: userImage, name } = facebookProfileData;
  useEffect(() => {
    if (success) {
      Notification({
        type: "success",
        message: message,
      });
      dispatch(SetState({ field: "success", value: false }));
      dispatch(SetState({ field: "message", value: false }));
      carouselRef.current?.prev();
      dispatch(logout());
      dispatch(ClearLeadsState());
      dispatch(ClearPipelineState());
      dispatch(ClearKanbanState());
      cookies.remove('loggedIn', { path: '/' });
      cookies.remove('token', { path: '/' });
      history.push('/auth/sign-in');
    }
    if (err) {
      Notification({
        type: "success",
        message: err,
      });
      dispatch(SetState({ field: "err", value: false }));
      carouselRef.current?.prev();
    }
    
    setCancelSubscription(false);
  }, [success])

  const imageErrors = () => {
    if (document.querySelector(`img[id="cancel-plan-image"]`)) document.querySelector(`img[id="cancel-plan-image"]`).src = NoUser;
  };

  const handleCancelSubscription = () => {
    if (!addFeedback) {
      carouselRef.current.next();
      setAddFeedback(true)
    } else {
      dispatch(CancelSubscription({ feedback: feedbackValue }));
    }
  };

  return (
    <EditProfileWrapper>
      <div className="social-media-header-wrapper">
        <h1 className="mb-0">Select Subscription Plan</h1>
        <div className="divider" />
      </div>
      <div className="plans-content-wrapper">
        <div className="content d-flex justify-content-between">
          <p className="mb-0">Choose any plan to continue using ChatPro.</p>
        </div>
        <div className="offer-banner-wrapper">
          <div className="offer-banner">
            <h1 className="mb-0">
              Lock in Beta Pricing at <span>40%</span> - <span>60%</span> OFF
            </h1>
          </div>
        </div>
        <Row gutter={30}>
          <Col md={12}>
            <div className="subscription-plan-item-wrapper">
              <div className="subscription-item-header position-relative">
                <h3 className="m-0 header-bage">{selectedPlan.planName || 'N/A'}</h3>
                <p className="m-0 d-flex">
                  <span className="doller-sign">$</span>
                  <span className="m-0 price-text">{selectedPlan.planPrice || 0}</span>
                  <span className="month-text">/{selectedPlan.planBillingPeriod ? billingPeriod[selectedPlan.planBillingPeriod] : 'year'}</span>
                </p>
                <p className="m-0 new-deal-label">Selected Plan</p>
              </div>
              <ul className="plans-list-wrapper">
                <li className="d-flex align-items-center">
                  <BsCheckLg /> Unlimited Leads
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> Unlimited Pipelines
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> Unlimited Updates
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg className="text-overlow-wrapper" />
                  Pull leads from groups posts, friends, and messages.
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> Organize by tags & lists
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> All updates with no increases
                </li>
              </ul>
              <Button disabled={loading} onClick={() => { setCancelSubscription(true) }} className="subscription-plan-btn" outlined>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        className="addEvent-modal crm-basic-modal"
        footer={null}
        type="primary"
        centered
        visible={cancelSubscription}
        width={480}
        closable={false}
      >
        <div>
          <div className="d-flex unfriend-heading-modal">
            <img
              src={userImage || "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"}
              id={'cancel-plan-image'}
              height={80}
              width={80}
              onError={() => { imageErrors(); }}
              alt={NoUser}
              className="add-space"
            />
            <div>
              <h2 className="header-content-wrap text-center color-dark">
                {name}
              </h2>
              <p className="content-subscription-label">
                ChatPro will loose your valuable support and you will loose access to all current and future membership awards. If you still want to cancel it would be helpful for us to know why
              </p>
            </div>
          </div>
          <Divider className="sub-modal-divider" />
          <Carousel ref={carouselRef} afterChange={(event) => { setAddFeedback(Number(event) === 1 ? true: false) }}>
            <div className="feedback-list-wrap">
              <p className="fs-18 color-dark">
                You wont be able to use ChatPro anymore.
              </p>
              <ul className="plans-list-wrapper">
                <li className="d-flex align-items-center">
                  <BsCheckLg />  <span className="span-check-wrap">Leads Will Be Removed & You Can't Sync Anymore</span>
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> <span className="span-check-wrap">Pipelines Will Be Deleted & You Can't Create Anymore</span>
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> <span className="span-check-wrap">Stages Will Be Deleted & You Can't Create Anymore</span>
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg />
                  <span className="span-check-wrap">You Wont Be Able To Use Bulk Messages Feature</span>
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> <span className="span-check-wrap">You Wont Be Able To Organize tags & lists</span>
                </li>
                <li className="d-flex align-items-center">
                  <BsCheckLg /> <span className="span-check-wrap">All Updates With No Increases</span>
                </li>
              </ul>
            </div>
            <div className="feedback-list-wrap">
              <p className="fs-18 color-dark">
                We Need Your Feedback.
              </p>
              <TextArea autoSize={{ minRows: 6, maxRows: 10 }} onChange={(event) => { setAddFeedbackValue(event.target.value) }} placeholder="Please Provide Your Feedback" />
            </div>
          </Carousel>
          <Divider />
        </div>
        <div className="modal-footer d-flex justify-content-center">
          <Button
            size="default"
            outlined
            type="white"
            onClick={() => {
              addFeedback && carouselRef.current.prev();
              setAddFeedback(false);
              setCancelSubscription(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCancelSubscription}
            size="default"
            type="primary"
            disabled={loading}
            className="block-user"
          >
            {loading ? <Spin size="small" /> : !addFeedback ? 'Continue' : 'Cancel Subscription'}
          </Button>
        </div>
      </Modal>
    </EditProfileWrapper>
  );
};
export default EditProfile;
