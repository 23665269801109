import React from 'react';
import { Input } from "antd"
import { FiSearch } from "react-icons/fi";
import SearchBar from './style';

const  InputComponent = (props) => {
    const { value, placeholder, search, onKeyPress, className, onChange, type, onClick } = props;

  return (
    <SearchBar>
      <Input value={value} onKeyPress={onKeyPress} placeholder={placeholder} onChange={onChange} className={className} type={type} onClick={onClick} />
      {search && <FiSearch className="search-icon" />}
    </SearchBar>
  );
}
export default InputComponent;
