import React, { useState, useEffect, useRef, useMemo , useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { debounce } from "lodash";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { useSelector, useDispatch } from "react-redux";
import SearchInput from "../../components/searchBar/input";

import { Row, Col, Spin } from "antd";

import { KanvanBoardWrap } from "./style";

import { Main } from "../styled";
import PipelineColumn from "./pipelineColumn";
import AddNewStage from "./addNewStage";

import Notification from "../ant-notification/notification";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";

import {
  SetState as SetLeadsState,
} from "../../redux/slices/leads-slice";

import { GetStages, SetState,GetAllStagesLeads } from "../../redux/slices/stages-slice";

import { GetLists , GetTags } from "../../redux/slices/list-slice";

import {
  SetState as SetDashboardState,
} from "../../redux/slices/dashboard-slice";


const Kanban = (props) => {
  const {
    dashboardSuccess,
    stageLeads,
    leadsErr,
    dashboardErr,
  } = useSelector((state) => {
    return {
      stageLeads: state.stages.stageLeads,
      leadsErr: state.leads.messageError,
      dashboardSuccess: state.dashboard.success,
      dashboardErr: state.dashboard.err,
    };
  });

  let scrollValue = 0;

  useEffect(() => {
    if (dashboardSuccess) {
      Notification({
        type: "success",
        message: dashboardSuccess,
      });
      dispatch(SetDashboardState({ field: 'success', value: false }))
    }
    if (dashboardErr) {
      Notification({
        type: "error",
        message: dashboardErr,
      });
      dispatch(SetDashboardState({ field: 'err', value: false }))
    }
    if (leadsErr) {
      Notification({
        type: "error",
        message: leadsErr,
      });
      dispatch(SetLeadsState({ field: 'messageError', value: false }))
    }
  }, [dashboardSuccess, dashboardErr, leadsErr])

  const { pipelines: { pipelinesData } } = useSelector(state => state);

  const dispatch = useDispatch();

  const {
    id: pipelineId
  } = props.match.params;

  const [stagesRecord, setStagesRecord] = useState([]);
  const [openSearchFilter, setOpenSearchFilter] = useState(false);

  const [filter, setFilter] = useState({ skip: 0, limit: 5, pipelineId });

  const [searchKeyword, setSearchKeyword] = useState("");

  const [sortByCreation, setSortByCreation] = useState([
    ["createdAt", "ascending"],
  ]);
  const [isLoading, setIsLoading] = useState(true);

  const handleGetStages = (filter) => {
    setIsLoading(true)
    dispatch(GetStages(filter));
  }

  const calculateActiveLeads = () => {
    let activeLeads = 0;
    stages.forEach(data => activeLeads += data.activeLeads || 0);
    return activeLeads;
  }

  const {
    stages,
    refreshStage
  } = useSelector(state => state.stages)

  useEffect(() => {
    handleGetStages(filter);
  }, [])

  useEffect(() => {
    if (refreshStage) {
      dispatch(SetState({ field: 'refreshStage', value: false }));
      handleGetStages(filter);
    }
  }, [refreshStage])

  useEffect(() => {
    handleGetAllStagesLeads(stages)
    setStagesRecord(stages);
    setIsLoading(false);
  }, [stages])

  const getPipelineName = () => {
    return pipelinesData.find(data => data._id === pipelineId)?.name || '';
  }

  useEffect(() => {
    dispatch(GetLists({ filter: { keyword: "" }, sort: sortByCreation, skip: 0 }));
    dispatch(GetTags({ filter: { keyword: "" }, sort: sortByCreation, skip: 0 }));
  }, [])


  const handleGetAllStagesLeads = (stages) => {
    const stageIds = [];
    if (stages.length) {

      stages?.forEach((stage, index) => {
        stageIds.push(stage?._id)
      })
    }
    if (stageIds.length) {
      dispatch(GetAllStagesLeads({ stageIds }))
    }
  }

  const handleHorizontalScroll = (event) => {
    const { left } = event;

    let newFilter = { ...filter };
    if (left > scrollValue) {
      newFilter = { ...filter, skip: filter.skip + 1, limit: 5 }
    } else if (left < scrollValue) {
      newFilter = { ...filter, skip: filter.skip - 1, limit: 5 };
    }
    setFilter(newFilter);
    handleGetStages(newFilter);

    scrollValue = left;
  }

  const searchLeadsByInput = useCallback(debounce(() => {
    handleGetStages(filter);
  }, 1500), [])


  return (
    <>
      <PageHeader
        title={getPipelineName()}
        className="page-header-main"
        buttons={[
          <div key="6" className="page-header-actions position-relative">
            <AddNewStage />
          </div>,
        ]}
      />
      <Main>
        <Row gutter={15}>
          <Col xs={24} className="mr-2 ">
            <KanvanBoardWrap>
              <div
                style={{ scrollBehavior: "auto", scrollMargin: "inherit" }}
                className="kanban-card-height"
              >
                {!isLoading ?
                  <Cards
                    className="card-heading"
                    headless
                    title={
                      <div className="main-card-header">
                        <h2 className="heading-title">
                          Leads:{" "}
                          <span className="heading-num">
                            {calculateActiveLeads()}
                          </span>
                        </h2>
                        <div key={1} className="search-box pipeline-search">
                          <SearchInput
                            search
                            className="leads-search-bar"
                            type="text"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {

                              }
                            }}
                            onChange={(event) => {
                              event.persist();
                              searchLeadsByInput(event.target.value);
                              setSearchKeyword(event.target.value)
                            }}
                            value={searchKeyword}
                            name="search"
                            placeholder="Search by Name"
                          />
                        </div>
                        {openSearchFilter && (
                          <div id={'search-filter-div'} className="d-flex justify-content-between filter-wrapper">
                            <div className="d-flex">
                              {(
                                <div>
                                  <div className="filter-dropdown-wrapper">
                                    <div
                                      className={
                                        openSearchFilter
                                          ? "overlay-advance-search-filter"
                                          : "overlay-advance-search-filter d-none"
                                      }
                                    >
                                      <div className="overlay-inner-wrapper">
                                        <Row gutter={[16, 16]}>
                                          <Col md={12}>
                                            <CustomDropDownMenu
                                              className="leads-custom-dropdown"
                                              name={"Stages"}
                                              allowClear
                                              placeholder={"Stages"}
                                            />
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="overlay-divider" />
                                      <div className="fotter-wrapper d-flex">
                                        <Button
                                          className="cancel-overlay-btn ant-btn-white"
                                          onClick={() => {
                                            setOpenSearchFilter(false);
                                          }}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          className="save-overlay-btn"
                                          onClick={() => {
                                            setOpenSearchFilter(false);
                                          }}
                                        >
                                          Apply
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    }
                  >
                    <Scrollbars
                      key={`${pipelineId}-scrollbar`}
                      // onScrollFrame={(event) => {handleHorizontalScroll(event)}}
                      className="sDash_kanban-board-item-scrolable-index"
                    >
                      <DndProvider backend={HTML5Backend}>
                        <div className="sDash_kanban-board-list">
                          {stagesRecord.length ?
                            stagesRecord.map((stage, index) => {
                              return (
                                <PipelineColumn
                                  stage={stage}
                                  keyword={searchKeyword}
                                // changeStagePosition={changeStagePosition}
                                />
                              );
                            }) : <div className="no-data-here-wrapper group-and-post-no-data">
                            </div>}
                        </div>
                      </DndProvider>
                    </Scrollbars>
                  </Cards> :
                  <div className="loader-center-wrapper">
                    <Spin size='large' /></div>}
              </div>
            </KanvanBoardWrap>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default Kanban;
