import React, { useEffect } from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PublicRoute from './public-route';
import PrivateRoute from './private-route';

import PageNotFound from '../container/pages/Page-Not-Found';
import SignIn from '../container/profile/authentication/overview/signIn';
import Verification from '../container/profile/authentication/overview/emailVerification';
import PaymentForm from '../container/profile/authentication/overview/paymentForm';
import SubscriptionPlans from '../container/profile/authentication/overview/subscriptionPlans';
import ForgotPassword from '../container/profile/authentication/overview/forgotPassword';
import ResetPassword from '../container/profile/authentication/overview/resetPassword';
import ConfirmEmail from '../container/profile/authentication/overview/confirmEmail';
import SetPassword from "../container/profile/authentication/overview/setPassword";
import verificationEmailSent from '../container/profile/authentication/overview/verificationEmailSent';
import OnboardingStepOne from "../container/profile/authentication/overview/onboarding-step-1";
import OnboardingStepTwo from "../container/profile/authentication/overview/onboarding-step-2";
import OnboardingStepThree from "../container/profile/authentication/overview/onboarding-step-3";

import { setAuthToken } from '../config/axios/axios-configuration';

const AuthRoute = () => {
  const { token, status } = useSelector(state => state.auth);
  const location = useLocation();
  const history = useHistory();

  setAuthToken(token);
  const { pathname: pathName } = location;
  useEffect(() => {
    if (status === 'Active' && (pathName?.includes('auth') || pathName?.includes('non-auth'))) {
      return history.push(`/dashboard`);
    }
  },[token])

  return (
    <Switch>
      <PublicRoute path='/auth/sign-in' component={SignIn} />
      <PublicRoute path='/auth/verification' component={Verification} />
      <PublicRoute path='/auth/forgot-password' component={ForgotPassword} />
      <PublicRoute path='/auth/reset-password' component={ResetPassword} />
      <PublicRoute path='/auth/confirm-email' component={ConfirmEmail} />
      <PublicRoute path='/auth/reset-password-email' component={verificationEmailSent} />
      <PublicRoute path='/auth/set-password' component={SetPassword} />
      
      {/* Private Routes */}

      <PrivateRoute path='/auth/subscription-plans' component={SubscriptionPlans} />
      <PrivateRoute path='/auth/add-subscription' component={PaymentForm} />
      <PrivateRoute path='/auth/onboarding-overview' component={OnboardingStepOne} />
      <PrivateRoute path='/auth/onboarding-connect' component={OnboardingStepTwo} />
      <PrivateRoute path='/auth/onboard-platforms' component={OnboardingStepThree} />
      <Redirect from='*' to='/' />
      <Route path='/not-found' component={PageNotFound} />
    </Switch>
  );
}

export default AuthRoute;
