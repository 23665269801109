import styled from "styled-components";

const CustomDropDown = styled.div`
.ant-select.custom-drop-down.ant-select-single.ant-select-allow-clear.ant-select-show-arrow .ant-select-selector {
    font-size: 13px;
    height: 36px !important;
    border: 0px;
    width: 180px;

}
`;
export default CustomDropDown;