import React from "react";

import { useSelector } from 'react-redux';
import {
  Table as TableData,
  Checkbox,
  Tooltip
} from "antd";

import Loader from "../../components/loader/index";
import Facebook from "../../static/img/facebook.svg";
import Messenger from "../../static/img/messenger.svg";
import NoUser from '../../static/img/no-user.png';
import { ReactComponent as UsergroupOutlined } from "../../static/img/usergroup-outlined.svg";

import {
  SolutionOutlined,
  UserOutlined,
  MessageOutlined
} from "@ant-design/icons";
import { BiLockOpen } from "react-icons/bi"
import "./style";

const Table = (props) => {

  const { leadsLoading } = useSelector(state => state.blockedLeads);

  const {
    leadsData,
    loading,
    handleTableChange,
    setSelectedLeads,
    setUnblockLeads,
    selectedLeads,
    pagination,
    setLead
  } = props;

  const onRowSelectionChange = ({ checked, selectedRows, leadData }) => {
    let newLists = selectedLeads;

    if (selectedRows === 'All') {
      if (checked) newLists.push(...leadData);
      else {
        newLists = selectedLeads.filter((value, index) => {
          return !leadData.find(data => data._id === value._id)
        })
      }
    }
    else if (checked) newLists.push(leadData);
    else newLists = newLists.filter(data => data.leadId !== leadData.leadId);
    newLists = [... new Set(newLists)];
    setSelectedLeads(newLists)
  };

  const handleImageError = (image) => {
    if (document.querySelector(`img[src="${image}"]`)) document.querySelector(`img[src="${image}"]`).src = NoUser;
  }

  const isSelected = (list) => selectedLeads.findIndex(item => (item?.leadId == list?.leadId)) !== -1;
  const isCompletelySelected = () => leadsData.every(data => { return selectedLeads.find(item => data.leadId == item.leadId) });

  const columns = [
    {
      title: <div>
        <Checkbox
          checked={leadsData.length ? isCompletelySelected() : false}
          onChange={(event) => {
            onRowSelectionChange({ checked: event.target.checked, selectedRows: 'All', leadData: leadsData })
          }} />
      </div>,
      dataIndex: "checkbox",
      width: 0,
    },
    {
      title: "Profile",
      dataIndex: "profile",
      width: 200,
      render: (data) => <div>{data}</div>
    },
    {
      title: "Profile Link",
      dataIndex: "profile_link",
      width: 263,
      align: 'left',
      render: (data) => <div>{data}</div>,
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      align: 'left',
      width: 270,
    },
    {
      title: "Actions",
      dataIndex: "action",
      fixed: "right",
      width: 100,
    },
  ];

  const setRelationshipStatus = (status) => {
    const { friends, groupMember, postMember, messenger } = status || {};
    return (
      <div className="d-flex profile-url gap-3">
        {friends && (
          <Tooltip placement="top" title={"Facebook Friends"}>
             <UserOutlined />
          </Tooltip>
        )}
        {groupMember && (
          <Tooltip placement="top" title={"Group Member"}>
            <UsergroupOutlined />
          </Tooltip>
        )}
        {messenger && (
          <Tooltip placement="top" title={"Messenger"}>
            <MessageOutlined />
          </Tooltip>
        )}
        {postMember && (
          <Tooltip placement="top" title={"Post Member"}>
            <SolutionOutlined />
          </Tooltip>
        )}
      </div>
    );
  };

  const items = [];
  leadsData.forEach((lead, index) => {
    items.push({
      key: index,
      checkbox: (
        <div className="d-flex user-profile gap-3">
          <Checkbox
            checked={isSelected(lead)}
            onChange={(event) => {
              onRowSelectionChange({
                checked: event.target.checked,
                selectedRows: 'Single',
                leadData: lead
              })
            }}
          />
        </div>
      ),
      profile: (
        <div className="d-flex user-profile gap-3">
          <img 
          className="user-img"
          src={lead.image || UserModal}
          onError={() => {handleImageError(lead.image)}}
          alt="" />
          <div className="heading-user-content-wrapper">
            <span>{lead.name}</span>
            <div className="d-flex lead-info-user">
              <Tooltip placement="top" title={lead.contact}><label className="leads-user-number">{lead.contact ? `+${lead.contact}` : ''}</label></Tooltip>
              <Tooltip placement="top" title={lead.email}> <label className="leads-user-number">{lead.email}</label></Tooltip>
            </div>
          </div>
        </div>
      ),
      profile_link: (
        <div className="block-leads-profile-links d-flex ">
        <a className="link-socail"
          href={`https://www.facebook.com/${lead.leadId}?leadId=${lead.leadId}`}
          target="_blank"
        >
          <img width="20" height="20" src={Facebook} alt="no-link" />
        </a>
        <a className="link-socail"
          href={`https://www.facebook.com/messages/t/${lead.leadId}?leadId=${lead.leadId}`}
          target="_blank"
        >
          <img className="meassanger-icon" width="20" height="20" src={Messenger} alt="" />
        </a>
      </div>
      ),
      relationship: (
        <div className="d-flex align-items-center relationship-data relation-ship gap-3">
          {setRelationshipStatus(lead.relationship)}
        </div>
      ),
      action: (
        <div className="d-flex justify-content-end">
          <Tooltip placement="top" title={"Unblock Lead"}>
            <BiLockOpen className="actions-icons lock-icon block-leads-lock-icon" icon="trash-2" size={16} onClick={() => { setLead(lead); setUnblockLeads(true) }} />
          </Tooltip>
        </div>
      ),
    });
  });

  return (
    <div>
      <TableData
        pagination={pagination}
        onChange={handleTableChange}
        columns={columns}
        dataSource={items}
        className="my-table"
        loading={{
          indicator: <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads"/></div>,
          spinning: leadsLoading
       }}
      />
    </div>
  );
};

export default Table;
