import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const GetDashboardRecord = createAsyncThunk(
  'dashboard/getDashboardRecord',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/dashboard/dashboard-record', { params: data });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UpdateDashboardRecord = createAsyncThunk(
  'dashboard/updateDashboardRecord',
  async (data, thunkAPI) => {
    try {
      const { label, value } = data;
      const response = await axios.put('/dashboard/dashboard-record', { label, value });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetDashboardGraph = createAsyncThunk(
  'dashboard/getDashboardGraph',
  async (data, thunkAPI) => {
    try {
      const { graphType } = data;
      const response = await axios.get('/dashboard/dashboard-graph', { params: { graphType } });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const auth = createSlice({
  name: 'dashboard',
  initialState: {
    leadsSynced: { value: 0, rating: 0, blocked: 0 },
    messagesSent: { value: 0, rating: 0 },
    leadsMovedToStages: { value: 0, rating: 0 },
    leadsAddedInPipelines: { value: 0, rating: 0 },
    leadsInPipelines: { value: 0, rating: 0 },
    valueOfDeals: { value: 0, rating: 0 },
    profilesVetted: { value: 0, rating: 0 },
    chartLoaded: false,
    graphData: [],
    columns: [],
    loading: false,
    success: false,
    information: false
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState() {
      return {
        cards: '',
        loading: false,
        success: false
      };
    }
  },
  extraReducers: {
    [GetDashboardRecord.pending]: (state, action) => ({
      ...state,
      success: false,
      loading: true
    }),
    [GetDashboardRecord.fulfilled]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      leadsSynced: action.payload.data?.leadsSynced,
      messagesSent: action.payload.data?.messagesSent,
      leadsAddedInPipelines: action.payload.data?.leadsAddedInPipelines,
      leadsMovedToStages: action.payload.data?.leadsMovedToStages,
      leadsInPipelines: action.payload.data?.leadsInPipelines,
      valueOfDeals: action.payload.data?.valueOfDeals,
      profilesVetted: action.payload.data?.profilesVetted,
      columns: action.payload.data?.columns
    }),
    [GetDashboardRecord.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err.error
    }),
    [GetDashboardGraph.pending]: (state, action) => ({
      ...state,
      success: false,
      chartLoading: true,
      loading: true
    }),
    [GetDashboardGraph.fulfilled]: (state, action) => ({
      ...state,
      success: false,
      chartLoading: false,
      loading: false,
      graphData: action.payload.data
    }),
    [GetDashboardGraph.rejected]: (state, action) => ({
      ...state,
      success: false,
      chartLoading: false,
      loading: false,
      err: action.payload.err?.error
    })
  }
});

const { reducer, actions } = auth;

export const {
  SetState,
  ClearState,
  logout
} = actions;

export default reducer;
