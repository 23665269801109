import React, { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";
import { CardStyleWrapper } from "./style";

const Card = (props) => {
  const { headingText, boxText, deleteClick, editClick } = props;
  const [boxTextLength, setBoxTextLength] = useState(boxText.slice(0, 72));
  const [isFullLength, setIsFullLength] = useState(false);

  const toggleTextLength = () => {
    if (isFullLength) {
      setBoxTextLength(boxText.slice(0, 72));
    } else {
      setBoxTextLength(boxText);
    }
    setIsFullLength(!isFullLength);
  };

  return (
    <CardStyleWrapper>
      <div className="card-header">
        <p>{headingText}</p>
        <div className="action-icons">
          <AiOutlineDelete onClick={deleteClick} />
          <BiPencil onClick={editClick} />
        </div>
      </div>
      <p className="card-discruption">
        {boxTextLength}
        {boxText.length > 72 && (
          <a onClick={toggleTextLength} href="#">
            {isFullLength ? " see less" : " see more"}
          </a>
        )}
      </p>
    </CardStyleWrapper>
  );
};

export default Card;
