import axios from 'axios';

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

const axiosBaseUrl = () => {
  axios.defaults.baseURL = process.env.NODE_ENV === 'development'
    ? 'http://localhost:90/api/'
    : 'https://api.chatpro.io/api/';

  return axios;
};

export {
  setAuthToken,
  axiosBaseUrl
};
