import styled from 'styled-components';

const DashboardWrapper = styled.div`
  margin-top:24px;
  .header-wrapper {
    margin-bottom:16px;
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 25px;
      letter-spacing: 0em;
      text-align: left;
    }
    .dashboard-search-box {
      input {
        border-radius: 30px;
        min-width: 252px;
      }
      .search-icon {
        position: absolute;
        right: 36px;
        top: 121px;
        color: #5f63f3;
      }
    }
  }
  button.sync-facebook-friend {
    background: #fff;
    color: #5F63F2;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 13px;
    border:1px solid #5F63F2 ;

    &:hover{
    background-color: #5F63F2;
    color: #fff;
    }
    svg{
      margin-right: 5px;
    }
}
  .dashboard-overflow-wrappper {
    height: calc(100vh - 146px);
  }
  .error-notification-wrapper {
    border: 1px solid #ff4d4f;
    width: 100%;
    padding: 7px 12px;
    border-radius: 6px;
    background: #FFFFFF;
    .error-inner {
      color: #ff4d4f;
      svg {
        position: relative;
        top: 3px;
        font-size: 16px;
        margin-right: 6px;
      }
      span {
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: left;
        .ancer-tags-wrapper {
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #ff4d4f;
          text-decoration: underline;
        }
        .crose-icon-wraper {
          font-size: 20px;
          color: #ff4d4f;
        }
      }
    }
    .crose-icon-wraper {
      font-size: 20px;
      color: #ff4d4f;
      cursor: pointer;
    }
  }
 
  .graph-wrapper {
    margin-top: 16px;
    .card-grid-wrapper{
      grid-gap:30px;
      flex-wrap:wrap ;
      margin-left: -30px;
    margin-right: 0px;
    }
    .column-wrapper {
    margin-right: -15px;
    margin-left: 15px;
}
    img {
      width: 100%;
      min-width: 252px;
    }
  }
  .bg-graph-wrapper {
    margin: 24px 0px;
    img {
      width: 100%;
      max-width: 92%;
    }
  }
  .setup-steps-card-wrapper {
    background: #ffffff;
    border-radius: 10px;
    .setup-steps-card {
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      padding:16px 0 0 16px;
    }
    .divider-card {
      width: 100%;
      height: 1px;
      background: #eceef5;
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .icons-text-wrapper {
      margin-bottom:16px ;
      .lock-svg-wrapper {
        min-width: 23px !important;
        width: 24px !important;
        position: relative;
      }
      svg {
      display: block;
      font-size: 28px;
      margin: auto;
      color: #20c997;
      margin-bottom: 9.4px;
      margin-top: -4px;

      }
    }
    p.m-0 {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: center;
    }
    .vertical-divider {
      height: 33px;
      width: 1px;
      background: rgba(95, 99, 242, 0.5);
      margin: 12px 0px !important;
    }
    .install-extention-btn {
    border: 1px solid #556ee6;
    color: #556ee6;
    margin-top: 12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 15.4px;
      &:hover {
        background-color: #556ee6;
        color: #fff;
      }
    }
    .import-btn {
      border: 1px solid #556ee6;
      color: #556ee6;
      margin-top: 12px;
      font-size: 12px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      padding: 0px 15.5px;
      &:hover {
        background-color: #556ee6;
        color: #fff;
      }
    }
  }
  .d-flex.rating-date-wrapper {
    margin-top: 9px;
}
  .cards-wrapper {
    background: #ffff;
    padding: 16px 16px;
    border-radius: 10px;
    min-width: auto;
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: -2px;
      text-align: left;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-top: 8px !important;
    }
    .message {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #5a5f7d;
      min-width: 233px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .rating-wrapper {
      color: #20c997;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
    }
    .date-wrapper {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      color: #5a5f7d;
      margin-left: 3px !important;
    }
    .content-area{
      width:100%;
    }
  }
  .icons-wrapper {
    img {
      min-width: 69px !important;
      min-height: 49px;
      margin: auto 0 0 0;
    }
  }
  .fotter-section {
    background: #f8f9fb;
    padding: 16px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    img {
      min-width: 14px !important;
      max-width: 14px !important;
    }
    .fotter-inner {
      margin-top: 32px;
      .play-wrapper {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #556ee6;
        display: flex;
        cursor: pointer;
        a{
          color:#556ee6;
        }
        img {
          margin-right: 4px;
        }
      }
      .call-wrapper {
        font-size: 13px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #556ee6;
        display: flex;
        cursor: pointer;
        a{
          color:#556ee6;
        }
        img {
          margin-right: 4px;
        }
      }
      .divider-wrapper {
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #adb4d2;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          width: 10px;
          height: 1px;
          background: #adb4d2;
          top: 11px;
          right: 24px;
        }
        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 1px;
          background: #adb4d2;
          top: 11px;
          left: 24px;
        }
      }
    }
  }
  .dashboard-table-wrapper table tbody tr td:first-child {
    border-radius: 0px !important;
    padding: 12px 0 12px 24px !important;
}
.ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0px !important;
    padding-left: 24px !important;
}
.dashbaord-header-button-wrapper {
    grid-gap: 16px;
    display: flex;
}

`;
export { DashboardWrapper };
