import Cookies from 'universal-cookie';

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar } from 'antd';

import { FiSettings } from "react-icons/fi"
import { BsLightbulb, BsQuestionCircle } from "react-icons/bs"

import FeatherIcon from 'feather-icons-react';
import Heading from '../../heading/heading';

import { InfoWraper, NavAuth, UserDropDwon } from './auth-info-style';
import Support from './support';
import { Popover } from '../../popup/popup';

import { logout, GetUser } from '../../../redux/slices/auth-slice'
import { ClearState as ClearLeadsState } from '../../../redux/slices/leads-slice';
import { ClearState as ClearPipelineState } from '../../../redux/slices/pipeline-slice';
import { ClearState as ClearKanbanState } from '../../../redux/slices/kanban-slice';


const AuthInfo = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    flag: 'english',
  });

  const {
    auth: { name: userName, facebookProfileData }
  } = useSelector((state) => state);

  const { image } = facebookProfileData || {};

  const SignOut = e => {
    e.preventDefault();
    dispatch(logout());
    dispatch(ClearLeadsState());
    dispatch(ClearPipelineState());
    dispatch(ClearKanbanState());
    cookies.remove('loggedIn', { path: '/' });
    cookies.remove('token', { path: '/' });
    history.push('/auth/sign-in');
  };

  const onLinkSelected = (value) => {
    history.push(value);
  }

  const userContent = (
    <UserDropDwon>
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <img src={require('../../../static/img/avatar/chat-auth.png')} alt="" />
          <figcaption>
            <Heading as="h5">{userName}</Heading>
          </figcaption>
        </figure>
        <ul className="user-dropdwon__links">
          <li>
            <Link onClick={() => onLinkSelected('/profile')} to="#">
              <FeatherIcon icon="user" /> Profile
            </Link>
          </li>
          <li>
            <Link onClick={() => { onLinkSelected('/background-jobs') }} to="#">
              <span className='icon-list-dropdown' /> Background Jobs
            </Link>
          </li>
          <li>
            <Link onClick={() => window.open("https://forms.clickup.com/14237522/f/djfuj-46538/W1OSIAPWSOKXTUGMDP", "_blank")} to="#">
              <BsLightbulb /> Suggest Feature
            </Link>
          </li>
          {/* <li>
            <Link onClick={() => { onLinkSelected('/settings') }} to="#">
              <FeatherIcon icon="settings" /> Settings
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="dollar-sign" /> Billing
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="users" /> Activity
            </Link>
          </li>
          <li>
            <Link to="#">
              <FeatherIcon icon="bell" /> Help
            </Link>
          </li> */}
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" /> Sign Out
        </Link>
      </div>
    </UserDropDwon>
  );

  const onFlagChangeHandle = value => {
    setState({
      ...state,
      flag: value,
    });
  };

  return (
    <InfoWraper>
      <Support />
      <div className="nav-author d-flex align-items-center">
        <div className='d-flex header-icons-wrapper'>
           <BsQuestionCircle className='header-setting-icon' />
            <FiSettings onClick={() => { onLinkSelected('/settings') }} className='header-setting-icon' />
        </div>
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example header-avatar">
            <Avatar src={image || "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"} />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
