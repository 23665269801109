import React from 'react';
import { Select, Tooltip } from 'antd';

import CustomDropDown from './style';
import { BiLinkExternal } from "react-icons/bi"

const IconDropDown = (props) => {
  const { Option } = Select;

  const {
    value,
    placeholder,
    name,
    id,
    onChange,
    options
  } = props;

  const handleString = (str) => {
    if (str) {
      if (str.length <= 17) return str;
      const maxLength = 17;
      const title = str.substr(0, maxLength);
      return `${title}...`;
    } else return "";
  };

  return (
    <CustomDropDown>
      <Select
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        allowClear={value ? true: false}
        className='custom-drop-down'
      >
        {name === 'groups' && options?.map((option, index) => {
          return (<Option id={id} key={index} value={option.groupId}>
            <Tooltip placement="topLeft" title={option.name}>
            <span className='dropdown-option'>
              {option.name}
            </span>
            </Tooltip>
            <a className='link-icon-dropdown' href={`https://www.facebook.com/groups/${option.groupId}`}
              target='_blank'>
              <BiLinkExternal />
            </a>
          </Option>);
        })}
        {name === 'posts' && options?.map((option, index) => {
          return (<Option id={id} key={index} value={option.postId}>
            <Tooltip placement="topLeft" title={option.name}>
            <span className='dropdown-option'>
              {option.name}
            </span>
            </Tooltip>
            <a className='link-icon-dropdown' href={`https://www.facebook.com/${option.postId}`}
              target='_blank'>
              <BiLinkExternal />
            </a>
          </Option>);
        })}

      </Select>
    </CustomDropDown>
  );
}

export default IconDropDown;