import React, { useState, useEffect } from "react";

import { Spin } from 'antd';
import { debounce } from "lodash";

import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as CheckMark } from "../../static/img/check-mark.svg"
import { ReactComponent as AlertIcon } from "../../static/img/alert-icon.svg"
import { ReactComponent as Invalid } from "../../static/img/invailed.svg"

import { Button } from "../../components/buttons/buttons";
import Input from "../../components/input/input";
import { EditProfileWrapper } from "./style";
import Notification from "../ant-notification/notification";

import { SetState, UpdateUserRecord, CompareOldPassword } from '../../redux/slices/auth-slice';

const EditProfile = (props) => {

  const { setShowResetPassword } = props;
  const { success, err, message, loading } = useSelector(state => state.auth);

  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordMatched, setOldPasswordMatched] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (success) {
      Notification({
        type: 'success',
        message: message || 'Success'
      });
      dispatch(SetState({ field: 'success', value: '' }));
    }
    if (err) {
      Notification({
        type: 'error',
        message: err || 'Error!, Please Try Again'
      });
      dispatch(SetState({ field: 'err', value: '' }));
    }
  }, [success, err])

  const handleUpdatePassword = () => {
    if (password !== confirmPassword) return dispatch(SetState({ field: 'err', value: 'Passwords Are Not Same' }));
    dispatch(UpdateUserRecord({ password }));
  }

  const handleComparePassword = debounce((value) => {
    dispatch(CompareOldPassword({ oldPassword: value })).then(data => {
      setOldPasswordMatched(data.payload);
    });
  }, 500)

  const handleCancelResetPassword = () => {
    setOldPasswordMatched(false);
    setOldPassword('');
    setPassword('');
    setConfirmPassword('');
    setShowResetPassword(false);
  }

  return (
    <EditProfileWrapper>
      <div className="header-wrapper">
        <h1 className="mb-0">Edit Profile</h1>
        <div className="divider" />
      </div>
      <div className="edit-profile-content-wrapper">
        <div className="content d-flex justify-content-between">
          <p className="mb-0">Please enter a new strong password to be used for your ChatPro account.</p>
        </div>
        <div className="reset-password-wrapper">
          <div className="form">
            <div className="form-item form-item-wrapper">
              <label>Current Password</label>
              <div className="d-flex align-items-center password-fields-wrapper">
                <Input type={"password"} value={oldPassword} onChange={(event) => { handleComparePassword(event.target.value); setOldPassword(event.target.value) }} placeholder="New Password Here" /><div className="password-alerts-wrapper">
                  {oldPassword.length && oldPasswordMatched ? <CheckMark /> : ''} 
                  {!oldPasswordMatched && oldPassword.length ? <span><AlertIcon/><span className="alert-text-wrapper">Wrong Password</span></span> : ''}
                </div>
              </div>
            </div>
            <div className="form-item form-item-wrapper">
              <label>New Password</label>
              <div className="d-flex align-items-center password-fields-wrapper">
                <Input type={"password"} value={password} onChange={(event) => { setPassword(event.target.value) }} placeholder="New Password Here" /><div className="password-alerts-wrapper">
                </div>
              </div>
            </div>
            <div className="form-item form-item-wrapper">
              <label>Confirm Password</label>
              <div className="d-flex align-items-center password-fields-wrapper">
                <Input type={"password"} value={confirmPassword} onChange={(event) => { setConfirmPassword(event.target.value) }} placeholder="Confirm Password" /><div className="password-alerts-wrapper">
                  {confirmPassword?.length && password !== confirmPassword ? <span><AlertIcon /><span className="alert-text-wrapper">Passwords didn’t match.</span></span> : ''}
                </div>
              </div>
            </div>
            <div className="reset-password">
              <div className="fotter-btn-wrapper">
                <Button onClick={handleCancelResetPassword} className="cancel-btn">Cancel</Button>
                <Button disabled={!oldPasswordMatched || loading || confirmPassword !== password} onClick={handleUpdatePassword} className="update-btn" outlined>
                  {loading ? <Spin size="small" /> : 'Confirm Password'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditProfileWrapper>
  );
};
export default EditProfile;
