import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { AuthWrapper } from './style';

import LeftSide from './authSideGrid';

import Mail from '../../../../static/img/mail.svg';

import Notification from '../../../ant-notification/notification';

import { ForgotPassword, SetState } from '../../../../redux/slices/auth-slice';

const confirmEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [disableResendEmail, setDisableResendEmail] = useState(false);
  const { err, success, resetEmail: email } = useSelector(
    (state) => state.auth
  );

  const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

  useEffect(() => {
    if (err) {
      Notification({
        type: 'error',
        message: err
      });
    }
    dispatch(SetState({ field: 'err', value: '' }));
  }, [err]);

  useEffect(() => {
    if (success) {
      Notification({
        type: 'success',
        message: 'Email Has Been Sent',
      });
      dispatch(SetState({ field: 'success', value: false }));
    }
  }, [success]);

  const handleSignInRoute = () => {
    history.push('/auth/sign-in');
  };

  const handleResendEmail = async () => {
    setDisableResendEmail(true);
    dispatch(ForgotPassword({ email }));
    await sleep(5000);
    setDisableResendEmail(false);
  };

  return (
    <AuthWrapper>
      <Row className="d-flex">
        <Col md={11} className="left-side-img">
          <LeftSide />
        </Col>
        <Col md={13} className="right-side-content">
          <div className="right-side-Content confirm-email-div-content">
            <div className="confirm-email-image">
              <img src={Mail} />
            </div>
            <h3>Confirm Your Email Address</h3>
            <p>
              Please check the email we sent you for the confir-mation email. Be
              sure to check spam if you can't find it.
            </p>
            <p className="confirm-receive-email">
              Didn't receive the confirmation email?
              <Link to="#" disabled={disableResendEmail} onClick={handleResendEmail} className="link-resend">
                {" "}
                Resend
              </Link>
            </p>
            <span>
              <i>Return to</i>
              <Link to="#" onClick={handleSignInRoute} className="link-sign-in">
                Sign in
              </Link>
            </span>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  );
};
export default confirmEmail;
