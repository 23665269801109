import { createSlice } from '@reduxjs/toolkit';

import staticData from '../../config/config';


const ChangeLayoutMode = createSlice({
  name: 'auth',
  initialState: {
    data: staticData.darkMode,
    rtlData: staticData.rtl,
    topMenu: staticData.topMenu,
    loading: false,
    rtlLoading: false,
    menuLoading: false,
    error: null,
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {  }
});

const { reducer, actions } = ChangeLayoutMode;

export const {
  SetState
} = actions;

export default reducer;
