import React, { useState, useEffect } from "react";
import { useSelector, useHistory } from 'react-redux';

import { Tabs, Row, Col, Switch } from "antd";

import Invoices from "./invoices";
import Payment from "./AddCard";
import Subscriptions from "./subscriptionPlan";
import LinkFacebookAccount from "./linkFacebookAccount";
import SocialMedia from "./socialMedia";


import { Items } from '../../utils/constants';
import { SettingWrapper } from "./style";

const Setting = () => {

  const [switchAccount, setSwitchAccount] = useState(false);

  const [tabName, setTabName] = useState("invoice");
  const {
    auth: { name, facebookProfileData = {} }
  } = useSelector((state) => state);

  const { image, profileId } = facebookProfileData || {};

  const handleTabChange = (item) => {
    if (item === 'media' && switchAccount) setSwitchAccount(false);
    setTabName(item);
  };

  const handleImageError = () => {
    const userImage = document.querySelector('img[id="social-media-main"]');
    if (userImage) userImage.src = 'https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png'
  }

  return (
    <SettingWrapper>
      <div className="setting-header-wrapper d-flex justify-content-between">
        <h1 className="m-0">Settings</h1>
      </div>
      <Row gutter={30}>
        <Col md={6}>
          <div className="setting-menu-wrapper">
            <div className="setting-menu">
              <div className="user-wrapper">
                <img id={'social-media-main'} onError={handleImageError} src={image || "https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png"} className="user-img" />
              </div>
              <h1 className="mb-0">{name}</h1>
            </div>
            <div className="setting-user-divider" />
            <div className="tabs-wrapper">
              <Tabs
                items={Items}
                onTabClick={(event) => handleTabChange(event)}
                tabPosition="left"
              >
                {Items?.map(({ key, label }) => {
                  return <Tabs.TabPane tab={label} key={key}></Tabs.TabPane>;
                })}
              </Tabs>
            </div>
            <div className="footer-wrapper">
              {/* <Switch /> <span>Enable Background Jobs?</span> */}
            </div>
          </div>
        </Col>
        <Col md={18}>
          <div className="content-wrapper">
            {tabName === "invoice" && <div><Invoices /></div>}
            {tabName === "payment" && <div><Payment /></div>}
            {tabName === "media" && !switchAccount &&
              <div><SocialMedia
                image={image}
                name={facebookProfileData?.name || name}
                setSwitchAccount={setSwitchAccount}
              /></div>}
            {tabName === "media" && switchAccount &&
              <div><LinkFacebookAccount
                profileId={profileId}
                setSwitchAccount={setSwitchAccount}
              /></div>}
            {tabName === "plans" && <div><Subscriptions /></div>}
            {/* { tabName === "invoice" ? (
              <div><Invoices /></div>
            ) : tabName === "payment" ? (
              <div><Payment /></div>
            ) : tabName === "media" ? (
              <div>
                {/* <SocialMedia /> */}
            {/* <LinkFacebookAccount />
          </div>
          ) : tabName === "plans" ? (
          <div><Subscriptions /></div>
          ) : (
          ""
            )} */}
          </div>
        </Col>
      </Row>
    </SettingWrapper >
  );
};
export default Setting;
