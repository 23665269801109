/*global chrome*/

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip } from "antd";

import { debounce } from "lodash";
import moment from 'moment';

import {
  Checkbox,
  Col,
  Row,
  Spin,
  Input,
  Form,
} from "antd";

import { FormOutlined } from "@ant-design/icons"
import { FiTrash2 } from "react-icons/fi";

import FeatherIcon from "feather-icons-react";
import { AiOutlineEye, AiOutlineCopy  } from "react-icons/ai"
import Notification from '../ant-notification/notification';

import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Button } from "../../components/buttons/buttons";
import { Modal } from "../../components/modals/antd-modals";
import InputSearch from "../../components/searchBar/input";
import Loader from "../../components/loader/index";

import NoData from "../../static/img/no-data.svg"

import { Main, CardWrapper } from "../styled";

import {
  AddPipelineRecord,
  GetPipelinesRecord,
  UpdatePipelineRecord,
  RemovePipelinesRecord,
  SetState,
  DuplicatePipelineRecord
} from '../../redux/slices/pipeline-slice';

import {
  SetState as SetStageState
} from '../../redux/slices/stages-slice';

import {
  ExtensionId
} from "../../utils/constants";
import { unwrapResult } from "@reduxjs/toolkit";


const Pipelines = () => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const history = useHistory();

  const { pipelines: { pipelinesData, message, loading, pipelineLoading, success, err } } = useSelector(state => state);
  const [edit, setEdit] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [create, setCreate] = useState(false);
  const [deletePipeline, setDeletePipeline] = useState(false);
  const [selectedPipelines, setSelectedPipelines] = useState([]);
  const [pipelineData, setPipelineData] = useState({});
  const [pipelines, setPipelines] = useState([]);
  const [singleSelection, setSingleSelection] = useState(false);
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState([["createdAt", "ascending"]]);

  const isSelected = (data) => selectedPipelines.findIndex(item => (item._id == data._id)) !== -1;
  const isCompletelySelected = () => pipelinesData.length && pipelinesData.every(data => { return selectedPipelines.find(item => data._id == item._id) });

  const onRowSelectionChange = ({ checked, selectedRows, pipelineData }) => {
    let newPipelines = selectedPipelines;

    if (selectedRows === 'All') {
      if (checked) newPipelines.push(...pipelineData);
      else {
        newPipelines = newPipelines.filter((value, index) => {
          return !pipelineData.find(data => data._id === value._id)
        })
      }
    } else if (checked) newPipelines.push(pipelineData);
    else newPipelines = newPipelines.filter(data => data._id !== pipelineData._id);
    newPipelines = [... new Set(newPipelines)];

    setSelectedPipelines(newPipelines);
  };

  const showLeadsCount = (value) => {
    if (Number(value) > 1000) value = `${value.toString().slice(0, value.toString().length - 3)}k+`;
    return `${value}`
  }

  useEffect(() => {
    if (success) {
      Notification({
        type: 'success',
        message: message || 'Success',
      });
      dispatch(GetPipelinesRecord({ filter, sort }));
      dispatch(SetState({ field: 'success', value: false }))
      dispatch(SetState({ field: 'message', value: '' }))
    } else if (err) {
      Notification({
        type: 'error',
        message: err
      });
      dispatch(SetState({ field: 'err', value: false }))
    }

    setCreate(false);
    setEdit(false);
    setDeletePipeline(false);
    setDisabled(false);
  }, [success, err])

  const sendPipelinesDataToExtension = () => {
    const pipelines = pipelinesData?.map(({ _id, name }) => { return { name, value: _id }})

    if (!chrome?.runtime) return;
    chrome.runtime?.sendMessage(
      ExtensionId,
      {
        messageType: "PIPELINES_DATA",
        pipelines
      });
  }

  useEffect(() => {
    dispatch(GetPipelinesRecord({ filter, sort }));
  }, []);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') sendPipelinesDataToExtension();
    setPipelines(pipelinesData);
  }, [pipelinesData])

  const handleAddPipelineData = async () => {
    if (!pipelineData.name || pipelineData.name?.trim()?.length === 0) {
      return dispatch(SetState({ field: 'err', value: 'Name Cannot Be Empty' }))
    }
    setDisabled(true);
    const data = { ...pipelineData, name: pipelineData.name.trim() };
    await dispatch(AddPipelineRecord(data));
    setPipelineData({});
  }

  const handleUpdatePipelineData = async () => {
    if (!pipelineData.name || pipelineData.name?.trim()?.length === 0) {
      return dispatch(SetState({ field: 'err', value: 'Name Cannot Be Empty' }))
    }
    setDisabled(true);
    const data = { ...pipelineData, name: pipelineData.name.trim() };
    await dispatch(UpdatePipelineRecord(data));
    setPipelineData({});
  }

  const handleDuplicatePipeline = async () => {
    if (!pipelineData.name || pipelineData.name?.trim()?.length === 0) {
      return dispatch(SetState({ field: 'err', value: 'Name Cannot Be Empty' }))
    }

    if(pipelines.some((pipeline) => pipeline.name === pipelineData.name)) {
      return dispatch(SetState({ field: 'err', value: 'Pipeline Already Exists With This Name' }))
    }
    setDisabled(true);
    const data = {pipelineId: pipelineData._id, copyLeads: pipelineData.duplicateLeads, newName: pipelineData.name}
    await dispatch(DuplicatePipelineRecord(data))
    setPipelineData({});
    setDuplicate(false);
  };

  const handleDeletePipelineData = async () => {
    let pipelines = [];
    if (singleSelection) pipelines.push(pipelineData._id)
    else pipelines = selectedPipelines.map(pipeline => pipeline._id)
    setDisabled(true);
    await dispatch(RemovePipelinesRecord(pipelines));
    setSingleSelection(true);
    setSelectedPipelines([]);
  }

  const handleSearchChange = debounce((value) => {
    setFilter({ keyword: value });
    dispatch(GetPipelinesRecord({ filter: { keyword: value }, sort }));
  }, 500)

  const handleKanbanComponent = (pipeline) => {
    const { _id, placeAtTop } = pipeline;
    dispatch(SetStageState({ field: 'stages', value: [] }));
    dispatch(SetStageState({ field: 'stageLeads', value: [] }));
    dispatch(SetState({ field: 'selectedPipelineData', value: _id }));
    dispatch(SetState({ field: 'placeAtTop', value: placeAtTop ? placeAtTop : false }));
    history.push(`/pipelines/${_id}`);
  }

  const data = [];
  pipelines.forEach((pipeline, index) => {
    data.push({
      id: index,
      data: pipeline,
      checkbox: <Checkbox className="m-0 p-0" type="checkbox" checked={isSelected(pipeline)} onChange={(event) => { onRowSelectionChange({ checked: event.target.checked, selectedRows: 'Single', pipelineData: pipeline }) }} />,
      boxtext: <span onClick={() => { handleKanbanComponent(pipeline) }}>{pipeline.name.length > 30 ? `${pipeline.name.slice(0, 50)}...` : pipeline.name}</span>,
      name: <span>{pipeline.name}</span>,
      duplicate: <Tooltip placement="topLeft" title="Duplicate pipeline"> <AiOutlineCopy  className='note-icon' /></Tooltip>,
      Eye: <Tooltip placement="topLeft" title="View pipeline stages"> <AiOutlineEye onClick={() => { handleKanbanComponent(pipeline) }} className='note-icon' /></Tooltip>,
      edit: <Tooltip placement="topLeft" title="Edit pipeline"><FormOutlined className='note-icon' /></Tooltip>,
      bucket: <Tooltip placement="topLeft" title="Delete pipeline"><FiTrash2 className='bucket-icon' /></Tooltip>,
      content: "Active Users",
      footer: showLeadsCount(pipeline.activeLeads),
      rightcontent: "Date Created",
      rightfooter: moment(pipeline.createdAt).format("D MMM YYYY"),
    })
  });

  return (
    <>
      <div className="pipeline-heading heading-pipeline-wrapper">
        <PageHeader
          ghost
          title="Pipelines"
          className="pipeline-heading page-header-main"
          buttons={[
            <div key="6" className="page-header-actions">
              <div className="d-flex">
                <div key={1} className="search-box pipeline-search-bar-wrapper">
                  <InputSearch
                    search
                    className="leads-search-bar"
                    onChange={(event) => { handleSearchChange(event.target.value) }}
                    placeholder="Search by Name"
                    type="text" />

                </div>
                <div className="d-flex align-items-center checkbox-wrapper">
                  <Checkbox checked={isCompletelySelected()}
                    onChange={(event) => {
                      onRowSelectionChange({
                        checked: event.target.checked,
                        selectedRows: 'All',
                        pipelineData: pipelines
                      })
                    }}
                    className="checkbox"
                    type="checkbox" />
                  <span>Select All</span>
                </div>
              </div>

              <Button outlined
              className={selectedPipelines.length >= 1 ? 'pipeline-delete-card-btn' : "pipeline-delete-disable-btn"} size="default" type="primary" onClick={() => { setSingleSelection(false); setDeletePipeline(true) }}>
                <FiTrash2 className="trash-bukets" />
                Delete Pipelines
              </Button>
              <Button outlined className='add-new-pipeline' size="default" type="primary" onClick={() => { setPipelineData({}); setCreate(true) }}>
                <FeatherIcon icon="plus" />
                Add New Pipeline
              </Button>
            </div>,
          ]}
        />
      </div>
    <Main>
        <CardWrapper>
          <Row gutter={25} className="row-style">
            <Col lg={24} xs={24}>
              <div className="pipeline-list-card-wrapper-scroll">
                {
                  data.length ? <Row gutter={[30, 24]} className="row-style">
                    {
                      data.map(({ checkbox, boxtext, name, edit, content, data, rightcontent, duplicate, Eye, rightfooter, bucket, footer, id }) => {
                        return (
                          <Col key={id} md={8}>
                            <div className="pipeline-card-wrapper">
                              <div className="d-flex justify-content-between pipeline-card-wrapper-item">
                                <div className="d-flex card-input">
                                  <span>{checkbox}</span>
                                  <Tooltip placement="topLeft" title={name}><span className="checkbox-text pipeline-checkbox-link-text">{boxtext}</span></Tooltip>
                                </div>
                                <div className="d-flex cards-icons">
                                  <span className="note-pad" onClick={() => {
                                    setPipelineData({...data, duplicateLeads: true, name: `${data.name} Copy` });
                                    setDuplicate(true);
                                  }}>
                                    {duplicate}
                                  </span>
                                  <span className="note-pad">
                                    {Eye}
                                  </span>
                                  <span className="note-pad" onClick={() => { setPipelineData(data); setEdit(true) }}>
                                    {edit}
                                  </span>
                                  <span className="bucket-icon" onClick={() => { setSingleSelection(true); setPipelineData(data); setDeletePipeline(true) }}>
                                    {bucket}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between footer-wrapper">
                                <div className="d-flex flex-column user-info-pipeline">
                                  <span className="user-info">{content}</span>
                                  <span>{footer}</span>
                                </div>
                                <div className="d-flex flex-column user-info-pipeline pipeline-footer">
                                  <span className="user-info">{rightcontent}</span>
                                  <span>{rightfooter}</span>
                                </div>
                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }
                  </Row> : <div className="no-data-here-wrapper">
                    {pipelineLoading ? <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads" /></div> :
                      <img src={NoData} alt="No Data Here" />
                    }
                  </div>
                }
              </div>
              <Cards headless className="p-3">
                <div className="pipeline-modal-wrapper-add">
                  <Modal
                    className="Create New Pipeline create-new-pipeline-modal"
                    footer={null}
                    type="primary"
                    title="Create New Pipeline"
                    centered
                    visible={create}
                    width={628}
                    closable={true}
                    onCancel={() => {
                      setCreate(false)
                    }}
                  >
                    <Form form={form} layout="vertical">
                      <Form.Item label="Title">
                        <Input
                          className="border-none"
                          value={pipelineData.name}
                          onChange={(event) => {
                            setPipelineData({ ...pipelineData, 'name': event.target.value })
                          }}
                          placeholder="Pipeline Name Here" />
                      </Form.Item>
                      <Form.Item>
                        <Checkbox
                          onClick={(event) => { setPipelineData({ ...pipelineData, 'placeAtTop': event.target.checked }) }}
                        >
                          When moving a lead card from one stage to another, place it at the start.
                        </Checkbox>
                      </Form.Item>
                    </Form>
                    <div className="modal-footer d-flex justify-content-end">
                      <Button
                        size="default"
                        outlined
                        type="white"
                        onClick={() => { setPipelineData({}); setCreate(false) }}
                      >
                        Cancel
                      </Button>
                      <Button disabled={disabled} onClick={handleAddPipelineData} size="default" type="primary" className="ml-2 create-new-pipeline-btn">
                        {loading ? <Spin size="small" /> : 'Create'}
                      </Button>
                    </div>
                  </Modal>
                </div>
                <Modal
                  className="Create New Pipeline create-new-pipeline-modal"
                  footer={null}
                  type="primary"
                  title="Edit Pipeline"
                  centered
                  visible={edit}
                  width={628}
                  closable={true}
                  onCancel={() => {
                    setEdit(false)
                  }}
                >
                  <Form form={form} layout="vertical">
                    <Form.Item label="Title">
                      <Input
                        className="border-radius-none"
                        value={pipelineData.name}
                        onChange={(event) => { setPipelineData({ ...pipelineData, 'name': event.target.value }) }}
                        placeholder="Social Ninja Tool Pipeline"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={pipelineData.placeAtTop}
                        onClick={(event) => { setPipelineData({ ...pipelineData, 'placeAtTop': event.target.checked }) }}
                      >
                        When moving a card from a stage to other place it on top.
                      </Checkbox>
                    </Form.Item>
                  </Form>
                  <div className="modal-footer d-flex justify-content-end">
                    <Button
                      size="default"
                      outlined
                      type="white"
                      onClick={() => setEdit(false)}
                    >
                      Cancel
                    </Button>
                    <Button disabled={disabled} onClick={handleUpdatePipelineData} size="default" type="primary" className="ml-2 Edit-list-pipeline-btn">
                      {loading ? <Spin size="small" /> : 'Save'}
                    </Button>
                  </div>
                </Modal>
                <Modal
                  className="Create New Pipeline create-new-pipeline-modal"
                  footer={null}
                  type="primary"
                  title="Duplicate Pipeline"
                  centered
                  visible={duplicate}
                  width={628}
                  closable={true}
                  onCancel={() => {
                    setDuplicate(false)
                  }}
                >
                  <Form form={form} layout="vertical">
                    <Form.Item label="Title">
                      <Input
                        className="border-radius-none"
                        value={pipelineData.name}
                        onChange={(event) => { setPipelineData({ ...pipelineData, 'name': event.target.value }) }}
                        placeholder="Social Ninja Tool Pipeline"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Checkbox
                        checked={pipelineData.duplicateLeads}
                        onClick={(event) => { setPipelineData({ ...pipelineData, duplicateLeads: !pipelineData.duplicateLeads }) }}
                      >
                        Copy leads
                      </Checkbox>
                    </Form.Item>
                  </Form>
                  <div className="modal-footer d-flex justify-content-end">
                    <Button
                      size="default"
                      outlined
                      type="white"
                      onClick={() => {
                        setDuplicate(false); 
                        setPipelineData({});
                      }}
                    >
                      Cancel
                    </Button>
                    <Button disabled={disabled} onClick={handleDuplicatePipeline} size="default" type="primary" className="ml-2 Edit-list-pipeline-btn">
                      {loading ? <Spin size="small" /> : 'Save'}
                    </Button>
                    
                  </div>
                </Modal>
                <Modal
                  className="addEvent-modal crm-basic-modal delete-pipeline-wraper"
                  footer={null}
                  type="primary"
                  centered
                  visible={deletePipeline}
                  width={440}
                  closable={false}
                >
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <FiTrash2 alt="block-user" className="custom-delete-icon-modal" />
                    {singleSelection ? <>
                      <h2 className="mb-3 text-center color-dark ">
                        Are you sure you want to delete this pipeline?
                      </h2>
                      <p className="text-center fs-14 color-gray">
                        This will delete the selected pipeline and their respective stages will also be deleted.
                      </p></> : <>
                      <h2 className="mb-3 text-center color-dark ">
                        Are you sure you want to delete these pipelines?
                      </h2>
                      <p className="text-center fs-14 color-gray">
                        This will delete the selected pipelines and their respective stages will also be deleted.
                      </p></>}
                  </div>
                  <div className="modal-footer d-flex justify-content-center">
                    <Button
                      size="default"
                      outlined
                      type="white"
                      onClick={() => setDeletePipeline(false)}
                    >
                      Cancel
                    </Button>
                    <Button disabled={disabled} onClick={handleDeletePipelineData} size="default" type="primary" className="pipeline-list-delete-btn">
                      {loading ? <Spin size="small" /> : 'Delete'}
                    </Button>
                  </div>
                </Modal>
              </Cards>
            </Col>
          </Row>
        </CardWrapper >
      </Main >
    </>
  );
};

export default Pipelines;
