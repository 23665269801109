import Styled from "styled-components";

const RowWrapper = Styled.div`
.filter-wrapper {
    background: #ffff;
    margin-bottom: 12px;
    /* margin-top:4px; */
    margin-top:0px;
    align-items: center;
    border-radius:8px;
    /* padding:11px 14px 13px; */
    padding:12px 16px;
    .filter-list{
        flex-wrap:wrap;
    }
    .filter-tag {
        margin-left: 3px;
    }
    .filter-icon {
    color: #5F63F2;
    font-weight: 700;
    font-size: 17px;
    margin-left: 24px;
    align-self: center;
    cursor:pointer;
}
    span{
        color:#000000 ;
        font-weight:500;
        font-size:12px;
        line-height:18px;
    }
    .font-bold{
        font-weight:700;
        color:#000 ;
        font-size:14px;
    }
    li.bages {
    border-radius: 15px;
    padding: 0px 8px;
    margin: 0px 4px;
    border: 1px solid #9BA8B7;
    position: relative;
    top: 2px;
    left: 2px;
    a{
      color:#000;
      padding: 1px 0px;
      display: inline-block;
    }
}
}
    span.search-icon {
    position: absolute;
    top: 7px;
    right: 8px;
}
.filter-dropdown-wrapper {
    position: absolute;
    width: 480px;
    height: auto;
    top: 213px;
    right: 41px;
    z-index: 6;
    padding-bottom:16px;
    box-shadow: 0px 4px 16px 0px #00000029;
    border-radius:5px;
    border:1px solid #F1F2F6;
    background: #FFFFFF;
    transition:3s ;
    .select-wrapper-filter {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0px 12px 24px;
    span{
        font-weight:400;
    }
}
.divider{
    margin:0px ;
    color:#F1F2F6 ;
}
.select-wrapper-filter .ant-select.ant-select-single.ant-select-allow-clear.ant-select-show-arrow {
    width: 208px;
}
.leads-select-list{
    margin-left:0px ;
    margin-top:16px;
    border:1px solid #ECEEF5;
    border-radius:5px;
    &.lead-list-align{
        margin-right:16px;
        
    }
    .sc-kpOJdX.blyKQb{
        margin:0px;
    }
    .sc-dxgOiQ.liQkez {
    height: 33px;
}
}
}
.button-wrapper{
    padding-right:24px;
    padding-top:18px;
    .btn-cancel{
    color: #9299B8;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #E3E6EF;
    margin-right:12.5px;
}
.btn-apply{
    background:#5F63F2 ;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #E3E6EF;
    span{    
    color:#fff;
    }
}
}
`;

const FilterWrapper = Styled.div`
.relationship-dropdown-wrapper {
    margin-left: 20px;
    width: 200px;
}
`;

const UserModalWrap = Styled.div`
.user-info-wrap-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.user-info-wrap {
    flex: 1;
    margin-top: 10px;
}
.can-btn-info {
  margin-right: 10;
}
.footer-btn-wrap {
  text-align: right
}
.submit-btn-info {
  margin-left: 10px;
}
`;

export {
  RowWrapper,
  UserModalWrap,
  FilterWrapper
};
