import React from "react";

import { useSelector } from "react-redux";

import { Table } from "antd"

import { BsEye } from "react-icons/bs"

import bagDelete from "../../static/img/bag-delete-icon.svg";

import { EditProfileWrapper } from "./style";

const EditProfile = () => {

  const { name, email } = useSelector(state => state.auth);

  const dataSource = [
    {
      key: '1',
      name: name,
      age: email,
      address: <div className="tool-manegement-wrapper d-flex"><span className="d-flex alignitems-center bages-wrapper"><span>ChatPro</span><img src={bagDelete} /></span></div>,
      actions:<><BsEye className="eye-icon-wrapper"/></>,
    } 
  ];

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 181,
    },
    {
      title: 'Email',
      dataIndex: 'age',
      key: 'age',
      width: 236,
    },
    {
      title: 'Tools to Manage',
      dataIndex: 'address',
      key: 'address',
      width: 314,

    },
    {
      title:'Actions',
      dataIndex:'actions',
      key:'actions',
      width:81,

    }
  ];

  return (
    <EditProfileWrapper>
      <div className="header-invoices">
        <h1 className="mb-0">Invoices</h1>
        <div className="divider" />
      </div>
      <div className="invoices-wrapper">
        <div className="content d-flex justify-content-between">
          <p className="mb-0">View complete invoices.</p>
        </div>
        <div className="table-wrapper">
        <Table dataSource={dataSource} columns={columns} />
        </div>
      </div>
    </EditProfileWrapper>
  );
};
export default EditProfile;
