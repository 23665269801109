import React, { useState, useEffect, useRef } from "react";
import Notification from '../ant-notification/notification';

import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Modal, Select, Form, Spin } from "antd";
import Input from "../../components/input/input";
import EditModal from "../../components/CardEditModal";
import { BsCreditCard } from "react-icons/bs";
import { FaRegTimesCircle } from "react-icons/fa";
import { countries, cities, states } from "../../utils/constants";
import Vise from "../../static/img/visa.svg";
import PlusIcon from "../../static/img/add-card-plus.svg";
import Loader from "../../components/loader";
import { Button } from "../../components/buttons/buttons";
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { EditProfileWrapper } from "./style";

import { GetCustomerCards, SetState, UpdateCustomerCards, AddCustomerCards, deleteCustomerCards } from "../../redux/slices/payment-slice";

const { REACT_APP_STRIPE_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);
const CardsForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const elements = useElements();
  const stripe = useStripe();

  // const { payment, cardName } = useSelector(state => state.auth);
  const { cards, loading, success,
    err, } = useSelector(state => state.payment);
  // const [cardData, setCardData] = useState({});
  const [removeCard, setRemoveCard] = useState(false);
  const [addCard, setAddCard] = useState(false);
  const [editCard, setEditCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  useEffect(() => {
    dispatch(GetCustomerCards())
  }, []);


  useEffect(() => {
    if (err) {
      Notification({
        type: 'error',
        message: err
      });
      dispatch(SetState({ field: "err", value: "" }));
    }

    if (success) {
      dispatch(GetCustomerCards());
      setEditCard(false);
      setAddCard(false);
      setRemoveCard(false)
    }

  }, [err, success,]);
  const handleForm = async (card_id) => {
    const userData = form.getFieldsValue();
    if (!userData?.expiration) { dispatch(SetState({ field: 'err', value: 'Please Enter Expiry Date' })); }
    else {

      console.log({userData})
      const { name, address_city, address_country, address_state, expiration, address_zip, address_line1, address_line2 } = userData;
      const [month, year] = expiration?.split('/');
      const details = {
        address_city,
        address_country,
        address_line2,
        address_line1,
        address_state,
        address_zip,
        name,
        exp_month: month,
        exp_year: year
      }

      dispatch(UpdateCustomerCards({ card_id, details }))
    }


  }
  const handleSaveForm = async () => {
    const userData = form2.getFieldsValue();
    const card = elements.getElement(CardNumberElement);
    const stripeToken = await stripe.createToken(card);

    if (stripeToken && stripeToken.error) { dispatch(SetState({ field: 'err', value: stripeToken.error?.message })); }
    // else if (!userData?.address) { dispatch(SetState({ field: 'err', value: 'Please Enter Address Details' })); }
    // else if (!userData?.cardName) { dispatch(SetState({ field: 'err', value: 'Please Enter Your FullName' })); }
    else {
      if (stripeToken?.token) {
        const token = stripeToken.token;

        const { name, address_city, address_country, address_state, address_zip, address_line1, address_line2 } = userData;
        const details = {
          name, address_city, address_country, address_state, address_zip, address_line1, address_line2
        }

        dispatch(AddCustomerCards({ token ,details}))

      }
    }
  }




  const ExpiryOptions = {
    showIcon: true,
    placeholder: '123',
    classes: {
      base: 'stripeElement',
      invalid: 'error',
    },
    style: {
      fontWeight: '500',
      base: {
        '::placeholder': {
          color: '#bfbfbf', // Set the color for the placeholder text
        },
      },
    },
  };
  
  const CardNumberOptions = {
    showIcon: true,
    placeholder: '0000 0000 0000 0000',
    classes: {
      base: 'stripeElement',
      invalid: 'error',
    },
    style: {
      fontWeight: '500',
      base: {
        width: '200px',
        border:'1px solid red',
        '::placeholder': {
          color: '#bfbfbf', // Set the color for the placeholder text
        },
        // your other styles
      },
    },
  };
  
  const ExNumberOptions = {
    showIcon: true,
    placeholder: 'MM/YY',
    classes: {
      base: 'stripeElement',
      invalid: 'error',
      
    },
    style: {
      fontWeight: '500',
      base: {
        '::placeholder': {
          color: '#bfbfbf', // Set the color for the placeholder text
        },
      },
    },
  };
  
  
  return (
    <EditProfileWrapper>
      <div className="header-wrapper">
        <h1 className="mb-0">Payment Method</h1>
        <div className="divider" />
      </div>
      <div className="add-card-wrapper">
        <div className="content d-flex justify-content-between">
          <p className="mb-0">Update your payment method.</p>
        </div>


        {loading?<div className="loading-center"><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads" /></div>:<Row className="card-row-wrapper" gutter={30}>

          {cards?.map((card, index) => {
            return < >
              <Col md={8} key={card?.id}>
                <div className="card-item-wrapper">
                  <div className="card-item">
                    <div className="card-header d-flex justify-content-between align-items-center">
                      <p className="mb-0">Card holder name</p>
                      <img src={Vise} />
                    </div>
                    <p className="mb-0 card-holder-name">{card?.name || 'N/A'}</p>
                    <p className="mb-0 card-num-wrapper">Card Number</p>
                    <p className="mb-0 card-num">xxxx - xxxx - xxxx - {card?.last4 || 'N/A'}</p>
                    <p className="mb-0 card-expire">Exp.</p>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0 card-expired-date">{card?.exp_month || 'N/A'} / {card?.exp_year || 'N/A'}</p>
                      {index === 0 && <Button className="defult-card-btnDefault Card">
                        Default Card
                      </Button>}
                    </div>
                  </div>
                  <div className="card-fotter-wrapper d-flex justify-content-between align-items-center">
                    <Button onClick={() => {
                      setSelectedCard(card);
                      setEditCard(true);
                    }} className="defult-card-btnDefault Card">
                      Edit Card
                    </Button>

                   {cards.length > 1 &&  <Button
                      onClick={() => {
                        setRemoveCard(true)
                        setSelectedCard(card)
                      }}
                      className="defult-card-btnDefault Card"
                    >
                      Remove Card
                    </Button>}
                  </div>
                </div>

                {/* //delete */}

              </Col>

            </>

          })}

          <EditModal
            handleForm={handleForm}
            setEditCard={setEditCard}
            card={selectedCard}
            form={form}
            editCard={editCard}
            loading={loading}
          />
          <Modal
            visible={removeCard}
            footer={null}
            type="primary"
            width={440}
            centered
            closable={false}
            onCancel={() => setRemoveCard(false)}
          >
            <div className="delete-card-content-info">
              <FaRegTimesCircle />
              <h6>Are you sure you want to remove this card?</h6>
              <p>This will remove card from the system.</p>
            </div>

            <footer className="add-card-modal-footer delete-modal">
              <Button onClick={() => setRemoveCard(false)}>Cancel</Button>
              <Button on onClick={() => {
                const card_id = selectedCard?.id;
                dispatch(deleteCustomerCards({ card_id }))

              }}>Confirm</Button>
            </footer>
          </Modal>



          {cards && [...Array(cards?.length >= 3 ? 0 : 1)].map(() => {
            return <>
              <Col md={8}>
                <div
                  onClick={() => setAddCard(true)}
                  className="card-item-wrapper add-new-card-wrapper"
                >
                  <img className="add-card-plus-icon" src={PlusIcon} />
                  <p className="add-card-text mb-0">Add Other Card</p>
                </div>
                <Modal
                  visible={addCard}
                  title="Add Credit Card"
                  className="add-card-modal"
                  footer={null}
                  width={722}
                  type="primary"
                  closable={true}
                  onCancel={() => setAddCard(false)}
                >
                  <div className="add-card-content-wrapper">
                    <p>Add Credit Card Credentials</p>
                    <Form className="add-card-modal-ui" name="addCard" form={form2} layout="vertical"
                
                    >
                      <div className="add-card-text-field-wrapper">
                        <Form.Item
                          name="name"
                          label="Cardholder Name"
                        >
                          <Input placeholder="Name Here" />
                        </Form.Item>
                      </div>
                      <div className="strip-form-item d-flex flex-column stripe-placeholder  position-relative">
                        <div className="credit-card-input-wrapper">
                          <Form.Item
                            name="Number"
                            rules={[{ message: 'Please Input Your Card Number!', required: true }]}
                            label="Card Number">
                            <CardNumberElement options={CardNumberOptions} />
                          </Form.Item>
                        </div>
                      </div>
                      {/* <div className="d-flex align-items-center stripform-item-wrapper strip-form-date-expire"> */}
                      <div className="strip-form-item d-flex stripe-placeholder flex-column">
                        <Form.Item
                          name="expirationDate"
                          rules={[{ message: 'Please Input Your Expiration Date!', required: true }]}
                          label="Expiration Date">
                          <CardExpiryElement options={ExNumberOptions} />
                        </Form.Item>
                      </div>
                      {/* </div> */}
                      <div className="strip-form-item d-flex stripe-placeholder  flex-column">
                        <Form.Item
                          name="cvc"
                          rules={[{ message: 'Please Input Your CVC!', required: true }]}
                          label="CVC">
                          <CardCvcElement options={ExpiryOptions} placeholder="123" />
                        </Form.Item>
                      </div>
                     <div className="address-footer-overlay">
                     <p className="address-title">Address</p>
                      <div className="address-items-wrapper">
                      <div className="add-card-text-field-wrapper">
                        <Form.Item
                          name="address_line1"
                          label="Address"
                        >
                          <Input placeholder="Address Here" />
                        </Form.Item>
                      </div>
                      <div className="add-card-text-field-wrapper">
                        <Form.Item
                          name="address_line2"
                          label="Address Line 2"
                        >
                          <Input placeholder="Address Here" />
                        </Form.Item>
                      </div>
                      <div className="add-card-text-field-wrapper" >
                        <Form.Item
                          name="address_country"
                          label="Country"
                        >
                          <Input placeholder="Country" />
                        </Form.Item>
                      </div>
                      <div className="add-card-text-field-wrapper">
                        <Form.Item
                          name="address_city"
                          label="City"
                        >
                          <Input placeholder="City" />

                        </Form.Item>
                      </div>
                      <div className="add-card-text-field-wrapper">
                        <Form.Item
                          name="address_state"
                          label="State/Province"
                        >
                          <Input placeholder="State" />

                        </Form.Item>
                      </div>
                      <div className="add-card-text-field-wrapper">
                        <Form.Item
                          name="address_zip"
                          label="Zip/Postcode"
                        >
                          <Input placeholder="00000" />
                        </Form.Item>
                      </div>
                      </div>
                      <footer className="add-card-modal-footer">
                        <Button onClick={() => setAddCard(false)}>Cancel</Button>
                        <Button type={"submit"} disabled={loading} onClick={handleSaveForm} className="save-btn">{loading ? <Spin size="small" /> : 'Save'}</Button>

                      </footer>
                     </div>
                    </Form>

                  </div>
                </Modal>
              </Col>
            </>
          })}

        </Row>}
      </div>
    </EditProfileWrapper>
  );
};

const EditProfile = ({ type }) => (
  <Elements stripe={stripePromise}>
    <CardsForm />
  </Elements>
);

export default EditProfile;
