
import React, { useState } from "react";
import { Link } from 'react-router-dom';
import {
  Space,
  Tooltip,
  DatePicker
} from "antd";

import moment from 'moment';
import propTypes from "prop-types";

import Facebook from "../../../static/img/facebook.svg";
import Messenger from "../../../static/img/messenger.svg";
import Edit from "../../../static/img/edit.svg";
import NoUser from "../../../static/img/no-user.png";

import FeatherIcon from "feather-icons-react";
import { BiEdit } from "react-icons/bi";
import { BiPencil } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi"

import { Button } from "../../../components/buttons/buttons";
import { Modal } from "../../../components/modals/antd-modals";
import { Dropdown } from '../../../components/dropdown/dropdown';

const KanbanBoardItem = ({
  leadData,
  setLead,
  setLeadUpdated,
  onCalenderDateChange,
  setEditLeadModal,
  changeCardPosition,
  setDeleteLeadModal,
  stage,
}) => {
  const { name, _id: leadId , image } = leadData;
  const [openCardDropDown, setOpenCardDropDown] = useState(false);

  const handleOpenCardDropDown = () => {
    setOpenCardDropDown(!openCardDropDown);
  }

  const showLeadDate = (date) => {
    return moment(date).format('D MMM, YYYY');
  }

  const showNotes = (str) => {
    if (str) {
      if (str.length < 20) return str;
      const maxLength = 40;
      const title = str.substr(0, maxLength);
      return `${title}.....`;
    } else return "";
  }

  const leadDateStyle = (value) => {
    const date = moment(value);
    const today = moment();

    if (date.isSame(today, 'day')) return { color: '#45b6fe' }
    else if (date.diff(today) < 0) return { color: '#FF0000' }
    else if (date.diff(today) > 0) return { color: ' #90EE90' }
  }

  const handleImageError = (image) => {
    if (document.querySelector(`img[src="${image}"]`))
      document.querySelector(`img[src="${image}"]`).src = NoUser;
  };
  
  return (
    <div className="title-wrapper">
      <div className={"card-header"}>
        <h4 className="sDash_kanvan-task__title">
          <div className="pipeline-card-ui-wrapper">
            <img
              className="user-img"
              src={image}
              onError={() => { handleImageError(image) }}
              alt="" />
            <h1>
              {name}
            </h1>
          </div>
          <div className="sDash_kanvan-task__title d-flex justify-content-between">
            <div className="d-flex justify-content-start">
              <DatePicker
                className="datepicker-custom"
                value={moment()}
                onChange={(event) => {
                  onCalenderDateChange({ event, lead: { ...leadData, leadDate: new Date(event._d)?.getTime() } })
                }}
                bordered={false}
              />
              <Dropdown
                content={
                  <>
                    <div className="cards-icon-option">
                      <HiChevronDoubleRight className='dropdown-icon-card' to='#' onClick={() => changeCardPosition({ position: 'end', lead: leadData, stageId: stage._id })} />
                      <span onClick={() => changeCardPosition({ position: 'end', lead: leadData, stageId: stage._id })}>Move To End</span>
                    </div>
                    <div className="cards-icon-option">
                      <HiChevronDoubleLeft className='dropdown-icon-card' to="#" onClick={() => changeCardPosition({ position: 'start', lead: leadData, stageId: stage._id })} />
                      <span onClick={() => changeCardPosition({ position: 'start', lead: leadData, stageId: stage._id })}>Move To Start</span>
                    </div>
                  </>
                }
                action={["click"]}
                className="wide-dropdwon kanbanCard-more"
              >

                <Link to="#" className="btn-more">
                  <FeatherIcon className="horizental-card-icon" icon="more-horizontal" onClick={handleOpenCardDropDown} size={14} style={{ transform: "rotate(90deg)" }} />
                </Link>
              </Dropdown>
            </div>
          </div>
        </h4>
        <div className="card-content-wrappper">
          <Tooltip placement="top" title={leadData.notes}>
            <span className="notes-text">{showNotes(leadData.notes)}</span>
          </Tooltip>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="date-text-picker">Date: <span className="date-wrapper" style={leadData.leadDate && leadDateStyle(leadData.leadDate)}>{leadData.leadDate ? showLeadDate(leadData.leadDate) : ''}</span></p>
          <div className="card-socail-links-wrapper">
            <a href={`https://www.facebook.com/${leadData.leadId}`} target="_blank">
              <img className="facebook-icon"
                src={Facebook}
                alt="no-social-media"
              />
            </a>
            <a href={`https://www.facebook.com/messages/t/${leadData.leadId}`} target="_blank">
              <img className="messenger-icon"
                src={Messenger}
                alt="no-social-media"
              />
            </a>
          </div>
        </div>
        <div className="hr-line"></div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div
          title=""
          className="w-100 sDash_kanvan-task__fotter d-flex justify-content-between"
        >
          <BiEdit className="icon-calender" src={Edit} onClick={() => { setLead(leadData); setLeadUpdated(leadData); setEditLeadModal(true) }} />
          <Space wrap>
            <FiTrash2 className='basket-icon' onClick={() => { setLead(leadData); setDeleteLeadModal(true) }} />
          </Space>
        </div>
      </div>
    </div>
  );
};

KanbanBoardItem.propTypes = {
  data: propTypes.object,
  showModal: propTypes.func,
  taskId: propTypes.string,
};

export default KanbanBoardItem;



