import React from "react";

import moment from 'moment';
import { useSelector } from 'react-redux';

import Loader from "../../components/loader/index";

import { Tabs, Row, Col, Tooltip, Checkbox } from "antd";

import NoData from "../../static/img/no-data.svg"

import { FiTrash2 } from "react-icons/fi";
import TabsWrapper from "./style";

const { TabPane } = Tabs;

const App = (props) => {

  const { groupsLoading, postsLoading } = useSelector(state => state.leads)
  const {
    setRemoveRecord,
    isGroupSelected,
    isPostSelected,
    setSelectedGroups,
    setSelectedPosts,
    openTab,
    onRowSelectionChange,
    setBulkAction,
    groupsList,
    setData,
    setOpenTab,
    postsList
  } = props;

  const groups = [];
  const posts = [];

  const showLeadsCount = (value) => {
    if (Number(value) == 1000) value = `${value.toString().slice(0, value.toString().length - 3)}k`;
    else if (Number(value) > 1000) value = `${value.toString().slice(0, value.toString().length - 3)}k+`;
    return `Leads: ${value}`
  }

  const handleString = (str) => {
    if (str) {
      if (str.length <= 30) return str;
      const maxLength = 30;
      const title = str.substr(0, maxLength);
      return `${title}...`;
    } else return "";
  };

  groupsList.forEach((group, index) => {
    groups.push({
      id: index,
      checkbox: <Checkbox type="checkbox"
        checked={isGroupSelected(group)}
        onChange={(event) => {
          onRowSelectionChange({
            tab: 'groups',
            checked: event.target.checked,
            record: group
          })
        }} />,
      boxtext: handleString(group.name),
      group,
      bucket: <Tooltip placement="topLeft" title="Delete group list"><FiTrash2 className="bucket-icon" /></Tooltip>,
      content: showLeadsCount(group.activeLeads),
      content_two: moment(group.createdAt).format("D MMM YYYY"),
      content_extra: "Date Added:",
    })
  })

  postsList.forEach((post, index) => {
    posts.push({
      id: index,
      checkbox: <Checkbox type="checkbox"
        checked={isPostSelected(post)}
        onChange={(event) => {
          onRowSelectionChange({
            tab: 'posts',
            checked: event.target.checked,
            record: post
          })
        }}
      />,
      boxtext: handleString(post.name),
      post,
      bucket: <Tooltip placement="topLeft" title="Delete post list"><FiTrash2 className="bucket-icon" /></Tooltip>,
      content: "Date Added:",
      content_two: moment(post.createdAt).format("D MMM YYYY"),
      content_extra: showLeadsCount(post.activeLeads),
    })
  })

  return (
    <TabsWrapper>
      <Tabs onChange={(event) => { setSelectedGroups([]); setSelectedPosts([]); setOpenTab(Number(event) === 1 ? 'groups' : 'posts'); }} defaultActiveKey={openTab === 'posts' ? '2' : '1'}>
        <TabPane tab="Groups" key="1">
          <div className="list-page-cards-wrapper">
            <div className="container">
              {
                groups.length ? <Row gutter={[27, 27]}>
                  {groups.map(
                    ({
                      checkbox,
                      boxtext,
                      content,
                      group,
                      content_extra,
                      content_two,
                      bucket,
                      id,
                    }) => {
                      return (
                        <Col key={id} md={8}>
                          <div className="list-card-wrapper">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex card-input d-flex alignitems-baseline">
                                <span>{checkbox}</span>
                                <Tooltip placement="topLeft" title={boxtext}><span className="box-text-wrapper-tabs">{boxtext}</span></Tooltip>
                              </div>
                              <div className="d-flex flex-column card-footer-wrapper">
                                <span className="user-info header-bage">{content}</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between user-info-list posts-card-wrapper align-items-center">
                              <div className="d-flex align-items-center card-footer-wrapper">
                                <span className="user-info mr-1">{content_extra}</span>
                                <span>{content_two}</span>
                              </div>
                              <div className="d-flex cards-icons">
                                <span onClick={() => {
                                  setData(group);
                                  setBulkAction(false);
                                  setOpenTab('groups');
                                  setRemoveRecord(true)
                                }}>
                                  {bucket}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    }
                  )}

                </Row> :
                  <div className="no-data-here-wrapper group-and-post-no-data">
                    {groupsLoading ? <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads" /></div> :
                      <img src={NoData} alt="No Data Here" />
                    }
                  </div>
              }
            </div>
          </div>
        </TabPane>
        <TabPane tab="Posts" key="2">
          <div className="list-page-cards-wrapper">
            <div className="container">
              {
                posts.length ? <Row gutter={[27, 27]}>
                  {posts.map(
                    ({
                      checkbox,
                      boxtext,
                      content,
                      post,
                      content_extra,
                      bucket,
                      content_two,
                      id,
                    }) => {
                      return (
                        <Col key={id} md={8}>
                          <div className="list-card-wrapper">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex card-input">
                                <span>{checkbox}</span>
                                <Tooltip placement="topLeft" title={boxtext}><span className="box-text-wrapper-tabs">{boxtext}</span></Tooltip>
                              </div>
                              <div className="d-flex flex-column card-footer-wrapper">
                                <span className="user-info header-bage">{content_extra}</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between user-info-list posts-card-wrapper align-items-center" >
                              <div className="d-flex card-footer-wrapper">
                                <span className="user-info">{content}</span>
                                <span className="date-issue">{content_two}</span>
                              </div>
                              <div className="d-flex">
                                <span onClick={() => { 
                                  setBulkAction(false);
                                  setData(post); 
                                  setOpenTab('posts'); 
                                  setRemoveRecord(true) 
                                  }}>
                                  {bucket}
                                </span>
                              </div>

                            </div>
                          </div>
                        </Col>
                      );
                    }
                  )}
                </Row> :
                  <div className="no-data-here-wrapper group-and-post-no-data">
                    {postsLoading ? <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads" /></div> :
                      <img src={NoData} alt="No Data Here" />
                    }
                  </div>
              }
            </div>
          </div>
        </TabPane>
      </Tabs>
    </TabsWrapper>
  );
};

export default App;
