/*global chrome*/

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { debounce } from "lodash";

import {
    Col,
    Row,
    Button,
    Spin,
    Input,
    Form,
    Table,
    Select,
    Tooltip,
    Modal,
    Checkbox
} from "antd";
import FeatherIcon from "feather-icons-react";

import Notification from "../ant-notification/notification";
import DeletePopUp from "../../static/img/delete-modal-red.svg";
import ResetPasswordPopup from "../../static/img/lock-round.svg";
import ResendEmailPopup from "../../static/img/mail.svg";

import Facebook from "../../static/img/facebook.svg";
import Messenger from "../../static/img/messenger.svg";

import { PageHeader } from "../../components/page-headers/page-headers";
import { Cards } from "../../components/cards/frame/cards-frame";
import SearchInput from "../../components/searchBar/input";

import Loader from "../../components/loader/index";

import { Main } from "../styled";
import { TableWrapper } from "../styled";
import { RowWrapper, FilterWrapper, UserModalWrap } from "./style";

import {
    parseData,
    userStatus,
    getRandomString,
    validateEmail,
    backgroundStatusColor
} from "../../utils/constants";

import {
    SetState,
    GetChatProUsers,
    CreateChatProUser,
    DeleteChatProUser,
    UpdateChatProUser,
    ResetPassword,
    ResendVerificationEmail
} from "../../redux/slices/admin-slice";

const { Option } = Select;
const ChatProUsers = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    const {
        users: usersList,
        total,
        err,
        success,
        loading,
        message
    } = useSelector((state) => state.admin);

    const { role } = useSelector((state) => state.auth);

    const [filter, setFilter] = useState({
        keyword: ""
    });
    const [userObj, setUserObj] = useState({ name: '', email: '', validPeriod: 30 })
    const [sort, setSort] = useState([["name", "ascending"]]);
    const [users, setUsers] = useState([]);
    const [createUserModal, setCreateUserModal] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: 0,
    });
    const [deleteUserModal, setDeleteUserModal] = useState(false);
    const [updateUserModel, setUpdateUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [updatedUserData, setUpdatedUserData] = useState(null);
    const [disableUpdateUser, setDisableUpdateUser] = useState(true);
    const [resetPasswordModal, setResetPasswordModal] = useState(false);
    const [resendEmailModal, setResendEmailModal] = useState(false);

    useEffect(() => {
        if (!role || role !== 'admin') history.push('/dashboard')
    }, [role])

    useEffect(() => {
        if (success) {
            Notification({
                type: "success",
                message: message || 'success',
            });
            dispatch(SetState({ field: "success", value: false }));
            dispatch(SetState({ field: "message", value: "" }));
        }
        if (err) {
            Notification({
                type: "error",
                message: err,
            });
            dispatch(SetState({ field: "err", value: false }));
        }
        setCreateUserModal(false);
        setUserObj({ name: '', email: '', validPeriod: 30 });
    }, [success, err]);

    useEffect(() => {
        setPagination({ ...pagination, total });
        setUsers(parseData(JSON.stringify(usersList)));
    }, [usersList])

    useEffect(() => {
        dispatch(GetChatProUsers({ filter, sort, skip: 0, limit: pagination.pageSize }));
        dispatch(SetState({ field: "err", value: "" }));
        dispatch(SetState({ field: "message", value: "" }));
        setPagination({ current: 1, pageSize: 20 });
    }, []);

    useEffect(()=>{ 
        setUpdatedUserData(selectedUser);
    },[selectedUser]);

    useEffect(() => {
        if (selectedUser && updatedUserData) {
            if (
                selectedUser.name !== updatedUserData.name ||
                selectedUser.email !== updatedUserData.email ||
                selectedUser.role !== updatedUserData.role ||
                selectedUser.status !== updatedUserData.status ||
                updatedUserData.newPassword
            ) {
                setDisableUpdateUser(false);
            } else {
                setDisableUpdateUser(true);
            }
        }
    }, [updatedUserData]);
    
    

    const handleTableChange = (newPagination, filters, sorter) => {
        const { current: newPage, pageSize: limit, total } = newPagination;
        setPagination({ current: newPage, pageSize: limit, total });
        dispatch(GetChatProUsers({ filter, sort, skip: (newPage - 1) * limit, limit }));
    };

    const handleFilterUsers = debounce(({ label, value }) => {
        setFilter({ ...filter, [label]: value });
        const { pageSize: limit } = pagination;
        dispatch(GetChatProUsers({ filter: { ...filter, [label]: value }, sort, skip: 0, limit }));
        setPagination({
            current: 1,
            pageSize: limit,
            total: 0,
        });
    }, 500);

    const handleString = (str) => {
        if (str) {
            if (str.length <= 40) return str;
            const maxLength = 40;
            const title = str.substr(0, maxLength);
            return `${title}.....`;
        } else return "";
    };

    const handleCloseModal = () => {
        setCreateUserModal(false);
        setUserObj({ name: '', email: '', validPeriod: 30 })
    }
    const checkSbumitBtnDisabled = () => {
        const { name, email, validPeriod } = userObj || {};
        if (!name || !email || !validPeriod) return true;
        else return false;
    }

    const handleCreateUser = () => {
        const { email } = userObj || {};
        if (!validateEmail(email)) {
            dispatch(SetState({ field: "err", value: "Email Is Invalid" }));
            return
        }
        const userName = email?.split('@')?.length && email?.split('@')[0];
        const password = getRandomString(16);
        const userData = { ...userObj, userName, password };
        dispatch(CreateChatProUser(userData));
        setCreateUserModal(false);
    }

    const handleUpdateUser = () => {
        const { email, name, status, role, _id} = updatedUserData || {};
        if (!validateEmail(email)) {
            dispatch(SetState({ field: "err", value: "Email Is Invalid" }));
            return
        }
        
        const userName = email?.split('@')?.length && email?.split('@')[0];

        if (!name) {
            dispatch(SetState({ field: "err", value: "Name Is Required" }));
            return
        }

        if (!status) {
            dispatch(SetState({ field: "err", value: "Status Is Required" }));
            return
        }

        if (!role) {
            dispatch(SetState({ field: "err", value: "Role Is Required" }));
            return
        }

        const dataToSend = {_id, email, name, status, role, userName};

        if (updatedUserData.newPassword) {
            dataToSend.newPassword = updatedUserData.newPassword;
        }

        dispatch(UpdateChatProUser(dataToSend));

        setUpdatedUserData(null);
        setSelectedUser(null);
        setUpdateUserModal(false);
    }

    const handleDeleteUserData = async () => {
        if (selectedUser?._id) {
            console.log("selectedUser", selectedUser._id, typeof selectedUser._id)
            dispatch(DeleteChatProUser(selectedUser._id));
        } else {
            console.log("no selectedUser", selectedUser);
        }
        setSelectedUser(null);
        setDeleteUserModal(false);
    };

    const handleResetPassword = async () => {
        if (selectedUser?._id) {
            const password = getRandomString(16);
            dispatch(ResetPassword({ _id: selectedUser._id, newPassword: password }));
        }
        setResetPasswordModal(false);
    }

    const handleResendEmail = async () => {
        if (selectedUser?._id) {
            dispatch(ResendVerificationEmail(selectedUser));
        }
        setResendEmailModal(false);
    }

    const tableProps = {
        loading,
    };

    const columns = [
        {
            title: "Profile",
            dataIndex: "profile",
            width: 100,
            render: (data) => <div>{data}</div>,
        },
        {
            title: "",
            dataIndex: "link",
            width: 100,
            render: (data) => <div>{data}</div>,
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 100,
            render: (data) => <div>{data}</div>,
        },
        {
            title: "Leads",
            dataIndex: "leads",
            width: 100,
            render: (data) => <div>{data}</div>,
        },
        {
            title: "Pipelines",
            dataIndex: "pipelines",
            width: 100,
            render: (data) => <div>{data}</div>,
        },
        {
            title: "Actions",
            dataIndex: "action",
            fixed: "right",
            width: 80
        },
    ];

    const data = [];
    users.forEach((user, index) => {
        data.push({
            key: index,
            profile: (
                <div className="d-flex user-profile gap-3 align-items-center">
                    <div className="heading-user-content-wrapper">
                        <span>{user.name}</span>
                        <div className="d-flex lead-info-user">
                            <Tooltip placement="top" title={user.email}>
                                <label>{handleString(user.email)}</label>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            ),
            'link': (
                <>
                    {user.facebookProfile?.profileId ? <div className="user-profile-link">
                        <a href={`https://www.facebook.com/${user.facebookProfile?.profileId}`} target="_blank">
                            <img width="20" height="20" src={Facebook} alt="no-link" />
                        </a>
                        <a
                            className="messanger-icon-wrap"
                            href={`https://www.facebook.com/messages/t/${user.facebookProfile?.profileId}`}
                            target="_blank"
                        >
                            <img
                                className="messanger-icon"
                                width="20"
                                height="20"
                                src={Messenger}
                                alt=""
                            />
                        </a>
                    </div> : ''}
                </>
            ),
            status: (
                <div key={index} className="d-flex status-wrapper-stucture">
                    <span className="status-wrapper status-content status Success status-color-wrap" style={{ background: backgroundStatusColor[user.status] }}>
                        {user.status}
                    </span>
                </div>
            ),
            leads: (
                <div className="d-flex user-profile gap-3 align-items-center">
                    <div className="heading-user-content-wrapper">
                        <span>{user.totalLeads}</span>
                    </div>
                </div>
            ),
            pipelines: (
                <div className="d-flex user-profile gap-3 align-items-center">
                    <div className="heading-user-content-wrapper">
                        <span>{user.totalPipelines}</span>
                    </div>
                </div>
            ),
            action: (
                <div className="table-actions">
                    <Button
                        className="btn-icon"
                        type="danger"
                        to="#"
                        shape="circle"
                        onClick={() => {
                            setSelectedUser(user);
                            setResetPasswordModal(true);
                        }}
                    >
                        <Tooltip placement="top" title={"Reset Password"}>
                            <FeatherIcon icon="refresh-ccw" size={16} />
                        </Tooltip>
                    </Button>
                    <Button
                        className="btn-icon"
                        type="info"
                        to="#"
                        shape="circle"
                        onClick={() => {
                            // setSingleUserAction(true);
                            // setLead(lead);
                            setSelectedUser(user);
                            setUpdateUserModal(true);
                        }}
                    >
                        <Tooltip placement="top" title={"Edit User"}>
                            <FeatherIcon icon="edit" size={16} />
                        </Tooltip>
                    </Button>
                    <Button
                        className="btn-icon"
                        type="danger"
                        to="#"
                        shape="circle"
                        onClick={() => {
                            setSelectedUser(user);
                            setDeleteUserModal(true);
                        }}
                    >
                        <Tooltip placement="top" title={"Delete User"}>
                            <FeatherIcon icon="trash-2" size={16} />
                        </Tooltip>
                    </Button>
                    {
                        user.status === 'Pending' && (
                            <Button
                                className="btn-icon"
                                type="danger"
                                to="#"
                                shape="circle"
                                onClick={() => {
                                    setSelectedUser(user);
                                    setResendEmailModal(true);
                                }}
                            >
                                <Tooltip placement="top" title={"Resend Verification Email"}>
                                    <FeatherIcon icon="mail" size={16} />
                                </Tooltip>
                            </Button>
                        )
                    }
                </div>
            )
        });
    });

    return (
        <>
            <PageHeader
                ghost
                title="Users"
                className={"page-header-main"}
                buttons={[
                    <FilterWrapper>
                        <div key="6" className="page-header-actions">
                            <div className="d-flex">
                                <div key={1} className="search-box">
                                    <SearchInput
                                        search
                                        className="leads-search-bar"
                                        type="text"
                                        onChange={(event) => { handleFilterUsers({ label: 'keyword', value: event.target.value }) }}
                                        name="search"
                                        placeholder="Search by Name/Email"
                                    />
                                </div>
                                <Select
                                    value={filter.status}
                                    allowClear
                                    onChange={(event) => { handleFilterUsers({ label: 'status', value: event }) }}
                                    className="relationship-dropdown-wrapper"
                                    placeholder="Status"
                                >
                                    {userStatus.map((data, index) => {
                                        return (
                                            <Option key={index} value={data.value}>
                                                {data.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                                <Button
                                    className="relationship-dropdown-wrapper"
                                    onClick={() => { setCreateUserModal(true) }}
                                >
                                    Create User
                                </Button>
                            </div>
                        </div>,
                    </FilterWrapper>
                ]}
            />
            <RowWrapper>
                <div className="d-flex justify-content-between filter-wrapper">
                    <div className="leads-counter-wrap">
                        <span>
                            Total: <span className="font-bold">{total}</span>
                        </span>
                    </div>
                </div>
            </RowWrapper>

            <Modal
                visible={createUserModal}
                title="Create New User"
                footer={null}
                type="primary"
                centered
                closable={true}
                onCancel={handleCloseModal}
            >
                <UserModalWrap>
                    <div className="user-info-wrap">
                        <label>Name:</label>
                        <Input
                            value={userObj?.name}
                            onChange={(event) => { setUserObj({ ...userObj, name: event.target.value }) }}
                            placeholder="Enter the name"
                        />
                    </div>
                    <div className="user-info-wrap">
                        <label>Email:</label>
                        <Input
                            value={userObj?.email}
                            onChange={(event) => { setUserObj({ ...userObj, email: event.target.value }) }}
                            placeholder="Enter the email"
                        />
                    </div>
                    <div className="user-info-wrap">
                        <label>Valid Period:</label>
                        <Input
                            value={userObj?.validPeriod}
                            type='number'
                            onChange={(event) => { setUserObj({ ...userObj, validPeriod: event.target.value }) }}
                            placeholder="Select the valid period"
                        />
                    </div>
                    <br />
                    <hr />
                    <div className="footer-btn-wrap">
                        <Button onClick={handleCloseModal} className="can-btn-info">
                            Cancel
                        </Button>
                        <Button loading={loading} className="submit-btn-info" type="primary" onClick={handleCreateUser} disabled={checkSbumitBtnDisabled() || loading}>
                           {loading ? <Spin size="small" /> : 'Submit'}
                        </Button>
                    </div>
                </UserModalWrap>
            </Modal>
            <Modal
                open={updateUserModel}
                title="Update User"
                footer={null}
                type="primary"
                centered
                closable={true}
                onCancel={() => setUpdateUserModal(false)}
            >
                <UserModalWrap>
                    <div className="user-info-wrap">
                        <label>Name:</label>
                        <Input
                            value={updatedUserData?.name}
                            onChange={(event) => { setUpdatedUserData({ ...updatedUserData, name: event.target.value }) }}
                            placeholder="Enter the name"
                        />
                    </div>
                    <div className="user-info-wrap">
                        <label>Email:</label>
                        <Input
                            value={updatedUserData?.email}
                            onChange={(event) => { setUpdatedUserData({ ...updatedUserData, email: event.target.value }) }}
                            placeholder="Enter the email"
                        />
                    </div>
                    <div className="user-info-wrap-container">
                        <div className="user-info-wrap">
                            <label>Status:</label>
                            <Select
                                style={{ width: '100%', border: "1px solid #e3e6ef", borderRadius: "6px" }}
                                value={updatedUserData?.status}
                                onChange={(event) => setUpdatedUserData({ ...updatedUserData, status: event })}
                                // placeholder="Select Status"
                                >
                                <Option value="Pending">Pending</Option>
                                <Option value="Verified">Verified</Option>
                                <Option value="Subscribed">Subscribed</Option>
                                <Option value="Active">Active</Option>
                            </Select>
                        </div>
                        <div className="user-info-wrap">
                        <label>Role:</label>
                        <Select
                            style={{ width: '100%', border: "1px solid #e3e6ef", borderRadius: "6px" }}
                            value={updatedUserData?.role}
                            onChange={(event) => setUpdatedUserData({ ...updatedUserData, role: event })}
                            >
                            <Option value="user">User</Option>
                            <Option value="admin">Admin</Option>
                        </Select>
                    </div>
                    </div>
                    <div className="user-info-wrap">
                        <label>New Password:</label>
                        <Input
                            type="password"
                            value={updatedUserData?.newPassword || ""}
                            onChange={(event) => { setUpdatedUserData({ ...updatedUserData, newPassword: event.target.value }) }}
                            placeholder="Enter new password"
                        />
                    </div>
                    <br />
                    <hr />
                    <div className="footer-btn-wrap">
                        <Button onClick={() => setUpdateUserModal(false)} className="can-btn-info">
                            Cancel
                        </Button>
                        <Button loading={loading} disabled={disableUpdateUser} className="submit-btn-info" type="primary" onClick={handleUpdateUser}>
                           {loading ? <Spin size="small" /> : 'Submit'}
                        </Button>
                    </div>
                </UserModalWrap>
            </Modal>
            
            <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                open={resetPasswordModal}
                width={440}
                closable={false}
            >
                <div className="d-flex justify-content-center align-items-center flex-column unfriend-heading-modal">
                    <img className="pipeline-bulk-action" src={ResetPasswordPopup} />
                    <h2 className="mb-3 text-center color-dark ">
                        Are you sure you want to reset password for this user
                    </h2>
                    <p className="text-center fs-14 color-gray">
                        Resetting password will generate a new password for this user and send it to their email.
                    </p>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                    <Button
                        size="default"
                        outlined
                        type="white"
                        onClick={() => {
                            setResetPasswordModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => await handleResetPassword()}
                        size="default"
                        type="primary"
                        // disabled={disabled}
                        className="ml-2 delete-btn-modal"
                    >
                        {loading ? <Spin size="small" /> : "Reset"}
                    </Button>
                </div>
            </Modal>

            <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                open={resendEmailModal}
                width={440}
                closable={false}
            >
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <img className="pipeline-bulk-action" src={ResendEmailPopup} />
                    <h2 className="mb-3 text-center color-dark ">
                        Resend Verification Email
                    </h2>
                    <p className="text-center fs-14 color-gray">
                        The user will receive a new verification email to complete their account verification process.
                    </p>

                    <br>
                    </br>
                    <Checkbox
                        className=""
                        checked={selectedUser?.betaUser || false}
                        onChange={(event) => {
                            setSelectedUser({ ...selectedUser, betaUser: event.target.checked })
                        }}
                        
                    >
                        Is this user part of the Beta program?
                    </Checkbox>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                    <Button
                        size="default"
                        outlined
                        type="white"
                        onClick={() => {
                            setResendEmailModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => await handleResendEmail()}
                        size="default"
                        type="primary"
                        // disabled={disabled}
                        className="ml-2 delete-btn-modal"
                    >
                        {loading ? <Spin size="small" /> : "Resend"}
                    </Button>
                </div>
            </Modal>

            <Modal
                className="addEvent-modal crm-basic-modal"
                footer={null}
                type="primary"
                centered
                open={deleteUserModal}
                width={440}
                closable={false}
            >
                <div className="d-flex justify-content-center align-items-center flex-column unfriend-heading-modal">
                    <img className="pipeline-bulk-action" src={DeletePopUp} />
                    <h2 className="mb-3 text-center color-dark ">
                        Are you sure you want to delete this user
                    </h2>
                    <p className="text-center fs-14 color-gray">
                        Deleting this user will delete him from this
                        application completely. Are you sure?
                    </p>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                    <Button
                        size="default"
                        outlined
                        type="white"
                        onClick={() => {
                            setDeleteUserModal(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={async () => await handleDeleteUserData()}
                        size="default"
                        type="primary"
                        // disabled={disabled}
                        className="ml-2 delete-btn-modal"
                    >
                        {loading ? <Spin size="small" /> : "Delete"}
                    </Button>
                </div>
            </Modal>

            <Main>
                <Row gutter={25}>
                    <Col lg={24} xs={24}>
                        <Cards headless>
                            {<TableWrapper>
                                <Table
                                    sticky
                                    size="small"
                                    scroll={{ x: 1200 }}
                                    dataSource={data}
                                    pagination={pagination}
                                    columns={columns}
                                    onChange={handleTableChange}
                                    {...tableProps}
                                    className="leads-custom-table"
                                    loading={{
                                        indicator: <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads" /></div>,
                                        spinning: loading
                                    }}
                                />
                                
                            </TableWrapper>}
                        </Cards>
                    </Col>
                </Row>
            </Main>
        </>
    );
};

export default ChatProUsers;
