/*global chrome*/

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const GetBlockedLeads = createAsyncThunk(
  'leads/getBlockedLeads',
  async (data, thunkAPI) => {
    try {
      const response = await axios.get('/leads/blocked-leads', { params: data });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const UnblockLeads = createAsyncThunk(
  'leads/unBlockLeads',
  async (data, thunkAPI) => {
    const { leadIds } = data;
    try {
      const response = await axios.put('/leads/unblock-leads', { leadIds });
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

const user = createSlice({
  name: 'blockedLeads',
  initialState: {
    success: false,
    total: 0,
    leads: [],
    leadsLoading: false,
    message: '',
    loading: false,
    err: '',
  },
  reducers: {
    SetState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    ClearState() {
      return {
        success: false,
        total: 0,
        leads: [],
        message: '',
        loading: false,
        err: '',
      };
    }
  },
  extraReducers: {
    [GetBlockedLeads.pending]: (state, action) => ({
      ...state,
      leadsLoading: true
    }),
    [GetBlockedLeads.fulfilled]: (state, action) => ({
      ...state,
      leadsLoading: false,
      leads: action.payload.data,
      total: action.payload.total
    }),
    [GetBlockedLeads.rejected]: (state, action) => ({
      ...state,
      leadsLoading: false,
      err: action.payload.err?.error
    }),
    [UnblockLeads.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [UnblockLeads.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      message: action.payload.message,
      success: true
    }),
    [UnblockLeads.rejected]: (state, action) => ({
      ...state,
      success: false,
      loading: false,
      err: action.payload.err?.error
    })
  }
});

const { reducer, actions } = user;

export const {
  SetState,
  ClearState
} = actions;

export default reducer;
