import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
    Form,
    Input,
    Button,
    Spin,
    Row,
    Col
} from 'antd';

import Heading from '../../../../components/heading/heading';
import LeftSide from "./authSideGrid"
import Notification from '../../../ant-notification/notification'

import { AuthWrapper } from './style';

import { SetState, GetUser, CreatePassword } from '../../../../redux/slices/auth-slice';

const AuthSetPassword = () => {

    const [form] = Form.useForm();

    const history = useHistory();
    const dispatch = useDispatch();
    const { loading, success, err, email, name } = useSelector(state => state.auth);

    useEffect(() => {
        const newParam = new URLSearchParams(history.location.search);
        const userId = newParam.get('userId');
        dispatch(GetUser({ userId }));
    }, [])

    useEffect(() => {
        if (err) {
            Notification({
                type: 'error',
                message: err
            });
            dispatch(SetState({ field: 'err', value: '' }));
        }
        if (success) {
            dispatch(SetState({ field: 'success', value: false }));
            history.push('/auth/sign-in');
        }
    }, [err, success])

    const handleSubmit = (data) => {
        const { password, confirmPassword } = data;
        if (password !== confirmPassword) {
            return dispatch(SetState({ field: 'err', value: 'Passwords Are Not Same' }))
        }
        dispatch(CreatePassword({ email, password }));
    };

    return (
        <AuthWrapper>
            <Row className='d-flex'>
                <Col md={10} className='left-side-img'>
                    <LeftSide />
                </Col>
                <Col md={14}>
                <div className='right-side-content d-flex flex-column justify-content-center align-item-center'>
                    <div className="auth-contents reset-password">
                        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
                            <Heading as="h3">
                                Set Password
                            </Heading>
                            <span className='user-wrapper'>Hello, </span><span className='user-titile-id'>{name}</span>
                            <br />
                            <p>Thank you for choosing ChatPro CRM. Once you have entered the password, click submit to save the information.</p>
                            {email && <Form.Item
                                rules={[{ message: 'Please Input Your Email!', required: true }]}
                                label=" EEmail Address"
                            >
                                <Input disabled={true} value={email} placeholder="Email" />
                            </Form.Item>}
                            <Form.Item
                                name="password"
                                rules={[{ message: 'Please Input Your Password!', required: true }]}
                                label="Password"
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                rules={[{ message: 'Please Confirm Your Password!', required: true }]}
                                label="Confirm Password"
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                            <Form.Item>
                                <Button className="btn-signin" htmlType="submit" type="primary" size="large">
                                    {loading ? <Spin size="small" /> : 'Submit'}
                                </Button>
                            </Form.Item>
                        </Form>

                    </div>
                </div>
                </Col>
            </Row>
        </AuthWrapper>
    );
};

export default AuthSetPassword;