import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Form, Input, Button, Row, Col } from "antd";

import Heading from "../../../../components/heading/heading";
import LeftSide from "./authSideGrid";

import Notification from "../../../ant-notification/notification";

import { AuthWrapper } from "./style";
import { ForgotPassword, SetState } from "../../../../redux/slices/auth-slice";

import {
  validateEmail
} from "../../../../utils/constants";

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [emailValidated, setValidateEmail] = useState(false);
  const { loading, err, success } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  useEffect(() => {
    if (err) {
      Notification({
        type: "error",
        message: err,
      });
    }
    dispatch(SetState({ field: "err", value: "" }));
  }, [err]);

  useEffect(() => {
    if (success) {
      Notification({
        type: "success",
        message: "Email Has Been Sent",
      });
      dispatch(SetState({ field: "success", value: false }));
      history.push("/auth/reset-password-email");
    }
  }, [success]);

  const handleSubmit = (data) => {
    const { email } = data;
    dispatch(SetState({ field: "resetEmail", value: email }));
    dispatch(ForgotPassword({ email }));
  };

  const handleValidateFormValues = () => {
    const email = form.getFieldValue('email');
    if (validateEmail(email)) setValidateEmail(true);
    else setValidateEmail(false);
  }

  const handleSignInRoute = () => {
    history.push("/auth/sign-in");
  };

  return (
    <AuthWrapper>
      <Row className="d-flex">
        <Col md={10} className="left-side-img">
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className="right-side-content d-flex flex-column justify-content-center align-item-center">
            <div className="auth-contents">
              <Form
                className="forget-form" 
                onValuesChange={handleValidateFormValues}
                name="login"
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
              >
                <Heading className="heading-forget" as="h3">
                  Forgot Password?
                </Heading>
                <span className="fs-14 color-gray">
                  Enter the email address you used when you joined and we'll send
                  you instructions to reset your password.{" "}
                </span>
                <Form.Item
                  className="label-heading"
                  name="email"
                  rules={[
                    { message: "Please type your email!", required: true },
                  ]}
                  label="Email Address">
                  <Input placeholder="name@example.com" />
                </Form.Item>
                <Form.Item>
                  <Button
                    disabled={!emailValidated}
                    className="btn-signin"
                    htmlType="submit"
                    type="primary"
                    size="large">
                    {loading ? "Loading..." : "Send Reset Instructions"}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-center auth-notice">
                    Return to
                    <span>
                      <Link
                        to="#"
                        onClick={handleSignInRoute}
                        className="color-secondary"
                      >
                        Sign in
                      </Link>
                    </span>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  );
};

export default SignIn;
