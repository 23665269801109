import styled from "styled-components";

const TableWrapper = styled.div`
  height: calc(100vh - 83px);
  overflow:hidden ;
  .heading {
    font-weight: 700;
    line-height: 29px;
    text-align: left;
    margin-left: 3px;
    h3 {
      font-size: 24px;
      margin: 0px;
      font-weight:700 ;
    }
  }
  .d-flex.justify-content-between.list-card-wrapper-item {
    align-items: baseline;
}
  .content-header.page-haeder-main {
    padding: 24px 0 16px;
}
span.list-checkbox-text {
    width: 190px;
    overflow: hidden;
    text-overflow: ellipsis;
}
  .ant-modal-header .ant-modal-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}
  .ant-modal.sc-jAaTju.tVeER.Create-New-List .ant-modal-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}
  .list-action-btn{
    margin-left:auto ;
    .page-header-actions{
      button{
        margin-bottom:0px;
        /* background-color:#FFFFFF; */
      }
    }
  }
.list-disable-button {
    background: #fff !important;
    color: #9BA8B7 !important;
    border: 1px solid #9BA8B7 !important;
    cursor: not-allowed;
    &:hover{
      background: #fff !important;
    color: #9BA8B7 !important;
    border: 1px solid #9BA8B7 !important;
    cursor: not-allowed;
    }
}
  .search-box {
    position: relative;
    box-shadow: 0 5px 5px rgba(#9299b8, 0.3);
    margin-left: 43px;
    svg{
      color:#5f63f3;
    }
    input {
      border: 0 none;
      height: 36px;
      width: 252px;
      font-size: 12px;
      padding: 0 19px 0 12px;
      min-width: 152px;
      border-radius: 27px;
      &::placeholder {
        color: #adb4d2;
      }
      &:focus {
        outline: none;
      }
    }
  }

  .block-icons {
    color: #adb4d2;
  }
  .actions-icons {
    color: #adb4d2;
    margin-right: 15px;
  }
  .page-header-list-button {
    margin-left: auto;
  }
  span.ant-dropdown-trigger {
    display: none;
  }
  .ant-spin-container {
    background: white;
    padding-bottom: 10px;
    border-radius: 6px;
  }
  .ant-modal .ant-modal-content {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 391px;
    text-align: center;
    flex-direction: column;
  }
  .ant-layout.ant-layout-has-sider {
    overflow: scroll;
  }
  .checkbox-wrapper span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9BA8B7;
}
.d-flex.cards-icons {
    align-items: center;
    grid-gap: 8px;
}
.ant-select.custom-drop-down.ant-select-single.ant-select-allow-clear.ant-select-show-arrow .dropdown-option {
  width: 100%;
}
.add-new-list {
    padding: 0 12.5px;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    background-color:#FFFFFF;
}
  .list-card-wrapper {
    background: #FFFFFF;
    border-radius: 8px;
    padding: 21px 25px 24px 21px;
    /* margin-top:-10px; */
    .note-icon {
    color: #5F63F2;
    font-size: 17px;
    cursor:pointer
}
    .eye-icon {
    color: #5F63F2;
    font-size: 17px;
    margin-right: 12px;
    cursor:pointer
}
.bucket-icon{
    color: #5F63F2;
    font-size:17px ;
    cursor:pointer;
}
 
.user-info-list {
    padding: 10px 0px 0 3px;
    .user-info{
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
    color: #9BA8B7;
    text-overflow: ellipsis;
    width: 100px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    }
    span{
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #4B5664;

    }
}

    .card-input{
margin:0px;
/* grid-gap:14px; */
grid-gap:4px;
     span {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color:#4B5664;
}
.list-checkbox-text{
  height:30px ;
  width: 167px !important;
}
input{
    width:16px;
    height:16px;
}
    }
    label.ant-checkbox-wrapper{
      top:-3px;
    }
}
.checkbox{
  margin-left:24px;
  span{
    margin-right:0px ;
  }
}
.card-footer-wrapper {
    margin-right:36px;
    span:nth-child(2) {
    padding-top: 4px;
    margin-left: -2px;
}
}
.list-page-cards-wrapper{
  padding: 20px 0px;
  margin-right:0px ;
  overflow: auto;
  height: calc(100vh - 133px);
  margin-left:-12px ;
  margin-right:-12px ;
}
.edit-icon {
    svg{
      font-size:17px;
    }
}

`;

export { TableWrapper };
