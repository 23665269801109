import React, { useEffect } from 'react'
import { Row, Col, Modal, Select, Form, Spin } from "antd";
import Input from "../input/input";
import { countries, cities, states } from '../../utils/constants';
import { AiOutlineLock } from "react-icons/ai";
import { BsCreditCard } from "react-icons/bs";
import { Button } from "../buttons/buttons"
function EditModal({ setEditCard, handleForm, card, form, editCard, loading }) {

  useEffect(() => {

    let month = card?.exp_month;
    let year = String(card?.exp_year)?.slice(-2);
    if (Number(month) <= 9) {
      month = formatNumberWithZero(month);
    }
    const defaultValues = {

      expiration: month + '/' + year,
      name: card?.name,
      address_country: card?.address_country,
      address_line2: card?.address_line2,
      address_state: card?.address_state,
      address_city: card?.address_city,
      address_line1: card?.address_line1,
      cvc: "xxx",
      address_zip: card?.address_zip,
    }
    form.setFieldsValue(defaultValues)
  }, [card, form])


  const isValidDate = (input) => {
    const datePattern = /^(0[1-9]|1[0-2])\/\d{2}$/;

    if (!datePattern.test(input)) {
      return false;
    }

    // Validate month not greater than 12
    const [month, year] = input?.split('/');
    const currentYear = new Date().getFullYear().toString().slice(-2);

    if (parseInt(month, 10) > 12) {
      return false;
    }

    // Validate year not less than current year
    if (parseInt(year, 10) <= parseInt(currentYear, 10)) {
      return false;
    }

    return true;
  }
  const validateDate = (_, value, callback) => {
    const validDate = isValidDate(value);
    if (validDate) {
      callback(); // Validation succeeded, call the callback with no arguments
    } else {
      callback('Input Valid Date MM/YY '); // Validation failed, call the callback with an error message
    }
  };

  const { Option } = Select;

  function formatNumberWithZero(number) {
    return number.toString().padStart(2, '0');
  }


  return (
    <Modal
      visible={editCard}
      title="Edit Credit Card"
      className="add-card-modal"
      footer={null}
      width={722}
      type="primary"
      closable={true}
      onCancel={() => setEditCard(false)}
    >
      <div className="add-card-content-wrapper">
        <p>Add Credit Card Credentials</p>
        <Form
          name="updateCard"
          form={form}
          layout="vertical"
          initialValues={{
            expiration: card?.exp_month + '/' + card?.exp_year,
            name: card?.name,
            address_country: card?.address_country,
            address_line2: card?.address_line2,
            address_state: card?.address_state,
            address_city: card?.address_city,
            address_line1: card?.address_line1,
            address_zip: card?.address_zip,
            cvc: "xxx"
          }}
        >
          <div className="add-card-input-wrapper">
            <div className="add-card-text-field-wrapper  edit-card-condition-items">
              <Form.Item
                label="name"
                name="name"
              >
                <Input placeholder="Name Here"
                />
              </Form.Item>
            </div>
            <div className="add-card-text-field-wrapper  edit-card-condition-items payment-card-info">
              <Form.Item
                label="Card Number"
              >
                <Input disabled placeholder="0000 0000 0000 0000"
                  value={`xxxx - xxxx - xxxx - ${card?.last4 || 'N/A'}`}
                />
                <AiOutlineLock className="lock-icon" />
              </Form.Item>
              <BsCreditCard />
            </div>
            <div className="d-flex align-items-center stripform-item-wrapper strip-form-date-expire">
              <div className="strip-form-item expiry-date-item d-flex flex-column">
                <Form.Item
                  name="expiration"
                  rules={[
                    {
                      validator: validateDate
                    }
                  ]}
                  label="Expiration Date">
                  <Input
                    placeholder="MM/YY"
                  //  onChange={(e) => {
                  //   console.log({data:isValidDate(e.target.value)})
                  //     setExpirationDate(e.target.value)
                  //   }}
                  // value={expiration}
                  />
                </Form.Item>

              </div>
            </div>
            <div className="add-card-text-field-wrapper  edit-card-condition-items">
              <Form.Item
                name="cvc"
                label="CVC"
              >
                <Input disabled placeholder="xxx" defaultValues={"xxx"} />
                <AiOutlineLock className="lock-icon" />
              </Form.Item>
            </div>
            <p className="address-title">Address</p>
            <div className="add-card-text-field-wrapper  edit-card-condition-items">
              <Form.Item
                label="Address"
                name="address_line1"
              >
                <Input placeholder="Address Here" />
              </Form.Item>
            </div>
            <div className="add-card-text-field-wrapper  edit-card-condition-items">
              <Form.Item
                label="Address Line 2"
                name="address_line2"
              >
                <Input placeholder="Address Here"
                />
              </Form.Item>
            </div>
            <div className="add-card-text-field-wrapper  edit-card-condition-items" >
              <Form.Item
                label="Country"
                name="address_country"
              >
                <Input placeholder="Country" />

              </Form.Item>
            </div>
            <div className="add-card-text-field-wrapper  edit-card-condition-items">
              <Form.Item
                label="City"
                name="address_city"
              >
                <Input placeholder="City" />
              </Form.Item>
            </div>
            <div className="add-card-text-field-wrapper  edit-card-condition-items">
              <Form.Item
                label="State/Province"
                name="address_state"
              >
                <Input placeholder="State" />
              </Form.Item>
            </div>
            <div className="add-card-text-field-wrapper edit-card-condition-items">
              <Form.Item
                label="Zip/Postcode"
                name='address_zip'
              >
                <Input placeholder="00000"
                />
              </Form.Item>
            </div>
          </div>
          <footer className="add-card-modal-footer">
            <Button onClick={() => setEditCard(false)}>Cancel</Button>
            <Button type={"submit"} disabled={loading} onClick={() => handleForm(card?.id)} className="save-btn">{loading ? <Spin size="small" /> : 'Update'}</Button>
          </footer>
        </Form>

      </div>
    </Modal>
  )
}

export default EditModal;
