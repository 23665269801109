/*global chrome*/

import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Checkbox } from "antd"

import LeftSide from "./authSideGrid"
import Facebook from "../../../../static/img/facebook.svg"
import Notification from "../../../ant-notification/notification";
import { ReactComponent as Logo } from '../../../../static/img/logo-text.svg';

import { SetState, AttachFacebookProfile, SendFacebookProfileData } from "../../../../redux/slices/auth-slice";

import { OnBoardingWrapper } from './style';

const OnboardingFlowStep1 = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const { auth: { token, err, success, attachProfile } } = useSelector((state) => state);
  const cookies = new Cookies();
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);
  const [facebookId, setFacebookId] = useState('');
  const [facebookImage, setFacebookImage] = useState('');
  const [facebookProfileName, setFacebookProfileName] = useState('');


  useEffect(() => {
    if (attachProfile) {
      const { name, image, facebookId } = attachProfile;
      setFacebookProfileName(name);
      setFacebookImage(image);
      setFacebookId(facebookId)
    }
  }, [attachProfile]);

  useEffect(() => {
    if (err) {
      Notification({
        type: "error",
        message: err,
      });
      dispatch(SetState({ field: "err", value: false }));
    }
    if (success) {
      dispatch(SetState({ field: 'status', value: 'Active' }));
      dispatch(SetState({ field: "success", value: false }));

      const current = new Date();
      const nextYear = new Date();
      nextYear.setFullYear(current.getFullYear() + 1);
      cookies.set('loggedIn', true, { path: '/', expires: nextYear });
      cookies.set('token', token, { path: '/', expires: nextYear });
      history.push('/dashboard');
    }
  }, [err, success]);

  const handleAttachAccount = async () => {
    const facebookProfileData = {
      profileId: facebookId,
      name: facebookProfileName,
      image: facebookImage
    }

    dispatch(SetState({ field: "facebookProfileData", value: facebookProfileData }));
    dispatch(AttachFacebookProfile({ facebookProfileData }))
    dispatch(SendFacebookProfileData({ name: facebookProfileName, image: facebookImage }))
  }

  return (
    <OnBoardingWrapper>
      <Row>
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>
        <Col md={14}>
          <div className='three-step-wrapper'>
            <div className='three-step d-flex'>
              <img src={Facebook} />
              <h2 className='m-0'>Link your Facebook account to <span className='logo-chat'><Logo /></span></h2>
            </div>
            <div className='step-three-user-info d-flex justify-content-center align-items-center flex-column'>
              <img className='profile-img-wrapper' width={70} height={70} src={facebookImage} />
              <h4>{facebookProfileName}</h4>
            </div>
            <div className='step-three-order-list'>
              <h3>Are you sure to Link this account?</h3>
              <ul>
                <li>All leads will be synced from that facebook account that you are including.</li>
                <li>No other facebook account will be entertained, if you want to sync leads from any other account, you will have to onboard it first.</li>
                <li>We will be including background jobs which will be syncing leads in the background, you will have the option to disable the background jobs in that case.</li>
              </ul>
              <div className='terms-wrapper d-flex align-items-center'>
                <Checkbox checked={agreeWithTerms} onChange={() => { setAgreeWithTerms(!agreeWithTerms) }} />
                <span>By linking an account you agree to our <a className='link-wrapper' href='#'>Terms</a> and <a className='link-wrapper' href='#'>Privacy Policy</a>.</span>
              </div>
            </div>
            <div className='socail-account-wrapper justify-content-end align-items-center d-flex'>
              <Button disabled={!agreeWithTerms} onClick={handleAttachAccount} outlined={'true'} className={agreeWithTerms ? 'confirm-link-btn' : 'cancel-link-btn'}>Confirm</Button>
            </div>
          </div>
        </Col>
      </Row>

    </OnBoardingWrapper>
  );
}
export default OnboardingFlowStep1;
