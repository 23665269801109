import React, { useEffect } from "react";

import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from "antd";
import { Link, useHistory } from "react-router-dom"
import { AuthWrapper } from "./style";
import Verfication from "../../../../static/img/email-verification.svg"
import LeftSide from "./authSideGrid";

import { SetState, ResendEmail } from '../../../../redux/slices/auth-slice';

import Notification from "../../../ant-notification/notification";

const Verification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { err, success, message } = useSelector(state => state.auth);

  useEffect(() => {
    if (success) {
      Notification({
        type: "success",
        message: message,
      })
      dispatch(SetState({ field: 'success', value: '' }))
    }
    if (err) {
      Notification({
        type: "error",
        message: err,
      })
      dispatch(SetState({ field: 'err', value: '' }))
    }
  }, [success, err])

  const handleResendEmail = () => {
    const params = new URLSearchParams(history.location.search);
    const betaUser = params.get('ChatPro_Beta_User');

    dispatch(ResendEmail({betaUser: betaUser === "true"}));
  }

  return (
    <AuthWrapper>
      <Row className='d-flex'>
        <Col md={10} className='left-side-img'>
          <LeftSide />
        </Col>

        <Col md={14}>
          <div className="email-verfication-wrapper">
            <div className="email-verification">
              <h1 className="m-0">Verification Email Sent</h1>
              <img src={Verfication} alt="verification img" />
              <p className="m-0"><i>If you didn’t receive the email </i> <Link onClick={handleResendEmail} className="email-verification-btn" type="link">Click Here</Link></p>
            </div>
          </div>
        </Col>
      </Row>
    </AuthWrapper>
  );
};

export default Verification;
