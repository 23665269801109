import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const AddFolder = createAsyncThunk(
    'add/folder',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/folder/add-folder', { name: data });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);


export const GetFolders = createAsyncThunk(
    'get/folders',
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/folder/get-folders', { params: data });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const RemoveFolder = createAsyncThunk(
    'remove/removeFolders',
    async (data, thunkAPI) => {
        try {
            const { folderId } = data;
            const response = await axios.delete('/folder/remove-folder', { params: { folderId } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);
export const getTemplates = createAsyncThunk(
    'get/templates',
    async (data, thunkAPI) => {
        try {
            const { id } = data;
            console.log({id})
            const response = await axios.post('/template/get-templates', { folderId:id});
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const AddTemplate = createAsyncThunk(
    'add/template',
    async (data, thunkAPI) => {
        try {
            const { message, name, folderId, file } = data;

            const formData = new FormData();
            formData.append('message', message);
            formData.append('name', name);
            formData.append('folderId', folderId);

            if (file) {
                formData.append('templateImage', file);
            }

            const response = await axios.post('/template/add-template', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);


export const updateTemplate = createAsyncThunk(
    'update/template',
    async (data, thunkAPI) => {
        try {
            const { message, name, templateId } = data;
            const response = await axios.put('/template/update-template', { message, name, templateId });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);
export const RemoveTemplate = createAsyncThunk(
    'remove/template',
    async (data, thunkAPI) => {
        try {
            const { templateId } = data;
            const response = await axios.delete('/template/remove-template', { params: { templateId } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const getSelectedTemplates = createAsyncThunk(
    'get/selected-templates',
    async (data, thunkAPI) => {
        try {
            const { selectedFolders } = data;
            const response = await axios.post('/template/get-selected-templates', { selectedFolders});
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);



// export const RemoveLeadsFromLists = createAsyncThunk(
//     'lists/removeLeadsFromLists',
//     async (data, thunkAPI) => {
//         try {
//             const { listIds } = data;
//             const response = await axios.post('/lists/remove-leads-from-lists', { listIds });
//             return response.data;
//         } catch (err) {
//             if (err.response && err.response.data) {
//                 return thunkAPI.rejectWithValue({
//                     err: err.response.data,
//                     status: err.response.status
//                 });
//             }
//             return thunkAPI.rejectWithValue({
//                 err: 'Network Error'
//             });
//         }
//     }
// );

// export const UpdateList = createAsyncThunk(
//     'list/updateList',
//     async (listData, thunkAPI) => {
//         try {
//             const { listId, name } = listData;
//             const response = await axios.put('/lists/update-list', { name }, { params: { listId } });
//             return response.data;
//         } catch (err) {
//             if (err.response && err.response.data) {
//                 return thunkAPI.rejectWithValue({
//                     err: err.response.data,
//                     status: err.response.status
//                 });
//             }
//             return thunkAPI.rejectWithValue({
//                 err: 'Network Error'
//             });
//         }
//     }
// );

const template = createSlice({
    name: 'template',
    initialState: {
        success: false,
        listsLoading: false,
        loading: false,
        folders: [],
        templates: [],
        total: 0,
        err: '',
    },
    reducers: {
        SetState(state, { payload: { field, value } }) {
            state[field] = value;
        },
        ClearState() {
            return {
                success: false,
                loading: false,
                lists: {},
                total: '',
                err: '',
            };
        }
    },
    extraReducers: {
        [AddFolder.pending]: (state, action) => ({
            ...state,
            success: false,
            loading: true
        }),
        [AddFolder.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false
        }),
        [AddFolder.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload?.err?.error
        }),
        [GetFolders.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [GetFolders.fulfilled]: (state, action) => ({
            ...state,
            total: action.payload.total,
            folders: action.payload.data,
            loading: false
        }),
        [GetFolders.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false
        }),

        [RemoveFolder.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [RemoveFolder.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false,
        }),
        [RemoveFolder.rejected]: (state, action) => ({
            ...state,
            success: false,
            err: action.payload?.err?.error
        }),
        [getTemplates.pending]: (state, action) => ({
            ...state,
            listsLoading: true
        }),
        [getTemplates.fulfilled]: (state, action) => ({
            ...state,
            templates: action.payload.data,
            listsLoading: false,
        }),
        [getTemplates.rejected]: (state, action) => ({
            ...state,
            err: action.payload?.err?.error,
            listsLoading: false,
        }),
        [AddTemplate.pending]: (state, action) => ({
            ...state,
            success: false,
            loading: true
        }),
        [AddTemplate.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false
        }),
        [AddTemplate.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload?.err?.error
        }),
        [RemoveTemplate.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [RemoveTemplate.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false,
        }),
        [RemoveTemplate.rejected]: (state, action) => ({
            ...state,
            success: false,
            err: action.payload?.err?.error
        }),
        [updateTemplate.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [updateTemplate.fulfilled]: (state, action) => ({
            ...state,
            loading: false,
            message: action.payload.message,
            success: true
        }),
        [updateTemplate.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload?.err?.error
        })
    }
});

const { reducer, actions } = template;

export const {
    SetState,
    ClearState
} = actions;

export default reducer;
