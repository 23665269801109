import styled from 'styled-components';

const BackgroundJobsWrapper = styled.div`
    height: calc(100vh - 102px);
    margin-top: 24px;
    .header-wrapper{
    h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
}
}
.cards-wrapper.responsive-layout-wrapper {
    min-height: 417px;
}
.message-checkbox-wrap {
    margin-right: 10px !important;
}
.delete-messages-wrap {
    border: 1px solid #5F63F2;
    align-items: center;
    padding: 11px;
    display: inline-flex;
    color: #5F63F2;
}
.groups-wrapper.posts-wrapper.progress .progress-bar {
    background: #FF69A5;
}
.cards-wrapper {
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    margin-top: 27px;
    h4{
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}
.message-jobs-wrapper{
    display: flex;
    justify-content: right;
}
.message-label-wrap {
    margin-left: 20px !important;
}
.message-span-wrapper {
    display: flex;
    align-items: self-end;
    justify-content: right;
}
.cards-labels-wrapper{
    margin-top: 26px;
    h3 {
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
}
.card-header-flex-info {
    display: flex !important;
}
.card-header-heading-info {
    margin-left: 20px !important;
}
.stop-icon-wrapper {
    width: 18px;
    cursor: pointer;
    margin-right: 1px;
    margin-top: -3px;
}
svg {
    font-size: 18px;
    margin-top: -1px;
    margin-right: 8px;
    color: #ADB4D2;
    cursor: pointer;
}
}
.progress {
    border: 1px solid #ECEEF5;
    background: #ECEEF5;
    margin-top: 7px;
    text-align: center;
    color:#fff;
    border-radius:4px;
    overflow:hidden;
    white-space: nowrap;
    .progress-bar {
    background: #32AE81;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0px;

}
}
}
label.ant-checkbox-wrapper.header-checkbox {
    left: -3px !important;
    position: relative;
}
.card-inner{
.progress-bar-wrapper{
    margin-top:25px;
}
.delete-groups-btn{
border:1px solid #5F63F2;
display: flex;
align-items: center;
padding: 11px;
margin-left: 16px;
&:hover{
background-color:#5F63F2;
}
&:hover span {
    color:#fff !important;
}
&:hover svg {
    color:#fff;
}
span{
font-size: 12px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0em;
text-align: left;
color:#5F63F2;
margin-left:0px;
}
svg{
    color:#5F63F2;
    margin-top: -1px;
}
}
 span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9BA8B7;
    margin-left: 8px;
}
.stop-icon-wrapper {
    width: 19px;
    cursor: pointer;
    position: relative;
    left: 0px;
    top: -2px;
}
svg {
    font-size: 18px;
    margin-top: -5px;
    margin-right: 7px;
    color: #ADB4D2;
    cursor: pointer;
}
p.m-0 {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #5A5F7D;
    margin-left: 13px !important;
}
.groups-wrapper{
    .progress-bar {
background-color:#FF9900 ;
}
}
.ant-checkbox-wrapper {
    position: relative;
    left: 3px;
    top: -4px;
    display: flex;
    .ant-checkbox{
        margin-left:0px !important ;
        .ant-checkbox-inner{
            margin-left:0px !important ;

        }
}
}
}
svg.modal-pause-icon {
    font-size: 52px;
    color:#ADB4D2;
}
`;
export default BackgroundJobsWrapper;