import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const AddList = createAsyncThunk(
    'list/addList',
    async (data, thunkAPI) => {
        try {
            const { name } = data;
            const response = await axios.post('/lists/add-list', {  name  });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);


export const GetLists = createAsyncThunk(
    'list/getList',
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/lists/get-lists', { params: data});
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const GetTags = createAsyncThunk(
    'tags/getTags',
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/tags/get-tags', { params: data});
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const RemoveLists = createAsyncThunk(
    'lists/removeLeads',
    async (data, thunkAPI) => {
        try {
            const { listIds } = data;
            const response = await axios.delete('/lists/remove-lists', { params: { listIds } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const RemoveLeadsFromLists = createAsyncThunk(
    'lists/removeLeadsFromLists',
    async (data, thunkAPI) => {
        try {
            const { listIds } = data;
            const response = await axios.post('/lists/remove-leads-from-lists', { listIds });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const UpdateList = createAsyncThunk(
    'list/updateList',
    async (listData, thunkAPI) => {
        try {
            const { listId, name } = listData;
            const response = await axios.put('/lists/update-list', { name }, { params: { listId } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

const list = createSlice({
    name: 'list',
    initialState: {
        success: false,
        listsLoading: false,
        loading: false,
        lists: [],
        tags: [],
        total: 0,
        err: '',
    },
    reducers: {
        SetState(state, { payload: { field, value } }) {
            state[field] = value;
        },
        ClearState() {
            return {
                success: false,
                loading: false,
                lists: {},
                total: '',
                err: '',
            };
        }
    },
    extraReducers: {
        [AddList.pending]: (state, action) => ({
            ...state,
            success: false,
            loading: true
        }),
        [AddList.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false
        }),
        [AddList.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload?.err?.error
        }),
        [GetLists.pending]: (state, action) => ({
            ...state,
            listsLoading: true
        }),
        [GetLists.fulfilled]: (state, action) => ({
            ...state,
            total: action.payload.total,
            lists: action.payload.data,
            listsLoading: false
        }),
        [GetLists.rejected]: (state, action) => ({
            ...state,
            success: false,
            listsLoading: false
        }),
        [GetTags.pending]: (state, action) => ({
            ...state,
            listsLoading: true
        }),
        [GetTags.fulfilled]: (state, action) => ({
            ...state,
            tags: action.payload.data,
            listsLoading: false
        }),
        [GetTags.rejected]: (state, action) => ({
            ...state,
            success: false,
            listsLoading: false
        }),

        [RemoveLists.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [RemoveLists.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false,
        }),
        [RemoveLists.rejected]: (state, action) => ({
            ...state,
            success: false,
            err: action.payload?.err?.error
        }),
        [RemoveLeadsFromLists.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [RemoveLeadsFromLists.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false,
        }),
        [RemoveLeadsFromLists.rejected]: (state, action) => ({
            ...state,
            success: false,
            err: action.payload?.err?.error
        }),
        [UpdateList.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [UpdateList.fulfilled]: (state, action) => ({
            ...state,
            loading: false,
            message: action.payload.message,
            success: true
        }),
        [UpdateList.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload?.err?.error
        })
    }
});

const { reducer, actions } = list;

export const {
    SetState,
    ClearState
} = actions;

export default reducer;
