import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from "lodash";
import moment from "moment";
import {
  Form,
  Checkbox,
  Input,
  Spin,
  Row,
  Col,
  Tooltip
} from "antd";

import InputSearch from '../../components/searchBar/input'
import Loader from "../../components/loader/index";
import FeatherIcon from "feather-icons-react";
import Notification from '../ant-notification/notification';
import NoData from "../../static/img/no-data.svg"

import { FormOutlined, CloseCircleOutlined } from "@ant-design/icons"
import { FiTrash2 } from "react-icons/fi";

import { Button } from "../../components/buttons/buttons";
import { Modal } from "../../components/modals/antd-modals";

import { TableWrapper } from "./style";

import {
  AddList,
  SetState,
  GetLists,
  RemoveLists,
  UpdateList,
  RemoveLeadsFromLists
} from '../../redux/slices/list-slice';

const List = () => {
  const dispatch = useDispatch();
  const { loading, listsLoading, lists, err, success, message, total } = useSelector(state => state.list);
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(false);
  const [selectedLists, setSelectedLists] = useState([]);
  const [listsData, setListsData] = useState([]);
  const [editList, setEditList] = useState(false);
  const [createList, setCreateList] = useState(false);
  const [bulkAction, setBulkAction] = useState(false);
  const [list, setList] = useState({});
  const [removeLists, setRemoveLists] = useState(false);
  const [removeLeads, setRemoveLeads] = useState(false);
  const [listData, setListData] = useState({});
  const [filter, setFilter] = useState({ keyword: '' });
  const [sort, setSort] = useState([["createdAt", "ascending"]]);

  const isSelected = (list) => selectedLists.findIndex(item => (item?._id == list?._id)) !== -1;
  const isCompletelySelected = () => selectedLists.length && listsData.every(data => { return selectedLists.find(item => data._id == item._id) });



  
  useEffect(() => {
    setListsData(lists)
  }, [lists, total]);

  const showLeadsCount = (value) => {
    if (Number(value) > 1000) value = `${value.toString().slice(0, value.toString().length - 3)}k+`;
    return `${value}`
  }

  const handleString = (str) => {
    if (str) {
      if (str.length < 30) return str;
      const maxLength = 30;
      const title = str.substr(0, maxLength);
      return `${title}...`;
    } else return "";
  };

  useEffect(() => {
    if (success) {
      Notification({
        type: 'success',
        message
      });
      dispatch(SetState({ field: 'success', value: false }))
      dispatch(GetLists({ filter, sort }))
    }
    if (err) {
      Notification({
        type: 'error',
        message: err
      });
      dispatch(SetState({ field: 'err', value: '' }))
    }
    setListData({});
    setCreateList(false);
    setRemoveLists(false);
    setRemoveLeads(false);
    setEditList(false);
    setDisabled(false);
  }, [success, err])

  useEffect(() => {
    dispatch(GetLists({ filter, sort }))
  }, []);

  const handleAddListData = async () => {
    if (!listData.name || listData.name?.trim()?.length === 0) return dispatch(SetState({ field: 'err', value: 'Please Enter Proper Name' }))
    const list = { listData, name: listData.name.trim() }
    setDisabled(true);
    await dispatch(AddList(list));
  }

  const handleUpdateList = async () => {
    setDisabled(true);
    const { _id: listId, name } = list;
    if (!name.length || name.trim()?.length === 0) return dispatch(SetState({ field: 'err', value: 'Please Enter Proper Name' }))
    await dispatch(UpdateList({ listId, name: name.trim() }));
  }

  const handleSearchChange = debounce((event) => {
    setFilter({ keyword: event });
    dispatch(GetLists({ filter: { keyword: event }, sort }))
  }, 250);

  const handleDeleteList = async () => {
    let listIds = [];
    setDisabled(true);
    if (bulkAction) listIds = selectedLists.map(data => data._id);
    else listIds.push(list._id)
    await dispatch(RemoveLists({ listIds }));
    setSelectedLists([]);
  }

  const handleDeleteLeads = async () => {
    let listIds = [];
    setDisabled(true);
    if (bulkAction) listIds = selectedLists.map(data => data._id);
    else listIds.push(list._id)
    await dispatch(RemoveLeadsFromLists({ listIds }));
    setSelectedLists([]);
  }

  const onRowSelectionChange = ({ checked, selectedRows, listData }) => {
    let newLists = selectedLists;

    if (selectedRows === 'All') {
      if (checked) newLists.push(...listData);
      else {
        newLists = selectedLists.filter((value, index) => {
          return !listData.find(data => data._id === value._id)
        })
      }
    }
    else if (checked) newLists.push(listData);
    else newLists = newLists.filter(data => data._id !== listData._id);
    newLists = [... new Set(newLists)];
    setSelectedLists(newLists)
  };

  const handleRemoveListsLeads = ({ leads, list }) => {
    if(Number(leads) === 0) return;
    setBulkAction(false); 
    setList(list); 
    setRemoveLeads(true)
  }

  const data = [];
  lists.forEach((list, index) => {
    data.push(
      {
        id: index,
        checkbox: <Checkbox type="checkbox"
          checked={isSelected(list)}
          onChange={(event) => {
            onRowSelectionChange({
              checked: event.target.checked,
              selectedRows: 'Single',
              listData: list
            })
          }}></Checkbox>,
        boxtext: <span>{list.name.length > 30 ? `${list.name.slice(0, 40)}...` : list.name}</span>,
        name: list.name,
        list,
        removeLeads:<Tooltip placement="topLeft" title="Remove leads"><span className={list.activeLeads > 0 ? 'icon-list-x-circle' : 'icon-list-x-circle-disable'}/></Tooltip>,
        edit: <Tooltip placement="topLeft" title="Edit list"><FormOutlined className='note-icon' /></Tooltip>,
        bucket: <Tooltip placement="topLeft" title="Delete list"><FiTrash2 className='bucket-icon' /></Tooltip>,
        content: "Active Users",
        footer: showLeadsCount(list.activeLeads),
        content_extra: "Date Created",
        footer_extra: moment(list.createdAt).format("D MMM YYYY"),
      })
  });

  return (
    <TableWrapper>
      <div className='content-header page-haeder-main d-flex align-items-center justify-content-between'>
        <div className='heading'>
          <h3>Lists</h3>
        </div>
        <div className='d-flex align-items-center'>
          <div key={1} className="search-box list-search-bar-wrapper">
            <InputSearch
              search
              onChange={(event) => { handleSearchChange(event.target.value) }}
              type="text"
              placeholder="Search by Name" />
          </div>
          <div className="d-flex align-items-center checkbox-wrapper">
            <Checkbox className="m-0 p-0" type="checkbox"
              checked={isCompletelySelected()}
              onChange={(event) => {
                onRowSelectionChange({
                  checked: event.target.checked,
                  selectedRows: 'All',
                  listData: listsData
                })
              }} />
            <span >Select All</span>
          </div>
            <>
              <div className='d-flex list-action-btn'>
                <div key="6" className="page-header-actions list-actions-btn">
                  <Button
                  outlined
                    size="default"
                    className={selectedLists.length > 1 ? "list-button" : "list-disable-button"}
                    type="primary"
                    onClick={() => {
                      setBulkAction(true),
                        setRemoveLists(true)
                    }}
                    disabled={selectedLists.length <= 1}>
                    <FiTrash2 className="trash-bukets" />
                    Remove Lists
                  </Button>
                </div>

                <div key="7" className="page-header-actions list-actions-btn">
                  <Button
                  outlined
                    size="default"
                    className={selectedLists.length > 1 && selectedLists.filter(list => list.activeLeads > 0)?.length ? "list-button" : "list-disable-button"}
                    type="primary"
                    disabled={selectedLists.length <= 1 || !selectedLists.filter(list => list.activeLeads > 0)?.length}
                    onClick={() => {
                      setBulkAction(true),
                        setRemoveLeads(true)
                    }}>
                    <FiTrash2 className="trash-bukets" />
                    Remove Leads
                  </Button>
                </div>
                <div key="8" className="page-header-list-button d-flex">
                <Button outlined className='add-new-list' size="default" type="primary" onClick={() => setCreateList(true)}>
                  <FeatherIcon icon="plus" />
                  Add New List
                </Button>
              </div>
              </div>
            </>
        </div>
        <Modal
          className="Create-New-List create-pipeline create-list-popup"
          footer={null}
          type="primary"
          title="Add New List"
          centered
          visible={createList}
          width={628}
          closable={true}
          onCancel={() => {
            setCreateList(false)
          }}>
          <Form form={form} layout="vertical">
            <Form.Item label="Title">
              <Input
                className="border-radius-none"
                value={listData.name}
                onChange={(event) => {
                  setListData({ 'name': event.target.value })
                }}
                placeholder="ChatPro List" />
            </Form.Item>
          </Form>
          <div className="modal-footer d-flex justify-content-end">
            <Button
              size="default"
              outlined={true}
              type="white"
              onClick={() => {
                setListData({})
                setCreateList(false)
              }}
            >
              Cancel
            </Button>
            <Button disabled={disabled} onClick={handleAddListData} size="default" type="primary" className="ml-2 create-new-pipeline-btn">
              {loading ? <Spin size="small" /> : 'Create'}
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-content list-modal-content"
          footer={null}
          type="primary"
          centered
          visible={removeLeads}
          width={440}
          closable={false}
        >
          <CloseCircleOutlined />
          {!bulkAction ? <>
            <h3>Are you sure you want to remove this lead?</h3>
            <p>Leads belonging to this list will be removed, but they will remain in the system.</p></> :
            <>
              <h3>Are you sure you want to remove these leads?</h3>
              <p>Leads belonging to these lists will be removed, but they will remain in the system.</p></>}
          <div className="modal-footer d-flex justify-content-center">
            <Button
              size="default"
              outlined={true}
              type="white"
              onClick={() => {
                setRemoveLeads(false);
              }}
            >
              Cancel
            </Button>
            <Button disabled={disabled} onClick={() => { handleDeleteLeads() }} size="default" type="primary" className="ml-2 save-btn">
              {loading ? <Spin size="small" /> : "Remove"}
            </Button>
          </div>
        </Modal>
        <Modal
          className="modal-content list-modal-content"
          footer={null}
          type="primary"
          centered
          visible={removeLists}
          width={440}
          closable={false}
        >
          <FiTrash2 className='custom-delete-icon-modal' />
          {!bulkAction ? <>
            <h3>Are you sure you want to delete this list?</h3>
            <p>The selected List will be deleted. Any leads in these list will remain in the system.</p></> : <>
            <h3>Are you sure you want to delete these lists?</h3>
            <p>The selected Lists will be deleted. Any leads in these lists will remain in the system.</p></>}

          <div className="modal-footer d-flex justify-content-center">
            <Button
              size="default"
              outlined={true}
              type="white"
              onClick={() => {
                setRemoveLists(false);
              }}
            >
              Cancel
            </Button>
            <Button disabled={disabled} onClick={() => { handleDeleteList() }} size="default" type="primary" className="ml-2 save-btn">
              {loading ? <Spin size="small" /> : "Remove"}
            </Button>
          </div>
        </Modal>
      </div>
      <div className='list-page-cards-wrapper'>
        <Row gutter={[30, 24]}>

         {
           data.length ?  (
            data.map(({
              checkbox,
              boxtext,
              edit,
              name,
              content,
              content_extra,
              bucket,
              footer,
              list,
              footer_extra,
              eye,
              removeLeads,
              id
            }) => {
              return (
                <Col key={id} md={8}>
                  <div className="list-card-wrapper">
                  <div className="d-flex justify-content-between list-card-wrapper-item">
                      <div className="d-flex card-input ">
                        <span>{checkbox}</span>
                        <Tooltip placement="topLeft" title={name}><span className='list-checkbox-text'>{boxtext}</span></Tooltip>
                      </div>
                      <div className="d-flex cards-icons">
                        <span onClick={() => { handleRemoveListsLeads({ leads: showLeadsCount(list.activeLeads), list }) }}>{removeLeads}</span>
                        <span className='edit-icon' onClick={() => { setBulkAction(false); setEditList(true), setList(list) }}>
                          {edit}
                        </span>
                        <span className='bucket-icon' onClick={() => { setBulkAction(false); setRemoveLists(true), setList(list) }}>
                          {bucket}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between user-info-list">
                      <div className="d-flex flex-column card-footer-wrapper">
                        <span className="user-info">{content}</span>
                        <span>{footer}</span>
                      </div>
                      <div className="d-flex flex-column card-footer-wrapper">
                        <span className="user-info">{content_extra}</span>
                        <span>{footer_extra}</span>
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })
           )
          :  <div className="no-data-here-wrapper">
          {listsLoading ? <div><Loader spinSize="large" spaceSize="middle" className="loader-wrapper-block-leads"/></div> :
                 <img src={NoData} alt="No Data Here" /> 
                 }
        </div>
         }
       

        </Row>
        <Modal
          className="Create-New-List create-list-popup"
          footer={null}
          type="primary"
          title="Edit List"
          centered
          visible={editList}
          width={628}
          closable={true}
          onCancel={() => {
            setEditList(false)
          }}>
          <Form form={form} layout="vertical">
            <Form.Item label="Title">
              <Input
                type="text"
                value={list.name}
                allowClear
                onChange={(event) => {
                  setList({ ...list, 'name': event.target.value })
                }}
                className="border-radius-none"
                placeholder="Chat Pro Tool List"
              />
            </Form.Item>
          </Form>
          <div className="modal-footer d-flex justify-content-end">
            <Button
              size="default"
              outlined
              type="white"
              onClick={() => setEditList(false)}
            >
              Cancel
            </Button>
            <Button disabled={disabled} onClick={() => { handleUpdateList() }} size="default" type="primary" className="ml-2 Edit-list-pipeline-btn">
              {loading ? <Spin size="small" /> : 'Confirm'}
            </Button>
          </div>
        </Modal>
      </div>
    </TableWrapper>
  );
};
export default List;
