import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Checkbox, Button, Modal, Spin, Tooltip } from "antd";

import Progress from "../../components/progress Bar/index";
import RemoveIcon from "../../static/img/stop.svg";
import BackgroundJobsWrapper from "./style";
import Notification from '../ant-notification/notification';

import { AiOutlinePauseCircle, AiFillCaretRight } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { isEmpty } from "lodash";

import { GetJobsRecord, RemoveJobsRecord, PauseBackgroundJobs, SetState } from "../../redux/slices/jobs-slice";

import { ExtensionId } from '../../utils/constants'

const BackgroundJobs = () => {
  const dispatch = useDispatch();

  const [groupsJobs, setGroupsJobs] = useState([]);
  const [postsJobs, setPostsJobs] = useState([]);
  const [messageJobs, setMessageJobs] = useState([]);
  const [friendsJob, setFriendsJob] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [bulkSelection, setBulkSelection] = useState(false);
  const [pauseJob, setPauseJob] = useState(false);
  const [removeJob, setRemoveJob] = useState(false);
  const [resumeJob, setResumeJob] = useState(false);
  const [selectedJobs, setSelectedJobs] = useState('');
  const [extensionLoading, setExtensionLoading] = useState('');


  const { facebookProfileData } = useSelector((state) => state.auth);
  const { jobs, success, err, loading, message } = useSelector((state) => state.backgroundJobs);

  const fixValue = (value) => {
    try {
      if (value && Number(value)) return Number(value).toFixed(2);
      else if (!value) return 0;
      else return value;
    } catch (err) {
      return value || 0;
    }
  };

  useEffect(() => {
    if (!isEmpty(facebookProfileData)) {
      const { profileId: facebookId } = facebookProfileData;
      if (facebookId) dispatch(GetJobsRecord({ facebookId }));
    }
  }, [facebookProfileData]);

  useEffect(() => {
    if (success) {
      dispatch(SetState({ field: 'success', value: false }))
      dispatch(SetState({ field: 'message', value: '' }))
      Notification({
        type: 'success',
        message
      });
      if (!isEmpty(facebookProfileData)) {
        const { profileId: facebookId } = facebookProfileData;
        dispatch(GetJobsRecord({ facebookId }));
      }
    }

    if (err) {
      dispatch(SetState({ field: 'err', value: false }))
      dispatch(SetState({ field: 'message', value: '' }))
      Notification({
        type: 'error',
        message
      });
      if (!isEmpty(facebookProfileData)) {
        const { profileId: facebookId } = facebookProfileData;
        dispatch(GetJobsRecord({ facebookId }));
      }
    }

    setResumeJob(false);
    setPauseJob(false);
    setRemoveJob(false);
    setSelectedGroups([]);
    setExtensionLoading(false);
    setSelectedPosts([]);
    setSelectedMessages([]);
  }, [err, success]);

  useEffect(() => {
    const groups = jobs.filter((job) => job.name === "Sync_Facebook_Groups");
    const friends = jobs.find((job) => job.name === "Sync_Facebook_Friends") || {};
    const posts = jobs.filter((job) => job.name === "Sync_Facebook_Posts");
    const messages = jobs.filter((job) => job.name === "Send_Bulk_Messages");
    setGroupsJobs(groups);
    setMessageJobs(messages);
    setFriendsJob(friends);
    setPostsJobs(posts);
  }, [jobs]);

  const handleCheckboxSelection = async ({ type, value, checked, allSelected }) => {
    if (type === 'groups') {
      if (allSelected) {
        let groupIds = [];
        if (checked) groupIds = groupsJobs.map(({ _id }) => _id);
        setSelectedGroups(groupIds);
      } else {
        if (checked) setSelectedGroups([...selectedGroups, value]);
        else {
          const filterSelectedGroups = selectedGroups.filter((group) => group !== value);
          setSelectedGroups(filterSelectedGroups);
        }
      }
    } else if (type === 'posts') {
      if (allSelected) {
        let postIds = [];
        if (checked) postIds = postsJobs.map(({ _id }) => _id);
        setSelectedPosts(postIds);
      } else {
        if (checked) setSelectedPosts([...selectedPosts, value]);
        else {
          const filterSelectedPosts = selectedPosts.filter((post) => post !== value);
          setSelectedPosts(filterSelectedPosts);
        }
      }
    } else {
      if (allSelected) {
        let messageJobIds = [];
        if (checked) messageJobIds = messageJobs.map(({ _id }) => _id);
        setSelectedMessages(messageJobIds);
      } else {
        if (checked) setSelectedMessages([...selectedMessages, value]);
        else {
          const filterSelectedMessages = selectedMessages.filter((message) => message !== value);
          setSelectedMessages(filterSelectedMessages);
        }
      }
    }
  }

  const handleRemoveJobs = async () => {
    let jobIds;
    if (bulkSelection) {
      if (selectedJobs === 'friends') jobIds = friendsJob._id;
      else if (selectedJobs === 'groups') jobIds = selectedGroups;
      else if (selectedJobs === 'posts') jobIds = selectedPosts;
      else if (selectedJobs === 'messages') jobIds = selectedMessages;
    } else jobIds = [selectedJobId];
    await dispatch(RemoveJobsRecord({ jobIds }))
  }

  const handleSetJobsColor = ({ status }) => {
    if (status === 'Paused') return { color: '#000000' };
    if (status === 'In Progress') return { color: 'rgb(144, 238, 144)' };
    if (status === 'Completed') return { color: '#ADD8E6' };
    else return { color: 'rgb(255, 0, 0)' };
  }

  const handleString = (str) => {
    if (str) {
      if (str.length <= 40) return str;
      const maxLength = 40;
      const title = str.substr(0, maxLength);
      return `${title}...`;
    } else return "";
  };

  const handlePauseJobs = async () => {
    let jobIds;
    if (bulkSelection) {
      if (selectedJobs === 'friends') jobIds = friendsJob._id;
      else if (selectedJobs === 'groups') jobIds = selectedGroups;
      else if (selectedJobs === 'posts') jobIds = selectedPosts;
      else if (selectedJobs === 'messages') jobIds = selectedMessages;
    } else jobIds = [selectedJobId];
    await dispatch(PauseBackgroundJobs({ jobIds }));
  }

  const handleResumeJobs = async () => {

    const jobData = jobs.find(job => job._id === selectedJobId);
    const jobAlreadyRunning = jobs.find(job => job.name === jobData.name && job.status === 'In Progress');

    if (jobAlreadyRunning) {
      dispatch(SetState({ field: 'message', value: 'Job Is Already Running' }))
      return dispatch(SetState({ field: 'err', value: true }));
    }
    if (!chrome?.runtime) {
      dispatch(SetState({ field: 'message', value: 'Extension Is Not Installed' }))
      setResumeJob(false);
      setExtensionLoading(false);
    } else {
      chrome.runtime?.sendMessage(
        ExtensionId,
        {
          messageType: "IS_EXTENSION_INSTALLED",
        },
        async (response) => {
          if (response && response.success) {
            if (response?.isUserValid === false) {
              setResumeJob(false);
              setExtensionLoading(false);
              return dispatch(SetState({ field: 'err', value: 'Account Is Not Attached Or Not Logged In' }));
            }
            chrome.runtime?.sendMessage(
              ExtensionId,
              {
                messageType: "Resume_Job",
                jobId: selectedJobId
              }, (data) => {
                const { err: error, message } = data || {};
                setResumeJob(false);
                setExtensionLoading(false);
                dispatch(SetState({ field: 'loading', value: false }));
                dispatch(SetState({ field: 'message', value: message || 'Job Has Been Resumed!' }))
                if (error) return dispatch(SetState({ field: 'err', value: true }))
                return dispatch(SetState({ field: 'success', value: true }))
              });
          }
          else {
            setResumeJob(false);
            setExtensionLoading(false);
            return dispatch(SetState({ field: 'err', value: 'Extension Is Not Installed' }))
          }
        }
      );
    }
  }

  const groups = groupsJobs.map((job, index) => {
    const { jobDetails, _id, syncInfo, status } = job;
    const { totalPeople, groupName } = syncInfo;
    const { totalLeadsSynced: totalSynced = 0 } = jobDetails || {};
    const percentageCompleted = (totalSynced / totalPeople) * 100;
    return ({
      index,
      checkbox: <Checkbox
        checked={selectedGroups.find(id => id === _id)}
        onClick={(event) => {
          handleCheckboxSelection({
            type: 'groups',
            value: _id,
            checked: event.target.checked
          })
        }}
      />,
      label: groupName,
      status: status,
      icons: <AiOutlinePauseCircle onClick={() => { setBulkSelection(false); setSelectedJobId(_id); setPauseJob(true); }} />,
      icon: <img src={RemoveIcon} onClick={() => { setBulkSelection(false); setSelectedJobId(_id); setRemoveJob(true); }} className="stop-icon-wrapper" />,
      resume: <AiFillCaretRight onClick={() => { setBulkSelection(false); setSelectedJobId(_id); setResumeJob(true) }} />,
      progress: <Progress className="groups-wrapper" now={percentageCompleted || 0} label={`Synced ${fixValue(percentageCompleted)}%`} />,
    })
  })

  const posts = postsJobs.map((job, index) => {
    const { _id, progress, syncInfo, status } = job;

    return ({
      index,
      checkbox: <Checkbox
        checked={selectedPosts.find(id => id === _id)}
        onClick={(event) => {
          handleCheckboxSelection({
            type: 'posts',
            value: _id,
            checked: event.target.checked
          })
        }}
      />,
      label: syncInfo?.name,
      status: status,
      icons: <AiOutlinePauseCircle onClick={() => { setBulkSelection(false); setSelectedJobId(_id); setPauseJob(true); }} />,
      icon: <img src={RemoveIcon} onClick={() => { setBulkSelection(false); setSelectedJobId(_id); setRemoveJob(true); }} className="stop-icon-wrapper" />,
      resume: <AiFillCaretRight onClick={() => { setBulkSelection(false); setSelectedJobId(_id); setResumeJob(true) }} />,
      progress: <Progress className="groups-wrapper" now={progress || 0} label={`Synced ${fixValue(progress || 0)}%`} />,
    })
  })

  return (
    <BackgroundJobsWrapper>
      <div className="header-wrapper">
        <h1 className="m-0">Background Jobs</h1>
      </div>
      <div className="cards-wrapper">
        <h4 className="m-0">Friends</h4>
        <div className="cards-labels-wrapper d-flex justify-content-between align-items-center">
          <div className="card-header-flex-info">
          <h3 className="m-0">
            {friendsJob?.syncInfo?.totalProfiles
              ? `Total Friends - ${friendsJob?.syncInfo?.totalProfiles}`
              : "Total Friends"}
          </h3>
          <h3 className="card-header-heading-info m-0">
            Status - <span style={handleSetJobsColor({ status: friendsJob.status })}>{friendsJob?.status}</span>
          </h3>
          </div>
          {friendsJob?._id ? <div className="message-jobs-wrapper">
            <img src={RemoveIcon} onClick={() => { setBulkSelection(false); setSelectedJobId(friendsJob._id); setRemoveJob(true); }} className="stop-icon-wrapper" />
          </div> : ''}
        </div>
        {friendsJob?._id && <Progress
          now={Number(friendsJob.progress) || 0}
          label={`Synced ${fixValue(Number(friendsJob.progress) || 0)}%`}
        />}
      </div>

      <div className="cards-wrapper">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0">Messages</h4>
          <div className='d-flex align-items-center'>
            <Checkbox
              checked={selectedMessages.length && selectedMessages.length === messageJobs.length}
              onClick={(event) => {
                handleCheckboxSelection({
                  type: 'messages',
                  value: [],
                  checked: event.target.checked,
                  allSelected: true
                })
              }}
              className='header-checkbox'
            />
            <span className="message-checkbox-wrap">Select All</span>
            {selectedMessages.length ? <Button className="delete-messages-wrap" onClick={() => { setBulkSelection(true); setSelectedJobs('messages'); setRemoveJob(true) }} ><FiTrash2 className="groups-delete" /> Delete Message Jobs</Button> : ''}
          </div>
        </div>
        {messageJobs.map((data, index) => {
          return (<>
            <div key={index} className="cards-labels-wrapper d-flex justify-content-between align-items-center">
              <div className='d-flex align-items-center'>
                <Checkbox
                  checked={selectedMessages.length && selectedMessages.includes(data._id)}
                  onClick={(event) => {
                    handleCheckboxSelection({
                      type: 'messages',
                      value: data._id,
                      checked: event.target.checked,
                    })
                  }}
                />
                <Tooltip title={data.syncInfo?.name}>
                  <p className='m-0'>
                    {handleString(data.syncInfo?.name)}
                  </p>
                </Tooltip>
                <p style={handleSetJobsColor({ status: data.status })} className='m-0 message-label-wrap'>{data.status}</p>
              </div>
              <div className="message-jobs-wrapper">
                <AiOutlinePauseCircle onClick={() => { setBulkSelection(false); setSelectedJobId(data._id); setPauseJob(true); }} />
                <img src={RemoveIcon} onClick={() => { setBulkSelection(false); setSelectedJobId(data._id); setRemoveJob(true); }} className="stop-icon-wrapper" />
                <AiFillCaretRight onClick={() => { setBulkSelection(false); setSelectedJobId(data._id); setResumeJob(true) }} />
              </div>
            </div>
            {data.progress && <Progress
              now={Number(data.progress) || 0}
              label={`Progress ${fixValue(data.progress) || 0}%`}
            />}
          </>)
        })}

      </div>

      <Row gutter={[30, 30]}>
        <Col md={12}>
          <div className='cards-wrapper responsive-layout-wrapper'>
            <div className='card-inner'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='m-0'>Groups - ({groupsJobs.length})</h4>
                <div className='d-flex align-items-center'>
                  <Checkbox
                    checked={selectedGroups.length && selectedGroups.length === groupsJobs.length}
                    onClick={(event) => {
                      handleCheckboxSelection({
                        type: 'groups',
                        value: [],
                        checked: event.target.checked,
                        allSelected: true
                      })
                    }}
                    className='header-checkbox'
                  />
                  <span>Select All</span>
                  {selectedGroups.length ? <Button onClick={() => { setBulkSelection(true); setSelectedJobs('groups'); setRemoveJob(true) }} className='delete-groups-btn'><FiTrash2 className="groups-delete" /> Delete Groups</Button> : ''}
                </div>
              </div>
              {groups.map(({ checkbox, label, status, icons, icon, progress, resume, index }) => {
                return (
                  <div key={index} className='progress-bar-wrapper'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex align-items-center'>
                        {checkbox}
                        <Tooltip title={label}>
                          <p className='m-0'>
                            {handleString(label)}
                          </p>
                        </Tooltip>
                        <p style={handleSetJobsColor({ status })} className='m-0'>{status}</p>
                      </div>
                      <div className=" d-flex align-items-center">
                        {icons}
                        {icon}
                        {resume}
                      </div>
                    </div>
                    {progress}
                  </div>
                );
              })}
              <Modal
                className="modal-content"
                footer={null}
                type="primary"
                centered
                visible={pauseJob}
                width={440}
                closable={false}
              >
                <AiOutlinePauseCircle className="modal-pause-icon" />
                <h3>Are you sure you want to pause this job</h3>
                <p>This job will be paused. Leads syncing will be paused for now</p>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined={'true'}
                    type="white"
                    onClick={() => {
                      setSelectedGroups([]);
                      setSelectedPosts([]);
                      setPauseJob(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => { handlePauseJobs() }} size="default" type="primary" className="ml-2 save-btn">
                    {loading ? <Spin size="small" /> : 'Pause'}
                  </Button>
                </div>
              </Modal>

              <Modal
                className="modal-content"
                footer={null}
                type="primary"
                centered
                visible={resumeJob}
                width={440}
                closable={false}
              >
                <AiFillCaretRight className="modal-pause-icon" />
                <h3>Are you sure you want to resume this job</h3>
                <p>This job will be started. Leads syncing will be resumed</p>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined={'true'}
                    type="white"
                    onClick={() => {
                      setSelectedGroups([]);
                      setSelectedPosts([]);
                      setSelectedMessages([]);
                      setResumeJob(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => { setExtensionLoading(true); handleResumeJobs() }} size="default" type="primary" className="ml-2 save-btn">
                    {extensionLoading ? <Spin size="small" /> : 'Resume'}
                  </Button>
                </div>
              </Modal>
              <Modal
                className="modal-content"
                footer={null}
                type="primary"
                centered
                visible={removeJob}
                width={440}
                closable={false}
              >
                <img src={RemoveIcon} className="modal-crose-icon" />
                <h3>Are you sure you want to delete this job?</h3>
                <p>This job will be deleted and no other leads will be synced. Previously added leads wont be removed</p>
                <div className="modal-footer d-flex justify-content-center">
                  <Button
                    size="default"
                    outlined={'true'}
                    type="white"
                    onClick={() => {
                      setSelectedGroups([]);
                      setSelectedPosts([]);
                      setRemoveJob(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={() => { handleRemoveJobs() }} size="default" type="primary" className="ml-2 save-btn">
                    {loading ? <Spin size="small" /> : 'Remove'}
                  </Button>
                </div>
              </Modal>
            </div>
          </div>
        </Col>
        <Col md={12}>
          <div className='cards-wrapper responsive-layout-wrapper'>
            <div className='card-inner'>
              <div className='d-flex justify-content-between align-items-center'>
                <h4 className='m-0'>Posts - ({postsJobs.length})</h4>
                <div className='d-flex align-items-center'>
                  <Checkbox
                    checked={selectedPosts.length && selectedPosts.length === postsJobs.length}
                    onClick={(event) => {
                      handleCheckboxSelection({
                        type: 'posts',
                        value: [],
                        checked: event.target.checked,
                        allSelected: true
                      })
                    }}
                    className='header-checkbox'
                  />
                  <span>Select All</span>
                  {selectedPosts.length ? <Button onClick={() => { setBulkSelection(true); setSelectedJobs('posts'); setRemoveJob(true) }} className='delete-groups-btn'><FiTrash2 className="groups-delete" /> Delete Posts</Button> : ''}
                </div>
              </div>
              {posts.map(({ checkbox, label, icons, status, icon, progress, index, resume }) => {
                return (
                  <div key={index} className='progress-bar-wrapper'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex align-items-center'>
                        {checkbox}
                        <Tooltip title={label}>
                          <p className='m-0'>
                            {handleString(label)}
                          </p>
                        </Tooltip>
                        <p style={handleSetJobsColor({ status })} className='m-0'>{status}</p>
                      </div>
                      <div className=" d-flex align-items-center">
                        {icons}
                        {icon}
                        {resume}
                      </div>
                    </div>
                    {progress}
                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </BackgroundJobsWrapper>
  );
};
export default BackgroundJobs;
