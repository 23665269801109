import styled from "styled-components";
const SettingWrapper = styled.div`
  height: calc(100vh - 97px);
  margin-top: 29px;
  .ant-row.ant-form-item.ant-form-item-has-success {
    row-gap: 0px;
    flex-direction: column;
}
.ant-form-item-control-input-content {
    width: 100px;
}
  .setting-header-wrapper {
    position: relative;
    h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
    }
    input {
      width: 252px;
      border-radius: 30px;
      margin-top: -6px;
    }
    svg {
      top: 7px;
      color: #5f63f2;
      cursor: pointer;
    }
  }
  
  ul.ant-menu.ant-menu-light.ant-menu-root.ant-menu-vertical {
    display: flex;
    flex-direction: column;
  }
  .content-wrapper {
    background: #fff;
    margin-top: 25px;
    border-radius: 8px;
  }
  .tool-manegement-wrapper{
      grid-gap:8px;
      .bages-wrapper {
        max-width: 126px;
        border: 1px solid #9BA8B7;
        padding: 3px 6px 3px 12px;
        justify-content: space-between;
        border-radius: 20px;
        img {
        max-width: 12px;
        margin-left: 8px;
        cursor: pointer;
}
span {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}
}
  }
  td.ant-table-cell {
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
      color:#4B5664;
}
  .ant-table{
    .ant-table-thead{
        tr {
            th {
    font-size: 12px;
    font-weight: 500;
    color: #5a5f7d !important;
    background: #fff;
    border-top: 0px;
    border-bottom: 1px solid #4B5664;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

}
        }
    } 
  } 
  .socail-media-card .facebook-user-image {
    max-width: 80px;
    height: 80px;
    border-radius: 40px;
}
  svg.eye-icon-wrapper {
    text-align: center;
    margin: auto;
    color: #4b4ec0;
    margin-left: 16px;
    cursor: pointer;
}
  .table-wrapper{
    .ant-table {
    height:446px;
    overflow: auto;
}

.ant-table-pagination{
    margin-top: 16px !important;
    display: flex;
    align-items: center;
}
  }
  .setting-menu-wrapper {
    background: #fff;
    margin-top: 27px;
    border-radius:4px ;
    min-height: calc(100vh - 220px);
    h1 {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      margin-top: 12px;
      overflow-wrap:anywhere;
    }
    .setting-menu {
      padding: 16px 22px;
      .user-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        width: 120px;
        margin: auto;
}

        .carmra-div {
          position: absolute;
          top: 79px;
          left: 85px;
          background: #5f63f3;
          padding: 7px 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffff;
          font-size: 14px;
          border-radius: 30px;
          border: 4px solid #fff;
          cursor: pointer;
        }
        img {
          max-width: 120px;
          height: 120px;
          border-radius: 68px;
        }
      }
    }
  .ant-tabs-content-holder {
    display: none;
  }
  .ant-tabs-nav {
    width: 100%;
  }
  .ant-tabs-tab {
    margin: 0px !important;
    padding: 12.5px 0px 12.5px 16px !important;
    &:nth-child(1){
      display:none ;
    }
    &:hover {
      background: #5f63f21f;
      color: #5f63f2;
      border-radius:5px;
      .tab-item {
        color: #5f63f2;
      }
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: #5f63f21f;
    border-radius: 5px;
    .tab-item {
      color: #5f63f2;
    }
  }
  .ant-tabs-ink-bar {
    background-color: transparent;
  }
  .ant-tabs-nav-list {
    margin: 0 16px;
  }
  svg.tab-icon {
    margin-right: 12px;
    font-size: 16px;
  }
  span.icon-list.tab-icon {
    margin-right: 15px;
    font-size: 16px;
    margin-left: 1px;
  }
  span.tab-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #4b5664;
  }
  button.ant-switch {
    padding: 0px !important;
    margin: 0px !important;
    width: 0px !important;
    height: 18px !important;
  }
  .footer-wrapper {
    padding-bottom: 16px;
    span {
      font-size: 13px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: 8px;
      color: #5a5f7d;
    }
  }
`;
const EditProfileWrapper = styled.div`
.tabs-wrapper {
    padding-top: 6px;
}
/* .ant-form-item-control-input{
  width: 100%;
} */
.loading-center{
  display: flex;
  justify-content: center;
  align-items: center
}
.address-footer-overlay {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.credit-card-input-wrapper {
          position: relative;
          input{
            padding-left: 42px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
          }
          svg {
              position: absolute;
              left: 16px;
              top: 10px;
              color:#5F63F2 ;
              font-size:16px;
          }
      }
    h1 {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      padding: 13px 24px;
    }
  .divider {
    width: 100%;
    height: 1px;
    background: #eceef5;
  }
  .header-invoices {
    h1 {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      padding: 13px 16px;
    }
  }
  th.ant-table-cell {
    padding: 22px 0 14px 0px !important;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-radius: 0px !important;
    padding-left: 17px !important;
  }
  td.ant-table-cell {
    padding: 15px 45px 16px 0px !important;
  }
  td.ant-table-cell:nth-child(1) {
    padding-left: 17px !important;
  }
  .invoices-wrapper {
    padding: 16px 0px;
    .content {
      p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #4b5664;
        margin-left: 16px;
      }
      button {
        background: transparent;
        color: #5b5ee8;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -5px;
        padding: 0 15px;

        &:hover {
          background-color: #5b5ee8;
          color: #fff;
        }
      }
    }
  }
  .add-card-wrapper {
    padding: 16px 24px;
    height: 552px;
    .content {
      p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #4b5664;
      }
    }
  }
  .edit-profile-content-wrapper {
    padding: 16px 24px;
    .content {
      p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #4b5664;
      }
      button {
        background: transparent;
        color: #5b5ee8;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -5px;
        padding: 0 15px;

        &:hover {
          background-color: #5b5ee8;
          color: #fff;
        }
      }
    }
  }
  .reset-password-wrapper {
    max-width: 417px;
    margin-top: 24px;
  }
  .reset-password {
    padding-top: 22px;
  }
  .form {
    max-width: 417px;
    margin-top: 17px;
    .form-item.form-item-wrapper {
      margin-top: 24.5px;
    }
    .form-item {
      label {
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #272b41;
        font-size: 12px;
        margin-top: 12px;
        display: block;
      }
      input {
        padding: 0 0px 0 16px !important;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .fotter-btn-wrapper {
    display: flex;
    justify-content: end;
    grid-gap: 16px;
    margin-top: 24.5px;
    padding-bottom: 282px;
    .update-btn {
      background: transparent;
      color: #5b5ee8;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      padding: 5px 16px;
      margin-top: 1px;

      &:hover {
        background-color: #5b5ee8;
        color: #fff;
      }
    }
    .cancel-btn {
      background: transparent;
      border: 1px solid #e3e6ef;
      color: #9299b8;
      font-size: 14px;
      font-weight: 700;
      padding: 0 15px;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      margin-right: -1px;

      &:hover {
        border: 1px solid #5b5ee8;
        color: #5b5ee8;
      }
    }
  }
  .card-header {
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #272b41;
    }
    img {
      max-width: 40px !important;
    }
  }
  .card-holder-name {
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 6px;
    color: #515d70;
  }
  .card-item-wrapper {
    border: 3px solid #eceef5;
    transition: 0.3s;
    cursor: pointer;
    min-height: 200px;
    &:hover {
      border: 3px solid #4b5664;
    }
    .card-item {
      padding: 16px 16px 12px;
    }
    .defult-card-btnDefault.Card {
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      background: transparent;
      border: none;
      color: #5f63f4;
    }
  }
  .card-fotter-wrapper {
    background: #f7f7ff;
    padding: 5.5px 0px;
    margin: 0 5px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      left: 98px;
      right: 0;
      top: 13px;
      border-left: 2px solid #515d70;
      width: 30px;
      height: 22px;
    }
  }
  .card-num-wrapper {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 12px;
  }
  .card-num {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #515d70;
  }
  .card-expire {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 12px;
    color: #272b41;
  }
  .card-expired-date {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #515d70;
  }
  .add-new-account-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    border-radius:4px ;
    cursor: pointer;
    .add-card-plus-icon {
      max-width: 24px !important;
    }
    .add-card-text {
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #6f6f6f;
      padding-top: 16px;
    }
  }
  .add-new-card-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    .add-card-plus-icon {
      max-width: 24px !important;
    }
    .add-card-text {
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0em;
      text-align: left;
      color: #6f6f6f;
      padding-top: 16px;
    }
  }
  .defult-card-btnDefault.Card.radio-btn {
    padding: 0 0px 0 0px;
  }
  .ant-row.card-row-wrapper {
    margin-top: 24px;
  }
  .socail-media-card {
    max-width: 241px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #5f63f2;\
    border-radius:4px ;
    padding: 34px 66.5px;
    position: relative;
    svg.facebook-icon {
    position: absolute;
    right: 76px;
    top: 87px;
    background: #fff;
    border-radius: 30px;
}
svg.switch-icon {
    position: absolute;
    top: 11px;
    right: 11px;
    cursor: pointer;
}

    img {
      max-width: 40px ;
    }
h1{
  margin-top:12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: #272B41;
  padding:0px;
  width: 200px;
  text-align: center;
  overflow-wrap:anywhere;

}
  }
  footer {
    margin-top: 18px;
    svg {
      color: #5f63f2;
      font-size: 27px;
      margin-top: -6px;
    }
    .note-text-wrapper {
      font-size: 13px;
      font-style: italic;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #5f63f2;
      margin-left: 8px;
      margin-right: 6px;
    }
    p {
      font-size: 13px;
      font-style: italic;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      color: #4b5664;
    }
  }
  .social-media-header-wrapper {
    position: relative;
    h1 {
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;

      padding: 13px 16px;
    }
  }
  .plans-content-wrapper {
    padding:11px 16px 24px;
    .content {
      p {
        font-size: 13px;
      }
    }
  }
  .social-profile-content-wrapper {
    padding: 16px 16px;
    height: 548px;
    overflow: auto;
    .content {
      p {
        margin-bottom: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #4b5664;
    margin-bottom:24px ;
      }
      button {
        background: transparent;
        color: #5b5ee8;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: -5px;
        padding: 0 15px;

        &:hover {
          background-color: #5b5ee8;
          color: #fff;
        }
      }
    }
  }
  .subscription-plan-item-wrapper {
    border: 2px solid #e3e6ef;
    padding: 24px 20px;
    border-radius: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: 0.3s all ease-in;
    &:hover {
      border: 2px solid #5f63f2;
      cursor: pointer;
      transition: 0.3s all ease-in;
      .ant-btn {
        background: #5f63f2;
        color: #fff;
        transition: 0.3s all ease-in;
      }
    }
    .subscription-item-header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .new-deal-label {
        position: absolute;
        top: -19px;
        right: -43px;
        background: #5f63f2;
        transform: rotate(45deg);
        padding: 0 30px;
        color: #fff;
        font-size: 9px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
      }
      h3 {
        font-size: 12px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0px;
        padding: 8px 16px;
        text-align: center;
        background: #5f63f21f;
        mix-blend-mode: normal;
        border-radius: 16px;
        color: #5f63f2;
        position: relative;
        top: -2px;
      }
      p {
        margin-top: 24px !important;
        span.doller-sign {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0px;
          text-align: left;
          color: #adb4d2;
          margin-right: 1px;
          margin-top: 9px;
        }
        .price-text {
          font-size: 36px;
          font-weight: 400;
          line-height: 44px;
          letter-spacing: 0px;
          text-align: left;
          color: #272b41;
          padding-right: 3px;
          padding-top: 4px;
        }
        .month-text {
          align-self: end;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0px;
          text-align: left;
          position: relative;
          top: -7px;
          color: #adb4d2;
        }
      }
    }
    .plans-list-wrapper {
      margin-top: 6px;
      li {
        margin-top: 16px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0px;
        text-align: left;
        color: #4b5664;
        margin-left: -7px;
        svg.text-overlow-wrapper {
          position: relative;
          top: -10px;
        }
        svg {
          color: #20c997;
          margin-right: 12px;
        }
      }
    }
    .subscription-plan-btn {
      background: transparent;
      color: #5f63f2;
      border: 1px solid #5f63f2;
      margin-top: 22px !important;
      text-align: center;
      text-align: center;
      margin: auto;
      margin-bottom: -6px;
      svg {
        font-size: 38px;
        width: auto;
        height: auto;
        margin-left: 3px;
      }
      &:hover {
        background: #5f63f2;
        color: #fff;
      }
    }
  }
  .confirmation-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .email-verification {
      h1 {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0px;
        text-align: left;
        padding-top: 104px;
        padding-left: 20px;
      }
      img {
        max-width: 307px !important;
        margin-top: 60px;
      }
      p {
        margin-top: 60px;
        padding-bottom: 103px;
        padding-left: 17px;
        i {
          font-size: 14px;
          font-style: italic;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: right;
          color: #5a5f7d;
        }

        a {
          font-size: 14px;
          font-style: italic;
          font-weight: 700;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  .offer-banner-wrapper {
    background: #5f63f2;
    padding: 6px 12px;
    text-align: center;
    border-radius: 10px;
    margin: 23px 0px;
    .offer-banner {
      border: 3px solid #fff;
      padding: 10px 0 9px 0;
      border-radius: 5px;
      h1 {
        font-size: 24px;
        font-weight: 500;
        line-height: 29px;
        letter-spacing: 0em;
        color: #fff;
        margin-left: 16px;
        span {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          letter-spacing: 0em;
        }
      }
    }
  }
`;
const LinkFacebookWrapper = styled.div`
padding: 24px;
  h2{
    text-align:center;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    margin-top:12px;
  }
  svg{
    margin-top: 2px;
    margin-left: 2px;
  }
  h1{
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom:40px;
    margin-left:18px ;
p{
  margin-bottom: 0px;
  color:#5F63F2 ;
  span{
      font-size: 24px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0px;
      text-align: left;
      margin-left:6px ;
}
}

    }
    .fb-user-image{
      margin: auto;
    display: block;
    max-width: 60px !important;
    border-radius: 40px;
    margin-top:0px !important ;
    }
    img{
    text-align: center;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    color: #272B41;
    margin-top:5px ;
    }
    h3{
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      margin-top:40px ;
    }
    ul{
      li{
        list-style: disc;
        color:#5A5F7D ;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        margin-left: 21px;
        margin-top: 10px;

        &::marker {
          color:#5A5F7D ;

}
      }
    }
    .chat-pro-account-buttons{
      grid-gap:16px ;
    }
    .loader-wrapper-block-leads{
     margin: 1200%;
    }
    button{
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color:#5F63F2 ;
    margin-bottom:138px ;
    margin-top:28px ;
    border: 1px solid #5F63F2;
      &:hover{
        background-color:#5F63F2 ;
        color: #fff;
}

    }
    h6 {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    color: #4B5664;
    margin-top: 16px;
    padding-bottom: 56px;
}
.no-data-illustration-wrapper{
  svg {
    margin-top: 52px;
}
} 
`;

export { SettingWrapper, EditProfileWrapper, LinkFacebookWrapper };
