import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

const user = createSlice({
    name: 'kanban',
    initialState: {
        success: false,
        loading: false,
        messageError: false,
        stagesLoading: false,
        stageAddLoading: false,
        message: '',
        stagesUpdated: false,
        pipelineData: { stages: [] },
        err: '',
    },
    reducers: {
        SetState(state, { payload: { field, value } }) {
            state[field] = value;
        },
        ClearState() {
            return {
                success: false,
                stagesUpdated: false,
                messageError: false,
                pipelineData: { stages: [] },
                err: '',
            };
          }
    }, 
    extraReducers: {
    }
});

const { reducer, actions } = user;

export const {
    SetState,
    ClearState
} = actions;

export default reducer;
