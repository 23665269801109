import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../../config/axios/axios-configuration';

const axios = axiosBaseUrl();

export const AddPipelineRecord = createAsyncThunk(
    'pipelines/addPipelines',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/pipelines/pipeline-record', data);
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const DuplicatePipelineRecord = createAsyncThunk(
    'pipelines/duplicatePipelineRecord',
    async (data, thunkAPI) => {
        try {
            const response = await axios.post('/pipelines/duplicate-pipeline-record', data);
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const GetPipelinesRecord = createAsyncThunk(
    'pipelines/getPipelinesRecord',
    async (data, thunkAPI) => {
        try {
            const response = await axios.get('/pipelines/pipelines-record', { params: data});
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const GetStageLeads = createAsyncThunk(
    'stages/getStageLeads',
    async (data, thunkAPI) => {
        try {
            const { pipelineId, stageId } = data;
            const response = await axios.get('/stages/get-stage-leads', { params: { pipelineId, stageId }});
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const UpdatePipelineRecord = createAsyncThunk(
    'pipelines/updatePipelineRecord',
    async (data, thunkAPI) => {
        try {
            const { _id: pipelineId } = data;
            const response = await axios.put('/pipelines/pipeline-record', { data }, { params: { pipelineId } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const GetPipelineLeads = createAsyncThunk(
    'pipelines/getPipelineLeads',
    async (data, thunkAPI) => {
        try {
            const { pipelineId, stageIds } = data;
            const response = await axios.get('/pipelines/pipeline-leads', { params: { pipelineId, stageIds } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const RemovePipelinesRecord = createAsyncThunk(
    'pipelines/removePipelinesRecord',
    async (pipelineIds, thunkAPI) => {
        try {
            const response = await axios.delete('/pipelines/delete-pipelines-record', { params: { pipelineIds } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

export const GetPipelineStages = createAsyncThunk(
    'stage/getPipelinesStages',
    async (data, thunkAPI) => {
        try {
            const { pipelineId } = data;
            const response = await axios.get('/pipelines/get-pipelines-stages', { params: { pipelineId } });
            return response.data;
        } catch (err) {
            if (err.response && err.response.data) {
                return thunkAPI.rejectWithValue({
                    err: err.response.data,
                    status: err.response.status
                });
            }
            return thunkAPI.rejectWithValue({
                err: 'Network Error'
            });
        }
    }
);

const user = createSlice({
    name: 'pipelines',
    initialState: {
        success: false,
        total: 0,
        information: '',
        pipelinesData: [],
        pipelineLoading: false,
        loading: false,
        stages: [],
        selectedPipelineData: '',
        err: '',
    },
    reducers: {
        SetState(state, { payload: { field, value } }) {
            state[field] = value;
        },
        ClearState() {
            return {
                success: false,
                total: 0,
                information: '',
                pipelinesData: [],
                stages: [],
                selectedPipelineData: '',
                err: '',
            };
        }
    },
    extraReducers: {
        [AddPipelineRecord.pending]: (state, action) => ({
            ...state,
            success: false,
            loading: true,
        }),
        [AddPipelineRecord.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false
        }),
        [AddPipelineRecord.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload.err?.error
        }),
        [DuplicatePipelineRecord.pending]: (state, action) => ({
            ...state,
            success: false,
            loading: true,
        }),
        [DuplicatePipelineRecord.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false,
            pipelinesData: [...state.pipelinesData, action.payload.pipeline],
        }),
        [DuplicatePipelineRecord.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload.err?.error,
        }),
        [GetPipelinesRecord.pending]: (state, action) => ({
            ...state,
            pipelineLoading: true
        }),
        [GetPipelinesRecord.fulfilled]: (state, action) => ({
            ...state,
            pipelinesData: action.payload.data,
            pipelineLoading: false,
            total: action.payload.total
        }),
        [GetPipelinesRecord.rejected]: (state, action) => ({
            ...state,
            success: false,
            pipelineLoading: false,
            err: action.payload.err?.error
        }),
        [GetPipelineStages.pending]: (state, action) => ({
            ...state
        }),
        [GetPipelineStages.fulfilled]: (state, action) => ({
            ...state,
            stages: action.payload.data
        }),
        [GetPipelineStages.rejected]: (state, action) => ({
            ...state,
            success: false,
            err: action.payload.err?.error
        }),
        [UpdatePipelineRecord.pending]: (state, action) => ({
            ...state,
            loading: true
        }),
        [UpdatePipelineRecord.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false
        }),
        [UpdatePipelineRecord.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload.err?.error
        }),
        [RemovePipelinesRecord.pending]: (state, action) => ({
            ...state,
            success: false,
            loading: true
        }),
        [RemovePipelinesRecord.fulfilled]: (state, action) => ({
            ...state,
            success: true,
            message: action.payload.message,
            loading: false
        }),
        [RemovePipelinesRecord.rejected]: (state, action) => ({
            ...state,
            success: false,
            loading: false,
            err: action.payload.err?.error
        })
    }
});

const { reducer, actions } = user;

export const {
    SetState,
    ClearState
} = actions;

export default reducer;
