import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';

const Progress = (props) => {
    const {now,className,label} = props;
//   const now = 60;
  return <ProgressBar now={now} className={className} label={label} />;
//   return <ProgressBar now={now} className={className} label={`${now}%`} />;
}

export default Progress;