import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistReducer } from 'redux-persist';
import createFilter from 'redux-persist-transform-filter';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';

import authSlice from '../slices/auth-slice';
import adminSlice from '../slices/admin-slice';
import blockedLeadsSlice from '../slices/blocked-leads-slice';
import ChangeLayoutMode from '../slices/change-layout-slice';
import dashboardSlice from '../slices/dashboard-slice';
import jobsSlice from '../slices/jobs-slice';
import leadsSlice from '../slices/leads-slice';
import listSlice from '../slices/list-slice';
import templateSlice from '../slices/template-slice';
import paymentSlice from '../slices/payment-slice';
import pipelinesSlice from '../slices/pipeline-slice';
import stagesSlice from '../slices/stages-slice';
import KanbanSlice from '../slices/kanban-slice';

const authTransform = createFilter('auth', [
  'name',
  'email',
  'message',
  'role',
  'emailVerified',
  'attachProfile',
  'facebookProfileData',
  'friendsSynced',
  'selectedPlan',
  'resetEmail',
  'userType',
  'status',
  'token',
  'userId'
]);

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'pipelines', 'pipelineLeads'],
  transforms: [authTransform]
};

const reducers = combineReducers({
  auth: authSlice,
  blockedLeads: blockedLeadsSlice,
  backgroundJobs: jobsSlice,
  ChangeLayoutMode,
  dashboard: dashboardSlice,
  kanban: KanbanSlice,
  leads: leadsSlice,
  list: listSlice,
  template: templateSlice,
  admin: adminSlice,
  payment: paymentSlice,
  pipelines: pipelinesSlice,
  stages: stagesSlice
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: process.env.NODE_ENV === 'development' ? [thunk, logger] : [thunk]
});
